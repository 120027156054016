import { States } from 'sigt';
import {
  RequestType,
  SET_REQUESTS,
  ADD_REQUEST,
  PAY_REQUEST,
  AUTH_USER_LOGOUT,
  CHANGE_REQ_STATUS,
  REBATE_REQUEST,
  SET_REQUESTS_PAGINATION,
  UPDATE_REQUEST,
} from '../actions/actionsTypes';

const initialState: States.Requests = {
  requests: [],
  pagination: {
    currentPage: 1,
    total: 0,
    tipoDocumento: '',
    documento: '',
    tipoContribuyente: '',
    referencia: '',
  },
};

export default (state = initialState, action: RequestType) => {
  switch (action.type) {
    case SET_REQUESTS:
      return { ...state, requests: action.payload };
    case SET_REQUESTS_PAGINATION:
      return {
        ...state,
        requests: [...action.payload.data],
        pagination: {
          ...action.payload.pagination,
        },
      };
    case ADD_REQUEST:
      return { ...state, requests: [...state.requests, action.payload] };
    case UPDATE_REQUEST: {
      const { id, request } = action.payload;
      const _requests = [...state.requests];
      const rIndex = _requests.findIndex((r) => r.id === id);
      _requests[rIndex] = {
        ..._requests[rIndex],
        liquidaciones: request.liquidaciones,
        montoPetro: request.montoPetro,
        monto: request.monto,
      };
      return { ...state, requests: _requests };
    }
    case CHANGE_REQ_STATUS: {
      const { id, status } = action.payload;
      const _requests = [...state.requests];
      const rIndex = _requests.findIndex((r) => r.id === id);
      _requests[rIndex] = {
        ..._requests[rIndex],
        estado: status as any,
      };
      return {
        ...state,
        requests: _requests,
      };
    }
    case REBATE_REQUEST:
      const _requests = [...state.requests];
      const rIndex = _requests.findIndex((r) => r.id === action.payload.id);
      _requests[rIndex] = {
        ..._requests[rIndex],
        rebajado: action.payload.rebajado,
        montoPetro: action.payload.montoPetro,
        liquidaciones: action.payload.liquidaciones,
      };
      return { ...state, requests: _requests };
    case PAY_REQUEST: {
      console.log('PAY_REQUEST action.payload', action.payload);
      const _requests = [...state.requests];
      const rIndex = _requests.findIndex((r) => r.id === action.payload.id);
      _requests[rIndex] = {
        ..._requests[rIndex],
        pagado: true,
        estado: 'validando',
        liquidaciones: action.payload.liquidaciones,
        montoPetro: action.payload.montoPetro,
        monto: action.payload.monto,
      };
      return {
        ...state,
        requests: _requests,
      };
    }
    case AUTH_USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
