import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Select, Input, Button, message, Table } from 'antd';
import { State, States, Taxpayer } from 'sigt';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../../../utils/hooks';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ColumnsType } from 'antd/lib/table';
import { useHistory, useLocation } from 'react-router';
const server = process.env.REACT_APP_SERVER_URL;

enum EnumTypeConsult {
  'DOCUMENTO_RAZON_SOCIAL',
  'RIM',
  'CORRELATIVO',
}
const typesOfConsul = [
  { label: 'Documento de identidad o Razón social', value: EnumTypeConsult.DOCUMENTO_RAZON_SOCIAL },
  { label: 'Número de licencia o RIM', value: EnumTypeConsult.RIM },
  { label: 'Correlativo', value: EnumTypeConsult.CORRELATIVO },
];

const GetTaxpayerInfo: React.FC<GetTaxpayerInfoProps> = ({ auth, thm }) => {
  const [tipoDocumento, setTipoDocumento] = useState('V');
  const [selectedConsult, setSelectedConsult] = useState<EnumTypeConsult | null>(null);
  const [searching, setSearching] = useState(false);
  const [taxpayers, setTaxpayers] = useState<Taxpayer[]>([]);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if ((history.location.state as any)?.contribuyentes) {
      setTaxpayers((history.location.state as any)?.contribuyentes);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (auth.user?.tipoUsuario === 4) {
      search();
    }
  }, [auth]);

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const columns: ColumnsType<Taxpayer> = [
    {
      title: 'Razón Social',
      dataIndex: 'razonSocial',
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      render: (doc, record) => (
        <span>
          {record.tipoDocumento}-{doc}
        </span>
      ),
    },
    {
      title: 'Tipo de Contribuyente',
      dataIndex: 'tipoContribuyente',
    },
    {
      title: 'Acciones',
      render: (_, contribuyente) => (
        <Button type='primary' onClick={() => action(contribuyente)} icon={<EyeOutlined />}>
          {location.pathname.includes('certificado/SM') ||
          location.pathname.includes('certificado/IU') ||
          location.pathname.includes('certificado/SOLA')
            ? 'Generar Solvencia'
            : location.pathname.includes('licencia')
            ? 'Tramitar Licencia'
            : location.pathname.includes('especiales')
            ? 'Liquidar'
            : location.pathname.includes('correcciones')
            ? 'Corregir'
            : 'Ver Contribuyente'}
        </Button>
      ),
    },
  ];

  const action = (contribuyente: Taxpayer) => {
    location.pathname.includes('certificado/SOLA')
      ? history.push(`/dashboard/certificado/SOLA/generar`, { contribuyente, contribuyentes: taxpayers })
      : location.pathname.includes('certificado/SM')
      ? history.push(`/dashboard/certificado/SM/generar`, { contribuyente, contribuyentes: taxpayers })
      : location.pathname.includes('certificado/IU')
      ? history.push(`/dashboard/certificado/IU/generar`, { contribuyente, contribuyentes: taxpayers })
      : location.pathname.includes('licencia')
      ? history.push(`/dashboard/contribuyente/licencia/${contribuyente.id}`, { contribuyente, contribuyentes: taxpayers })
      : location.pathname.includes('interesesAE')
      ? history.push(`/dashboard/impuestos/interesesAE/${contribuyente.id}`, { contribuyente, contribuyentes: taxpayers })
      : location.pathname.includes('especiales')
      ? history.push(`/dashboard/impuestos/especiales/${contribuyente.id}`, { contribuyente, contribuyentes: taxpayers })
      : location.pathname.includes('correcciones')
      ? location.pathname.includes('correcciones/editar')
        ? history.push(`/dashboard/correcciones/editar/contribuyente/${contribuyente.id}`, {
            contribuyente,
            contribuyentes: taxpayers,
          })
        : history.push(`/dashboard/correcciones/liquidaciones/${contribuyente.id}`, { contribuyente, contribuyentes: taxpayers })
      : history.push(`/dashboard/contribuyente/${contribuyente.id}`, { contribuyente, contribuyentes: taxpayers });
  };

  const search = async () => {
    const values = await form.validateFields();
    const { razonSocial, doc, rim, correlativo } = values;
    if (((razonSocial || doc || rim || correlativo) && auth.user?.tipoUsuario !== 4) || auth.user?.tipoUsuario === 4) {
      try {
        setSearching(true);
        let params;
        if (auth.user?.tipoUsuario !== 4) {
          if (selectedConsult === EnumTypeConsult.DOCUMENTO_RAZON_SOCIAL) {
            if (doc && razonSocial) params = `doc=${doc}&pref=${tipoDocumento}&name=${razonSocial}`;
            else if (!razonSocial && doc) params = `doc=${doc}&pref=${tipoDocumento}`;
            else params = `name=${razonSocial}`;
          }
          if (selectedConsult === EnumTypeConsult.RIM) {
            params = `rim=${rim}`;
          }
          if (selectedConsult === EnumTypeConsult.CORRELATIVO) {
            params = `correlativo=${correlativo}`;
          }
        } else {
          params = `doc=${auth.user?.cedula}&pref=${auth.user?.nacionalidad}`;
        }

        const response = await axios.get<{ contribuyentes: Taxpayer[] }>(`${server}/settlements/search/taxPayer?${params}`, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        console.log(response.data.contribuyentes);
        setTaxpayers(response.data.contribuyentes);
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al buscar contribuyentes');
      } finally {
        setSearching(false);
      }
    } else {
      message.warn('Debe ingresar algún parámetro para la búsqueda de contribuyente');
      setSearching(false);
    }
  };

  return (
    <Form layout='vertical' form={form}>
      <Row gutter={24}>
        {auth.user?.tipoUsuario !== 4 && (
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col xs={24} xl={7}>
                <label>Modo de búsqueda</label>
                <Select style={{ width: '100%' }} onChange={(e: EnumTypeConsult) => setSelectedConsult(e)}>
                  {typesOfConsul.map((c, i) => (
                    <Select.Option key={i} value={c.value}>
                      {c.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              {selectedConsult === EnumTypeConsult.DOCUMENTO_RAZON_SOCIAL && (
                <>
                  <Col xs={24} xl={7}>
                    <Form.Item label='Documento de Identidad' name='doc'>
                      <Input
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') search();
                        }}
                        placeholder='Documento de Identidad'
                        addonBefore={tipoAddon}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={7}>
                    <Form.Item label='Razón Social' name='razonSocial'>
                      <Input placeholder='Razón Social' />
                    </Form.Item>
                  </Col>
                </>
              )}
              {selectedConsult === EnumTypeConsult.RIM && (
                <Col xs={24} xl={7}>
                  <Form.Item label='Número de licencia o RIM' name='rim'>
                    <Input
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') search();
                      }}
                      placeholder='Número de licencia o RIM'
                    />
                  </Form.Item>
                </Col>
              )}
              {selectedConsult === EnumTypeConsult.CORRELATIVO && (
                <Col xs={24} xl={7}>
                  <Form.Item label='Correlativo' name='correlativo'>
                    <Input
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') search();
                      }}
                      placeholder='Correlativo'
                    />
                  </Form.Item>
                </Col>
              )}
              <Col xl={6} xs={12}>
                <Button
                  onClick={() => search()}
                  style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }}
                  loading={searching}
                  icon={<SearchOutlined />}
                  type='primary'
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={taxpayers.map((t) => {
              t.key = t.id;
              return t;
            })}
            bordered
            loading={searching}
          />
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth, thm: state.thm });

export default connect(mapStateToProps)(GetTaxpayerInfo);

interface GetTaxpayerInfoProps {
  auth: States.Auth;
  thm: States.ThemeColors;
}
