import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { Row, Col, Form, Input } from 'antd';

const ColindanciaMedidas: React.FC<ColindanciaMedidasProps> = ({ form, disabled }) => {
  return (
    <Row style={{ paddingLeft: 16 }} gutter={16}>
      <Col xl={12} xs={24}>
        <Form.Item
          label='Norte'
          name={['colindanciaMedidas', 'norte']}
          rules={[{ required: true, message: 'Este campo es requerido' }]}
        >
          <Input disabled={disabled} placeholder='Norte'></Input>
        </Form.Item>
      </Col>
      <Col xl={12} xs={24}>
        <Form.Item
          label='Sur'
          name={['colindanciaMedidas', 'sur']}
          rules={[{ required: true, message: 'Este campo es requerido' }]}
        >
          <Input disabled={disabled} placeholder='Sur'></Input>
        </Form.Item>
      </Col>
      <Col xl={12} xs={24}>
        <Form.Item
          label='Este'
          name={['colindanciaMedidas', 'este']}
          rules={[{ required: true, message: 'Este campo es requerido' }]}
        >
          <Input disabled={disabled} placeholder='Este'></Input>
        </Form.Item>
      </Col>
      <Col xl={12} xs={24}>
        <Form.Item
          label='Oeste'
          name={['colindanciaMedidas', 'oeste']}
          rules={[{ required: true, message: 'Este campo es requerido' }]}
        >
          <Input disabled={disabled} placeholder='Oeste'></Input>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ColindanciaMedidas;

interface ColindanciaMedidasProps {
  form: FormInstance;
  disabled?: boolean;
}
