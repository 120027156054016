import { States } from 'sigt';
import {
  ProceduresActionsTypes,
  CHANGE_PROCEDURE_STATUS,
  SET_PROCEDURE,
  SET_PROCEDURES,
  CHANGE_PROCEDURE_DATA,
  CHANGE_PROCEDURE_CERTIFICATE,
  CHANGE_FINE_STATUS,
  AUTH_USER_LOGOUT,
  SET_FINES,
  SET_FINE,
  CHANGE_FINE_CERTIFICATE,
  SET_SUPPORTS,
  SET_SUPPORT,
  CHANGE_SUPPORT_DATA,
  SET_PROCEDURE_PAGINATION,
} from '../actions/actionsTypes';

const intitialState: States.Procedures = {
  procedures: [],
  fines: [],
  support: [],
  pagination: {
    currentPage: 1,
  },
};

export default (state = intitialState, action: ProceduresActionsTypes): States.Procedures => {
  switch (action.type) {
    case SET_SUPPORTS:
      return { ...state, support: action.payload };
    case SET_PROCEDURES:
      return { ...state, procedures: action.payload };
    case SET_PROCEDURE:
      return { ...state, procedures: [action.payload, ...state.procedures] };
    case SET_PROCEDURE_PAGINATION:
      return {
        ...state,
        procedures: [...state.procedures, ...action.payload.procedures],
        pagination: { currentPage: action.payload.page },
      };

    case SET_SUPPORT:
      return { ...state, support: [action.payload, ...state.support] };
    case SET_FINES:
      return { ...state, fines: action.payload };
    case SET_FINE:
      return { ...state, fines: [action.payload, ...state.fines] };
    case AUTH_USER_LOGOUT:
      return intitialState;
    case CHANGE_PROCEDURE_STATUS: {
      const { id, status } = action.payload;
      const procedures = [...state.procedures];
      const pIndex = procedures.findIndex((p) => p.id === id);
      const _procedure = Object.assign({}, procedures[pIndex]);
      _procedure.estado = status;
      procedures[pIndex] = _procedure;
      return {
        ...state,
        procedures,
      };
    }
    case CHANGE_FINE_STATUS: {
      const { id, status } = action.payload;
      const fines = [...state.fines];
      const fIndex = fines.findIndex((f) => f.id === id);
      const _fines = Object.assign({}, fines[fIndex]);
      _fines.estado = status;
      fines[fIndex] = _fines;
      return {
        ...state,
        fines,
      };
    }
    case CHANGE_PROCEDURE_DATA: {
      const { id, data } = action.payload;
      const procedures = [...state.procedures];
      const pIndex = procedures.findIndex((p) => p.id === id);
      procedures[pIndex] = Object.assign({}, data);
      return {
        ...state,
        procedures,
      };
    }
    case CHANGE_SUPPORT_DATA: {
      const { id, data } = action.payload;
      const support = [...state.support];
      const pIndex = support.findIndex((p) => p.id === id);
      support[pIndex] = Object.assign({}, data);
      return {
        ...state,
        support: support,
      };
    }
    case CHANGE_PROCEDURE_CERTIFICATE: {
      const { id, certificate } = action.payload;
      const procedures = [...state.procedures];
      const pIndex = procedures.findIndex((p) => p.id === id);
      const _procedure = Object.assign({}, procedures[pIndex]);
      _procedure.certificado = certificate;
      procedures[pIndex] = _procedure;
      return {
        ...state,
        procedures,
      };
    }
    case CHANGE_FINE_CERTIFICATE: {
      const { id, certificate } = action.payload;
      const fines = [...state.fines];
      const fIndex = fines.findIndex((f) => f.id === id);
      const _fines = Object.assign({}, fines[fIndex]);
      _fines.certificado = certificate;
      fines[fIndex] = _fines;
      return {
        ...state,
        fines,
      };
    }
    default:
      return state;
  }
};
