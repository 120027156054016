import React from 'react';
import { Select } from 'antd';

const CatastroTypeSelect: React.FC<CatastroTypeSelectProps> = ({ onChange, value, placeholder, disabled = false }) => {
  return (
    <Select value={value} onChange={onChange} placeholder={placeholder} disabled={disabled}>
      <Select.Option value='NATURAL'>Natural</Select.Option>
      <Select.Option value='JURIDICO'>Jurídico</Select.Option>
    </Select>
  );
};

export default CatastroTypeSelect;

interface CatastroTypeSelectProps {
  onChange?: (e: any) => any;
  value?: any;
  placeholder?: string;
  disabled?: boolean;
}
