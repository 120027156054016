import React, { useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { Row, Col, Typography, Form, InputNumber, Divider, Descriptions } from 'antd';

const PropertyAvaluo: React.FC<PropertyAvaluoProps> = ({ form }) => {
  const [avaluos, setAvaluos] = useState({
    terreno: 0,
    construccion: 0,
  });

  useEffect(() => {
    if (!form) return;
    const values = form.getFieldValue('avaluo');

    const terreno = +(Number(values?.terreno?.metros ?? 0) * Number(values?.terreno?.unidad ?? 0)).toFixed(2);
    const construccion = +(Number(values?.construccion?.metros ?? 0) * Number(values?.construccion?.unidad ?? 0)).toFixed(2);

    setAvaluos({ terreno, construccion });
  }, [form]);

  return (
    <>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col xl={24} xs={24}>
          <Typography.Title level={4}>Terreno</Typography.Title>
        </Col>
        <Col xl={6} xs={24}>
          <Form.Item
            label='Metros'
            name={['avaluo', 'terreno', 'metros']}
            rules={[{ required: true, message: 'Este campo es requerido' }]}
          >
            <InputNumber style={{ width: '100%' }} placeholder='metros' min={0} step={1} />
          </Form.Item>
        </Col>
        <Col xl={6} xs={24}>
          <Form.Item
            label='Unidad'
            name={['avaluo', 'terreno', 'unidad']}
            rules={[{ required: true, message: 'Este campo es requerido' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder='unidad'
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
              min={0}
              step={0.1}
            />
          </Form.Item>
        </Col>
        <Col xl={24} xs={24}>
          <Descriptions bordered>
            <Descriptions.Item label='Total Avaluó Terreno'>{avaluos?.terreno ?? 'N/A'}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Divider type='horizontal' />

      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col xl={24} xs={24}>
          <Typography.Title level={4}>Construcción</Typography.Title>
        </Col>
        <Col xl={6} xs={24}>
          <Form.Item
            label='Metros'
            name={['avaluo', 'construccion', 'metros']}
            rules={[{ required: true, message: 'Este campo es requerido' }]}
          >
            <InputNumber style={{ width: '100%' }} placeholder='metros' min={0} step={1} />
          </Form.Item>
        </Col>
        <Col xl={6} xs={24}>
          <Form.Item
            label='Unidad'
            name={['avaluo', 'construccion', 'unidad']}
            rules={[{ required: true, message: 'Este campo es requerido' }]}
          >
            <InputNumber placeholder='unidad' min={0} step={0.1} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xl={24} xs={24}>
          <Descriptions bordered>
            <Descriptions.Item label='Total Avaluó Construccion'>{avaluos?.construccion ?? 'N/A'}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </>
  );
};

export default PropertyAvaluo;

interface PropertyAvaluoProps {
  form: FormInstance;
}
