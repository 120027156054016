import React from 'react';
import { Select, Row, Col, Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import ParishSelect from './ParishSelect';

const UbicacionSelect: React.FC<UbicacionSelectProps> = ({ form }) => {
  return (
    <Row style={{ paddingLeft: 16 }} gutter={16}>
      <Col xl={8} xs={24}>
        <Form.Item
          label='Estado'
          name={['ubicacion', 'estado']}
          rules={[{ required: true, message: 'Debe seleccionar el estado' }]}
        >
          <Select placeholder={'Estado'}>
            <Select.Option value='TACHIRA'>TACHIRA</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xl={8} xs={24}>
        <Form.Item
          label='Municipio'
          name={['ubicacion', 'municipio']}
          rules={[{ required: true, message: 'Debe seleccionar el municipio' }]}
        >
          <Select placeholder={'Municipio'}>
            <Select.Option value='SAN CRISTÓBAL'>SAN CRISTÓBAL</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xl={8} xs={24}>
        <Form.Item
          label='Parroquia'
          name={['ubicacion', 'parroquiaUbicacion']}
          rules={[{ required: true, message: 'Debe seleccionar la parroquia' }]}
        >
          <ParishSelect />
        </Form.Item>
      </Col>
      <Col xl={8} xs={24}>
        <Form.Item
          label='Dirección'
          name={['ubicacion', 'direccion']}
          rules={[{ required: true, message: 'Debe ingresar la dirección' }]}
        >
          <Input placeholder='Dirección' />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default UbicacionSelect;

interface UbicacionSelectProps {
  form: FormInstance;
}
