import React from 'react';
import { Col, Divider, Form, Input, Row, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Utils } from '../../../utils/validators';

const NumeroCatastral: React.FC<NumeroCatastralProps> = ({ form, disabled }) => {
  return (
    <>
      {/* <Row gutter={[16, 16]}>
        <Divider />
        <Typography.Title level={4}>Numero Catastral Anterior</Typography.Title>
      </Row> */}
      <Row style={{ paddingLeft: 16 }} gutter={[16, 16]}>
        <Col xl={8} xs={24}>
          <Form.Item
            rules={[{ validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' }]}
            label='Parroquia'
            name={['numeroCatastral', 'parroquia']}
          >
            <Input disabled={disabled} maxLength={2} placeholder='Parroquia'></Input>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item
            rules={[{ validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' }]}
            label='Sector'
            name={['numeroCatastral', 'sector']}
          >
            <Input disabled={disabled} maxLength={2} placeholder='Sector'></Input>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item
            rules={[{ validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' }]}
            label='Manzana'
            name={['numeroCatastral', 'manzana']}
          >
            <Input disabled={disabled} maxLength={3} placeholder='Manzana'></Input>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item
            rules={[{ validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' }]}
            label='Lote'
            name={['numeroCatastral', 'lote']}
          >
            <Input disabled={disabled} maxLength={3} placeholder='lote'></Input>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default NumeroCatastral;

interface NumeroCatastralProps {
  form: FormInstance;
  disabled?: boolean;
}
