import React, { useEffect } from 'react';
import { Col, Divider, Form, Input, Row, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Utils } from '../../../utils/validators';

const CodigoCatastralAnterior: React.FC<CodigoCatastralAnteriorProps> = ({ form }) => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Divider />
        <Typography.Title level={4}>Código Catastral Anterior</Typography.Title>
      </Row>
      <Row style={{ paddingLeft: 16 }} gutter={[16, 16]}>
        <Col xl={8} xs={24}>
          <Form.Item
            rules={[{ validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' }]}
            label='Parroquia'
            name={['codigoCatastralAnterior', 'parroquia']}
          >
            <Input maxLength={2} placeholder='Parroquia'></Input>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item
            rules={[{ validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' }]}
            label='Sector'
            name={['codigoCatastralAnterior', 'sector']}
          >
            <Input maxLength={2} placeholder='Sector'></Input>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item
            rules={[{ validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' }]}
            label='Manzana'
            name={['codigoCatastralAnterior', 'manzana']}
          >
            <Input maxLength={3} placeholder='Manzana'></Input>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item
            rules={[{ validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' }]}
            label='Parcela'
            name={['codigoCatastralAnterior', 'parcela']}
          >
            <Input maxLength={3} placeholder='Parcela'></Input>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item
            rules={[{ validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' }]}
            label='SubParcela'
            name={['codigoCatastralAnterior', 'subParcela']}
          >
            <Input maxLength={3} placeholder='SubParcela'></Input>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item
            rules={[{ validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' }]}
            label='Nivel'
            name={['codigoCatastralAnterior', 'nivel']}
          >
            <Input maxLength={3} placeholder='Nivel'></Input>
          </Form.Item>
        </Col>
        <Col xl={8} xs={24}>
          <Form.Item
            rules={[{ validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' }]}
            label='Unidad'
            name={['codigoCatastralAnterior', 'unidad']}
          >
            <Input maxLength={3} placeholder='Unidad'></Input>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default CodigoCatastralAnterior;

interface CodigoCatastralAnteriorProps {
  form: FormInstance;
}
