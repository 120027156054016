import React from 'react';
import { Provider, ConnectedComponent, useDispatch } from 'react-redux';
import store from './redux/store';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Login from './components/Forms/External/LoginForm';
import External from './layouts/ExternalLayout';
import ResultError from './components/ResultError';
import Dashboard from './layouts/DashboardLayout';
import ForgetPassword from './components/ForgetPassword';
import NotFound from './components/NotFound';
import ValidateDoc from './components/ValidateDoc';
import ValidateSedematDoc from './components/ValidateSedematDoc';
import RegisterForm from './components/Forms/External/RegisterForm';
import Document from './components/Document';

import './assets/css/App.css';
import 'ant-design-pro/dist/ant-design-pro.css';
import { ConfigProvider, notification } from 'antd';
import locale from 'antd/es/locale/es_ES';
import { AUTH_USER_LOGOUT, SET_USER } from './redux/actions/actionsTypes';
import moment from 'moment';
import AuthVerify from './utils/authVerify';

interface AppRouteProps {
  component: React.FC | ConnectedComponent<any, any>;
  layout: React.FC;
  path: string;
  exact?: boolean;
}

const AppRoute: React.FC<AppRouteProps> = ({ component: Component, layout: Layout, path, exact }) => (
  <Route
    path={path}
    exact={exact}
    render={(props: object) => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

const App: React.FC = () => {
  const dispatch = useDispatch();

  const signOut = (notif?: boolean) => {
    if(notif){
      notification.error({
        placement: 'topRight',
        bottom: 50,
        duration: 0,
        message:'Inactividad prolongada detectada. Sesión terminada.'
      });
    }
    dispatch({ type: SET_USER, payload: { user: null, token: null } });
    dispatch({ type: AUTH_USER_LOGOUT });
    localStorage.clear();
  };

  const checkTokenTime = () => {
    const tokenTime = localStorage.getItem("tokenDate");
    if(tokenTime){
      const exp = moment(tokenTime || '');
      const dif = moment().diff(exp, 'minutes');
      if(dif >= 20) {
        signOut(true);
      }
      else {
        localStorage.setItem('tokenDate', moment().toString());
      }
    }
    else {
      signOut();
    }
  };
  return (
    <ConfigProvider locale={locale}>
      <Provider store={store}>
        <div className='app' onMouseMove={() => checkTokenTime()} onClick={() => checkTokenTime()} onInput={() => checkTokenTime()}>
          <BrowserRouter>
            <Switch>
              <AppRoute path='/ingresar/funcionarios' layout={External} component={Login} exact />
              <AppRoute path='/tokenInvalido' layout={External} component={ResultError} />
              <AppRoute exact path='/ingresar' layout={External} component={Login} />
              <Route path='/dashboard' component={Dashboard} />
              <Route
                exact
                path='/auth/:token'
                render={(props) => <Redirect to={{ pathname: '/dashboard', state: { token: props.match.params.token } }} />}
              />
              <Route exact path='/'>
                {!localStorage.getItem('token') ? <Redirect to='/ingresar' /> : <Redirect to='/dashboard' />}
              </Route>
              <AppRoute path='/registro' layout={External} component={RegisterForm} />
              <AppRoute path='/olvidoContraseña' layout={External} component={ForgetPassword} />
              <AppRoute path='/validarDoc/:id' layout={External} component={ValidateDoc} />
              <AppRoute
                path='/validarTramite/:id'
                layout={External}
                component={(props) => <ValidateDoc {...props} type='TRAMITE' />}
              />
              <AppRoute path='/validarSedemat/:id' layout={External} component={ValidateSedematDoc} />
              <AppRoute path='/documento' layout={External} component={Document} />
              {/* <AppRoute path='/report' layout={ReportLayout} component={ScannerReport} /> */}
              {/* <AppRoute path="/validateMail" layout={External} component={validateMail} /> */}
              <AppRoute path='*' layout={External} component={NotFound} />
            </Switch>
            <AuthVerify logOut={signOut}/>
          </BrowserRouter>
        </div>
      </Provider>
    </ConfigProvider>
  );
};

export default App;

interface AppRouteProps {
  component: React.FC | ConnectedComponent<any, any>;
  layout: React.FC;
  path: string;
  exact?: boolean;
}
