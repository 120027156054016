import React, { useState, useEffect } from 'react';
import { FormInstance } from 'antd/lib/form';
import { Col, Select, Form, Row, Input, Divider, Typography, message, DatePicker, InputNumber } from 'antd';
import { Utils } from '../../../utils/validators';
import { getDataIU } from '../../../services/estates';
import { useSelector } from 'react-redux';
import { State } from 'sigt';
import { cementerio, ejido, mercado, quiosco } from './../../../utils/valuesIU';

const PropertyClassification: React.FC<PropertyClassificationProps> = ({ form }) => {
  const [selectClasificacionIU, setSelectClasificacionIU] = useState('');
  const [selectClaseEdificacion, setSelectClaseEdificacion] = useState<any>();
  const [selectTipoConstruccion, setSelectTipoConstruccion] = useState<any>();
  const [selectValorConstruccion, setSelectValorConstruccion] = useState<any>();
  const [dataIU, setDataIU] = useState<any>();

  const auth = useSelector((state: State) => state.auth);

  useEffect(() => {
    getDataIU(auth.token)
      .then((res) => {
        setDataIU(res);
        const values = form.getFieldValue('clasificacionIU');

        setSelectClasificacionIU(values.clasificacion);

        // clase edificación
        let buildingClassifications = res?.buildingClassifications.filter(
          (f) => f.id_tipo_edificacion === values?.impuestoConstruccion?.tipoEdificacion ?? 0
        );
        setSelectClaseEdificacion(buildingClassifications);

        //tipo construcción
        let constructionTypes = res?.constructionTypes.filter(
          (f) => f.id_clase_edificacion === values?.impuestoConstruccion?.claseEdificacion ?? 0
        );
        setSelectTipoConstruccion(constructionTypes);

        // valor construcción
        let constructionValues = res?.constructionValues.filter(
          (f) => f.id_tipo_vivienda === values?.impuestoTerreno?.tipoVivienda ?? 0
        );
        setSelectValorConstruccion(constructionValues);
        console.log(values);
      })
      .catch((error) => message.error('Error al momento de obtener los datos'));
    // eslint-disable-next-line
  }, []);

  const clasificacionIU = [
    { value: 'EJIDO' },
    { value: 'MERCADO' },
    { value: 'QUIOSCO' },
    { value: 'CEMENTERIO' },
    { value: 'NO APLICA' },
  ];

  const handleClasificacionIU = (value) => {
    setSelectClasificacionIU(value);
  };

  const formClasificaionIU = (key) => {
    switch (key) {
      case 'EJIDO':
        return (
          <>
            <Divider orientation='left'>
              <Typography.Title level={4}>Datos de inmueble {key?.toLowerCase() ?? key}</Typography.Title>
            </Divider>
            <Row style={{ paddingLeft: 16, width: '100%' }} gutter={16}>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Clase de Ejido'
                  name={['clasificacionIU', 'ejido', 'clase']}
                  rules={[{ required: true, message: 'Debe ingresar la clase de ejido' }]}
                >
                  <Select placeholder='Clase de ejido'>
                    {ejido.clase.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Tenencia'
                  name={['clasificacionIU', 'ejido', 'tenencia']}
                  rules={[{ required: true, message: 'Debe ingresar la tenencia del ejido' }]}
                >
                  <Select placeholder='Tenencia de ejido'>
                    {ejido.tenencia.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Uso de ejido'
                  name={['clasificacionIU', 'ejido', 'uso']}
                  rules={[{ required: true, message: 'Debe ingresar el uso del ejido' }]}
                >
                  <Select placeholder='Uso de ejido'>
                    {ejido.uso.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Nro de contrato'
                  name={['clasificacionIU', 'ejido', 'contrato']}
                  rules={[{ required: true, message: 'Debe ingresar el número del contrato' }]}
                >
                  <Input placeholder='Nro de contrato' />
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Fecha de vencimiento'
                  name={['clasificacionIU', 'ejido', 'fechaVencimiento']}
                  rules={[{ required: true, message: 'Debe ingresar la fecha de vencimiento' }]}
                >
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </>
        );
      case 'MERCADO':
        return (
          <>
            <Divider orientation='left'>
              <Typography.Title level={4}>Datos de inmueble {key?.toLowerCase() ?? key}</Typography.Title>
            </Divider>
            <Row style={{ paddingLeft: 16, width: '100%' }} gutter={16}>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Tipo de local'
                  name={['clasificacionIU', 'mercado', 'tipoLocal']}
                  rules={[{ required: true, message: 'Debe ingresar el tipo de local' }]}
                >
                  <Select placeholder='Tipo de local'>
                    {mercado.local.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Tipo de Mercado'
                  name={['clasificacionIU', 'mercado', 'tipoMercado']}
                  rules={[{ required: true, message: 'Debe ingresar el tipo de mercado' }]}
                >
                  <Select placeholder='Tipo de Mercado'>
                    <Select.Option key={1} value={'MERCADO LA ERMITA'}>
                      MERCADO LA ERMITA
                    </Select.Option>
                    <Select.Option key={2} value={'MERCADO LA GUAYANA'}>
                      MERCADO LA GUAYANA
                    </Select.Option>
                    <Select.Option key={3} value={'MERCADO LA VILLA'}>
                      MERCADO LA VILLA
                    </Select.Option>
                    <Select.Option key={4} value={'MERCADO SANTA TERESA'}>
                      MERCADO SANTA TERESA
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Actividad Comercial'
                  name={['clasificacionIU', 'mercado', 'tipoAE']}
                  rules={[{ required: true, message: 'Debe ingresar la actividad comercial' }]}
                >
                  <Select placeholder='Actividad Comercial'>
                    {mercado.tipo_ae.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Nro de contrato'
                  name={['clasificacionIU', 'mercado', 'contrato']}
                  rules={[
                    { required: true, message: 'Debe ingresar el número del contrato' },
                    { validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' },
                  ]}
                >
                  <Input placeholder='Nro de contrato' />
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Canon de Arrendamiento'
                  name={['clasificacionIU', 'mercado', 'canonArrendamientoMercado']}
                  rules={[{ required: true, message: 'Debe ingresar el canon de arrendamiento' }]}
                >
                  <InputNumber style={{ width: '100%' }} placeholder='Canon de Arrendamiento'></InputNumber>
                </Form.Item>
              </Col>
            </Row>
          </>
        );
      case 'QUIOSCO':
        return (
          <>
            <Divider orientation='left'>
              <Typography.Title level={4}>Datos de inmueble {key?.toLowerCase() ?? key}</Typography.Title>
            </Divider>
            <Row style={{ paddingLeft: 16, width: '100%' }} gutter={16}>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Tipo'
                  name={['clasificacionIU', 'quiosco', 'tipoQuiosco']}
                  rules={[{ required: true, message: 'Debe ingresar el tipo' }]}
                >
                  <Select placeholder='Tipo'>
                    {quiosco.tipo.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Objeto'
                  name={['clasificacionIU', 'quiosco', 'objetoQuiosco']}
                  rules={[{ required: true, message: 'Debe ingresar el objeto' }]}
                >
                  <Select placeholder='objeto'>
                    {quiosco.objeto.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Zona'
                  name={['clasificacionIU', 'quiosco', 'zonaQuiosco']}
                  rules={[{ required: true, message: 'Debe ingresar la zona' }]}
                >
                  <Select placeholder='Zona'>
                    {quiosco.zona.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Nro de contrato'
                  name={['clasificacionIU', 'quiosco', 'contrato']}
                  rules={[
                    { required: true, message: 'Debe ingresar el número del contrato' },
                    { validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' },
                  ]}
                >
                  <Input placeholder='Nro de contrato' />
                </Form.Item>
              </Col>
            </Row>
          </>
        );
      case 'CEMENTERIO':
        return (
          <>
            <Divider orientation='left'>
              <Typography.Title level={4}>Datos de inmueble {key?.toLowerCase() ?? key}</Typography.Title>
            </Divider>
            <Row style={{ paddingLeft: 16, width: '100%' }} gutter={16}>
              <Col xl={10} xs={24}>
                <Form.Item
                  label='Area de servicio'
                  name={['clasificacionIU', 'cementerio', 'areaServicios']}
                  rules={[{ required: true, message: 'Debe ingresar el area de servicio' }]}
                >
                  <Select placeholder='Area de Servicio'>
                    {cementerio.area_servicio.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} xs={24}>
                <Form.Item
                  label='Sector'
                  name={['clasificacionIU', 'cementerio', 'sector']}
                  rules={[{ required: true, message: 'Debe ingresar el sector' }]}
                >
                  <Select placeholder='Sector'>
                    {cementerio.sector.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Tenencia cementerio'
                  name={['clasificacionIU', 'cementerio', 'tenencia']}
                  rules={[{ required: true, message: 'Debe ingresar la tenencia del cementerio' }]}
                >
                  <Select placeholder='Tenencia cementerio'>
                    {cementerio.tenencia.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.value}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Nro de contrato'
                  name={['clasificacionIU', 'cementerio', 'contrato']}
                  rules={[
                    { required: true, message: 'Debe ingresar el número del contrato' },
                    { validator: Utils.validators.alphaNumeric, message: 'Debe ingresar un valor alfanumérico' },
                  ]}
                >
                  <Input placeholder='Nro de contrato' />
                </Form.Item>
              </Col>
            </Row>
          </>
        );
      default:
        break;
    }
  };

  const handleSelectTipoEdificacion = (e) => {
    let buildingClassifications = dataIU?.buildingClassifications.filter((f) => f.id_tipo_edificacion === e);
    form.setFieldsValue({
      clasificacionIU: {
        impuestoConstruccion: {
          claseEdificacion: null,
          tipoConstruccion: null,
        },
      },
    });
    setSelectClaseEdificacion(buildingClassifications);
    setSelectTipoConstruccion(null);
  };

  const handleSelectClaseEdificacion = (e) => {
    let constructionTypes = dataIU?.constructionTypes.filter((f) => f.id_clase_edificacion === e);
    form.setFieldsValue({
      clasificacionIU: {
        impuestoConstruccion: {
          tipoConstruccion: null,
        },
      },
    });
    setSelectTipoConstruccion(constructionTypes);
  };

  const handleSelectTipoVivienda = (e) => {
    let constructionValues = dataIU?.constructionValues.filter((f) => f.id_tipo_vivienda === e);
    form.setFieldsValue({
      clasificacionIU: {
        impuestoTerreno: {
          valorConstruccion: null,
        },
      },
    });
    setSelectValorConstruccion(constructionValues);
  };

  return (
    <>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col xl={8} xs={24}>
          <Form.Item
            label='Clasificación del Inmueble'
            name={['clasificacionIU', 'clasificacion']}
            rules={[{ required: true, message: 'Debe ingresar la clasificación del inmueble' }]}
          >
            <Select placeholder='Clasificación del Inmueble' onChange={(e) => handleClasificacionIU(e)}>
              {clasificacionIU.map((option, index) => (
                <Select.Option key={index} value={option.value}>
                  {option.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {formClasificaionIU(selectClasificacionIU)}

      <Divider orientation='left'>
        <Typography.Title level={4}>Impuesto De Construcción</Typography.Title>
      </Divider>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        {selectClasificacionIU !== 'EJIDO' && selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO' && (
          <Col xl={8} xs={24}>
            <Form.Item
              label='Tipo Edificación'
              name={['clasificacionIU', 'impuestoConstruccion', 'tipoEdificacion']}
              rules={[{ required: true, message: 'Debe ingresar un tipo de edificación' }]}
            >
              <Select
                placeholder='Tipo Edificación'
                onChange={(e) => {
                  handleSelectTipoEdificacion(e);
                }}
              >
                {dataIU?.buildingTypes &&
                  dataIU?.buildingTypes?.map((option) => (
                    <Select.Option key={option.nombre} value={option.id_tipo_edificacion}>
                      <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {selectClasificacionIU !== 'EJIDO' && selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO' && (
          <Col xl={8} xs={24}>
            <Form.Item
              label='Clase Edificación'
              name={['clasificacionIU', 'impuestoConstruccion', 'claseEdificacion']}
              rules={[{ required: true, message: 'Debe ingresar una clase de edificación' }]}
            >
              <Select
                placeholder='Clase Edificación'
                onChange={(e) => {
                  handleSelectClaseEdificacion(e);
                }}
              >
                {selectClaseEdificacion &&
                  selectClaseEdificacion.map((option) => (
                    <Select.Option key={option.nombre} value={option.id_clase_edificacion}>
                      <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {selectClasificacionIU !== 'EJIDO' && selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO' && (
          <Col xl={8} xs={24}>
            <Form.Item
              label='Tipo Construcción'
              name={['clasificacionIU', 'impuestoConstruccion', 'tipoConstruccion']}
              rules={[{ required: true, message: 'Debe ingresar un tipo de construcción' }]}
            >
              <Select placeholder='Tipo Construcción'>
                {selectTipoConstruccion &&
                  selectTipoConstruccion.map((option) => (
                    <Select.Option key={option.nombre} value={option.id_tipo_construccion}>
                      <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
      {selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO' && (
        <Divider orientation='left'>
          <Typography.Title level={4}>Impuesto De Terreno</Typography.Title>
        </Divider>
      )}
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        {selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO' && (
          <Col xl={8} xs={24}>
            <Form.Item
              label='Clase Terreno'
              name={['clasificacionIU', 'impuestoTerreno', 'claseTerreno']}
              rules={[{ required: true, message: 'Debe ingresar una clase de terreno' }]}
            >
              <Select placeholder='Clase Terreno'>
                {dataIU?.landClasses &&
                  dataIU?.landClasses?.map((option) => (
                    <Select.Option key={option.nombre} value={option.id_clase_terreno}>
                      <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {selectClasificacionIU !== 'EJIDO' && selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO' && (
          <Col xl={8} xs={24}>
            <Form.Item
              label='Tipo Viviendas'
              name={['clasificacionIU', 'impuestoTerreno', 'tipoVivienda']}
              rules={[{ required: true, message: 'Debe ingresar un tipo de vivienda' }]}
            >
              <Select
                placeholder='Tipo Viviendas'
                onChange={(e) => {
                  handleSelectTipoVivienda(e);
                }}
              >
                {dataIU?.dwellingTypes &&
                  dataIU?.dwellingTypes?.map((option) => (
                    <Select.Option key={option.nombre} value={option.id_tipo_vivienda}>
                      <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {selectClasificacionIU !== 'EJIDO' && selectClasificacionIU !== 'QUIOSCO' && selectClasificacionIU !== 'CEMENTERIO' && (
          <Col xl={8} xs={24}>
            <Form.Item
              label='Valor Construcción'
              name={['clasificacionIU', 'impuestoTerreno', 'valorConstruccion']}
              rules={[{ required: true, message: 'Debe ingresar un valor de construcción' }]}
            >
              <Select placeholder='Valor Construcción'>
                {selectValorConstruccion &&
                  selectValorConstruccion.map((option) => (
                    <Select.Option key={option.nombre} value={option.id_valor_construccion}>
                      <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};

export default PropertyClassification;

interface PropertyClassificationProps {
  form: FormInstance;
}
