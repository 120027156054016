import React, { useEffect, useState, useRef } from 'react';
import { Sucursal, DatosEnlace, States, State, Taxpayer } from 'sigt';
import { Row, Col, Collapse, Form, Input, Button, Modal, Steps, Typography, Divider, Tooltip, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import Lottie from 'react-lottie';
import ReactCodeInput from '@gabtrompiz/react-verification-code-input';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Utils } from '../../../utils/validators';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router';
import { setTaxpayerInfo } from '../../../redux/actions/auth';
import { fetchRequests } from '../../../redux/actions/requests';
import { fetchInstitutions } from '../../../redux/actions/institutions';
const server = process.env.REACT_APP_SERVER_URL;
const smsAnim = require('../../../assets/animations/sms.json');
const emailAnim = require('../../../assets/animations/email.json');

const EditRegisters: React.FC<EditRegistersProps> = ({
  selectedRegisters,
  form,
  setCurrent,
  linkingData,
  auth,
  setLinkingData,
  setTaxpayerInfo,
  fetchRequests,
  fetchInstitutions,
}) => {
  const [activeKey, setActiveKey] = useState<string>('0');
  const [verifying, setVerifying] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [sendingCode, setSendingCode] = useState<boolean>(false);
  const [resendTimeout, setResendTimeout] = useState<number>(10);
  const [intervalId, setIntervalId] = useState<number>(-1);
  const [inCooldown, setInCooldown] = useState<boolean>(false);
  const [resending, setResending] = useState(false);
  const [checking, setChecking] = useState(false);
  const [code, setCode] = useState('');
  const isNatural = linkingData?.datosContribuyente?.tipoContribuyente === 'NATURAL';

  const { Panel } = Collapse;

  const history = useHistory();

  const animRef = useRef<any>(null);
  const animRef2 = useRef<any>(null);

  useEffect(() => {
    selectedRegisters
      .filter((s) => s.datosSucursal)
      .forEach((r) => {
        form.setFieldsValue({
          [r.datosSucursal.registroMunicipal]: {
            ...r.datosSucursal,
          },
        });
      });
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (animRef2.current) {
      animRef.current.anim.isPaused = true;
      animRef.current.anim.currentFrame = 80;
      animRef.current.anim.currentRawFrame = 80;
    }
  }, [animRef2]);

  useEffect(() => {
    if (!verifying) {
      setCurrentStep(0);
    }
    if (animRef.current && !verifying) {
      animRef.current.anim.isPaused = true;
      animRef.current.anim.currentFrame = 0;
      animRef.current.anim.currentRawFrame = 0;
    } else if (animRef.current && verifying) {
      animRef.current.anim.isPaused = false;
    }
    // eslint-disable-next-line
  }, [verifying]);

  useEffect(() => {
    if (resendTimeout <= 1) {
      clearInterval(intervalId);
      setInCooldown(false);
      setResendTimeout(600);
    }
    // eslint-disable-next-line
  }, [resendTimeout]);

  const verifyInfo = async () => {
    try {
      await form.validateFields();
      setVerifying(true);
    } catch (e) {
      e.errorFields.forEach((e) =>
        message.error(e.name[0] === 'contribuyente' ? e.errors[0] : `${e.errors[0]} para el R.I.M. ${e.name[0]}`)
      );
    }
  };

  const requireCode = async () => {
    const values = await form.validateFields();
    const _contact = Object.assign({}, values.contribuyente);
    delete values.contribuyente;
    const _branches = cloneDeep(linkingData?.sucursales);
    Object.keys(values).forEach((k) => {
      const branch = _branches?.find((b) => b?.datosSucursal?.registroMunicipal === k);
      if (branch) {
        branch.datosSucursal = {
          ...branch.datosSucursal,
          ...values[k],
          representado: true,
        };
      }
    });
    const body = {
      datosEnlace: {
        datosContribuyente: linkingData?.datosContribuyente,
        sucursales: _branches,
        datosContacto: _contact,
        actividadesEconomicas: linkingData?.actividadesEconomicas,
      },
    };
    setSendingCode(true);
    try {
      await axios.post(`${server}/settlements/taxPayer`, body, { headers: { Authorization: `Bearer ${auth.token}` } });
      setCurrentStep((c) => (isNatural ? c + 2 : c + 1));
      if (!isNatural) {
        setInCooldown(true);
        setResendTimeout(600);
        clearInterval(intervalId);
        setIntervalId(
          setInterval(() => {
            setResendTimeout((t) => t - 1);
          }, 1000) as unknown as number
        );
      }
    } catch (e) {
      if (e.response?.data?.status === 429) {
        setCurrentStep((c) => c + 1);
        setResendTimeout(e.response?.data?.tiempo);
        setInCooldown(true);
        clearInterval(intervalId);
        setIntervalId(
          setInterval(() => {
            setResendTimeout((t) => t - 1);
          }, 1000) as unknown as number
        );
        message.info('Se ha enviado un código de verificación recientemente, por favor ingréselo o reenvie el código.');
      } else {
        message.error(e.response?.data?.message || 'Error en el servidor');
      }
    } finally {
      setSendingCode(false);
    }
  };

  const closeModal = () => {
    setVerifying(false);
    setInCooldown(false);
    clearInterval(intervalId);
    setResendTimeout(600);
    if (currentStep === 2) history.push('/dashboard');
  };

  const resendCode = async () => {
    setResending(true);
    try {
      const info = message.loading('Reenviando código de verificación');
      await axios.put(`${server}/settlements/taxPayer/resend`, {}, { headers: { Authorization: `Bearer ${auth.token}` } });
      info();
      message.success('Código de verificación reenviado de manera exitosa');
      setResendTimeout(600);
      setInCooldown(true);
      clearInterval(intervalId);
      setIntervalId(
        setInterval(() => {
          setResendTimeout((t) => t - 1);
        }, 1000) as unknown as number
      );
    } catch (e) {
      if (e.response?.data?.status === 429) {
        setResendTimeout(e.response?.data?.tiempo);
        setInCooldown(true);
        setIntervalId(
          setInterval(() => {
            setResendTimeout((t) => t - 1);
          }, 1000) as unknown as number
        );
        message.info('Se ha enviado un código de verificación recientemente, por favor ingréselo o reenvie el código.');
      } else {
        message.error(e.response?.data?.message || 'Error en el servidor');
      }
    } finally {
      setResending(false);
    }
  };

  const verifyCode = async () => {
    if (code.length === 6) {
      try {
        setChecking(true);
        const response = await axios.put<{ contribuyente: Taxpayer }>(
          `${server}/settlements/taxPayer/verify`,
          { codigo: code },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        message.success('Registro completado de manera exitosa');
        setTaxpayerInfo(response.data.contribuyente);
        fetchRequests(auth.token || '');
        fetchInstitutions(auth.token || '');
        setLinkingData(null);
        setCurrentStep((c) => c + 1);
      } catch (e) {
        message.error(e?.response?.data?.message || 'Error al verificar código');
      } finally {
        setChecking(false);
      }
    } else {
      message.error('Debe ingresar el código de verificación');
    }
  };

  const formatNumber = (s: string) => `(${s.slice(0, 3)}) ${s.slice(3, 6)}-${s.slice(6)}`;

  return (
    <Form layout='vertical' form={form} scrollToFirstError>
      <Row gutter={24}>
        <Col span={24} style={{ marginTop: 20 }}>
          {selectedRegisters.length > 0 && (
            <Collapse accordion activeKey={activeKey} onChange={(c) => setActiveKey(c as string)}>
              {selectedRegisters
                .filter((r) => r.datosSucursal)
                .map((r) => (
                  <Panel
                    header={`${r.datosSucursal?.registroMunicipal} - ${r.datosSucursal?.razonSocial}`}
                    key={r.key}
                    forceRender
                  >
                    <Row gutter={24} style={{ margin: 0 }}>
                      <Col xl={12} xs={24}>
                        <Form.Item
                          label='Razón Social'
                          name={[r.datosSucursal?.registroMunicipal, 'razonSocial']}
                          rules={[{ required: true, message: 'Debe ingresar la razón social' }]}
                        >
                          <Input placeholder='Razón Social' />
                        </Form.Item>
                      </Col>
                      <Col xl={12} xs={24}>
                        <Form.Item
                          label='Denominación Comercial'
                          name={[r.datosSucursal?.registroMunicipal, 'denomComercial']}
                          rules={[{ required: true, message: 'Debe ingresar la denominación comercial' }]}
                        >
                          <Input placeholder='Denominación Comercial' />
                        </Form.Item>
                      </Col>
                      <Col xl={8} xs={24}>
                        <Form.Item
                          label='Nombre del Representante'
                          name={[r.datosSucursal?.registroMunicipal, 'nombreRepresentante']}
                          rules={[{ required: true, message: 'Debe ingresar el nombre del representante' }]}
                        >
                          <Input placeholder='Nombre del Representante' />
                        </Form.Item>
                      </Col>
                      <Col xl={16} xs={24}>
                        <Form.Item
                          label='Dirección'
                          name={[r.datosSucursal?.registroMunicipal, 'direccion']}
                          rules={[{ required: true, message: 'Debe ingresar la dirección' }]}
                        >
                          <Input placeholder='Dirección' />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                ))}
            </Collapse>
          )}
        </Col>
        <Col span={24}>
          <Divider orientation='left'>
            <Typography.Text strong style={{ marginLeft: 0, marginTop: 5, color: 'rgba(0, 0, 0, 0.85)', fontSize: 18 }}>
              Datos de Contacto
            </Typography.Text>
          </Divider>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <span>
                Teléfono&nbsp;
                <Tooltip title='El numero teléfonico que ingrese será verificado mediante un mensaje de texto con un código de 6 dígitos'>
                  <QuestionCircleOutlined style={{ marginLeft: 2.5 }} />
                </Tooltip>
              </span>
            }
            name={['contribuyente', 'telefono']}
            rules={[
              { required: true, message: 'Debe ingresar el numero de teléfono móvil' },
              { validator: Utils.validators.phoneNumber },
            ]}
            normalize={Utils.normalize.isNumber}
          >
            <Input placeholder='Teléfono Móvil' />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label='Correo electrónico'
            name={['contribuyente', 'correo']}
            rules={[{ required: true, message: 'Debe ingresar el correo electrónico' }, { validator: Utils.validators.email }]}
          >
            <Input placeholder='Correo electrónico' />
          </Form.Item>
        </Col>
        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
          <Button onClick={() => setCurrent((c) => c - 1)}>Atrás</Button>
          <Button type='primary' onClick={() => verifyInfo()}>
            Siguiente
          </Button>
        </Col>
        <Modal
          visible={verifying}
          title='Verificación de numero telefónico'
          onCancel={() => closeModal()}
          destroyOnClose
          onOk={
            currentStep === 0
              ? () => requireCode()
              : currentStep === 1
              ? () => verifyCode()
              : () => (auth.user?.tipoUsuario === 4 ? history.push('/dashboard') : history.push('/dashboard/impuestos/declarar'))
          }
          cancelButtonProps={{ hidden: true }}
          okText={currentStep === 2 ? 'Aceptar' : currentStep === 1 ? 'Verificar' : 'Siguiente'}
          okButtonProps={{ loading: sendingCode || checking }}
        >
          <Steps progressDot current={currentStep}>
            <Steps.Step title='Confirmación' />
            <Steps.Step title='Verificación' />
            <Steps.Step title='Finalizado' />
          </Steps>
          {currentStep === 0 && !isNatural && (
            <>
              <Lottie
                options={{ autoplay: true, loop: true, animationData: smsAnim }}
                width={100}
                height={100}
                style={{ marginBottom: 15, marginTop: 15 }}
                isClickToPauseDisabled
              />
              <Row style={{ textAlign: 'center', marginBottom: 20, fontWeight: 'bolder' }}>
                <Col span={24}>
                  <Typography.Text>
                    Deberá confirmar a través de un código de 6 dígitos que será enviado en un mensaje de texto al siguiente
                    numero telefónico:
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text style={{ color: '#0091c8' }}>
                    +58 {formatNumber(form.getFieldValue(['contribuyente', 'telefono']) || '')}
                  </Typography.Text>
                </Col>
              </Row>
            </>
          )}
          {currentStep === 0 && isNatural && (
            <>
              <Row style={{ textAlign: 'center', marginBottom: 20, fontWeight: 'bolder' }}>
                <Col span={24}>
                  <Typography.Text>
                    ¿Está seguro de que desea actualizar los datos de las sucursales seleccionadas?
                  </Typography.Text>
                </Col>
              </Row>
            </>
          )}
          {currentStep === 1 && (
            <React.Fragment>
              <Lottie
                options={{ autoplay: true, loop: false, animationData: emailAnim }}
                width={150}
                height={150}
                isClickToPauseDisabled
                ref={animRef}
              />
              <Row style={{ textAlign: 'center', marginBottom: 20, fontWeight: 'bolder' }}>
                <Typography.Text>
                  Se ha enviado un mensaje de texto al +58 {formatNumber(form.getFieldValue(['contribuyente', 'telefono']) || '')}
                  , por favor ingrese el código para verificar el numero telefónico
                </Typography.Text>
              </Row>
              <Row>
                <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} span={24}>
                  <ReactCodeInput onChange={(e) => setCode(e)} />
                </Col>
              </Row>
              <Row style={{ textAlign: 'center', marginTop: 20, fontWeight: 'bold' }}>
                <Col span={24}>
                  <Typography.Text>
                    ¿No ha recibido el código de verificación?&nbsp;
                    {inCooldown ? (
                      `Debe esperar ${moment.utc(resendTimeout * 1000).format('mm:ss')} para reenviarlo.`
                    ) : (
                      <Button type='link' style={{ padding: 0 }} onClick={() => resendCode()} loading={resending}>
                        Reenviar
                      </Button>
                    )}
                  </Typography.Text>
                </Col>
              </Row>
            </React.Fragment>
          )}
          {currentStep === 2 && (
            <React.Fragment>
              <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  alt='verificado'
                  src={require('../../../assets/images/verified.png')}
                  style={{ width: 150, height: 150, objectFit: 'contain', padding: 25 }}
                />
              </Row>
              <Row style={{ textAlign: 'center', marginBottom: 20, fontWeight: 'bolder' }}>
                <Typography.Text>
                  Registro completado de manera exitosa, las opciones de declarar y pagar impuesto ya se encuentran disponibles.
                </Typography.Text>
              </Row>
            </React.Fragment>
          )}
        </Modal>
      </Row>
    </Form>
  );
};

const mapStateToProps = (state: State) => ({ auth: state.auth });

export default connect(mapStateToProps, { setTaxpayerInfo, fetchRequests, fetchInstitutions })(EditRegisters);

interface EditRegistersProps {
  selectedRegisters: Sucursal[];
  form: FormInstance;
  setCurrent: (s: any) => void;
  linkingData: DatosEnlace | null;
  auth: States.Auth;
  setLinkingData: (s: any) => void;
  resetCurrent: () => void;
  setTaxpayerInfo: (taxpayer: Taxpayer) => void;
  fetchRequests: (token: string) => void;
  fetchInstitutions: (token: string) => void;
}
