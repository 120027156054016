import { States } from 'sigt';
import { EstateTypes, SET_DATA_ESTATE } from '../actions/actionsTypes';

const initialState: States.Estate = {
  dataEstate: null,
};

export default (state: States.Estate = initialState, action: EstateTypes) => {
  switch (action.type) {
    case SET_DATA_ESTATE:
      return { ...state, dataEstate: action.payload };

    default:
      return state;
  }
};
