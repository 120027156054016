import moment from 'moment';

export const fromMonthToNumber = (month: string | undefined) => {
  return month ? moment.months().indexOf(month) : false;
};

export const fromNumberToMonth = (month: number) => {
  const listMonth = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];

  if (month < 0 || month > 11) return 'Mes desconocido';

  return listMonth[month];
};

export const getMonthToNumber = (month) => {
  const listMonth = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];

  console.log(month, listMonth.indexOf(month));

  return listMonth.indexOf(month);
};

export const isBeforeOctober2023 = (month, year) => {
  const monthNumber: any = fromMonthToNumber(month);

  const enteredDate = moment({ year, month: monthNumber }).startOf('month');
  const october2023 = moment('2023-10-01');

  // console.log(enteredDate.format('YYYY-MM-DD'), monthNumber);
  return enteredDate.isSameOrBefore(october2023);
};
