import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Form, Input, Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import { State } from 'sigt';
import { Utils } from '../../../utils/validators';
import { FormInstance } from 'antd/lib/form';

const CodigoCatastral: React.FC<CodigoCatastralProps> = ({
  onChange,
  value,
  placeholder,
  disabled = false,
  form,
  tipoTramite,
}) => {
  const [fichaCatastral, setFichaCatastral] = useState(false);
  const [selectAmbito, setSelectAmbito] = useState<any>();
  const [selectSector, setSelectSector] = useState<any>();
  const [selectManzana, setSelectManzana] = useState<any>();
  const [formInitialized, setFormInitialized] = useState(false);

  //   const [selectTipoTierraUrbana, setSelectTipoTierraUrbana] = useState<any>();
  //   const [selectSubsector, setSelectSubsector] = useState<any>();

  const estate = useSelector((state: State) => state.est).dataEstate;

  useEffect(() => {
    if (!formInitialized && form) {
      const values = form.getFieldValue('codigoCatastral');

      if (values?.checkBoxFichaCatastral) {
        setFichaCatastral(values?.checkBoxFichaCatastral ?? false);
        return;
      }
      if (!values?.codCat_parroquia && !values?.codCat_sector) return;

      const { codCat_parroquia, codCat_sector } = values;

      let ambits = estate?.ambits.filter((f) => f?.id_parroquia === codCat_parroquia);
      let sectors = estate?.sectors.filter((f) => f?.id_parroquia === codCat_parroquia);

      let ambit = estate?.ambits.find((f) => f?.id_ambito === values?.ambito);
      if (tipoTramite === 119 && ambit?.cod_ambito === 'R01') {
        form.setFieldsValue({
          codigoCatastral: {
            codCat_sector: '000',
            manzana: '000',
            nivel: '000',
            unidad: '000',
            parcela: '000',
            SubParcela: '000',
          },
        });
        setSelectSector(null);
        setSelectManzana(null);
      } else {
        setSelectSector(sectors);
      }

      setSelectAmbito(ambits);

      let subsectors = estate?.subsectors.filter((f) => f?.id_sector === codCat_sector);
      let blocks = estate?.blocks.filter((f) => subsectors?.find((sub) => sub?.id_subsector === f?.id_subsector));

      setSelectManzana(blocks);
      setFormInitialized(true);
    }
  }, [form, formInitialized]);

  const handleParroquiaChange = (e) => {
    let ambits = estate?.ambits.filter((f) => f.id_parroquia === e);
    let sectors = estate?.sectors.filter((f) => f.id_parroquia === e);

    form.setFieldsValue({
      codigoCatastral: {
        codCat_sector: null,
        ambito: null,
        codCat_subsector: null,
        manzana: null,
        tipoTierraUrbana: null,
      },
    });

    setSelectAmbito(ambits);
    setSelectSector(sectors);
    // setSelectSubsector(null);
    setSelectManzana(null);
    // setSelectTipoTierraUrbana(null);
  };

  const handleSectorChange = (e) => {
    form.setFieldsValue({
      codigoCatastral: {
        codCat_subsector: null,
        manzana: null,
        tipoTierraUrbana: null,
      },
    });

    let subsectors = estate?.subsectors.find((f) => f.id_sector === e);
    let blocks = estate?.blocks.filter((f) => f.id_subsector === subsectors.id_subsector);
    setSelectManzana(blocks);

    // setSelectSubsector(subsectors);
    // setSelectTipoTierraUrbana(null);
  };

  // const handleManzanaChange = (e) => {
  // let blocks = estate?.blocks.find((f) => f.id_manzana === e);
  // let urbanLandTypes = estate?.urbanLandTypes.filter((f) => f.id_tipo_tierra_urbana === blocks.id_tipo_tierra_urbana);
  // form.setFieldsValue({ ...form, tipoTierraUrbana: null });
  // setSelectTipoTierraUrbana(urbanLandTypes);
  // };

  const handleFichaCatastral = (e) => {
    form.setFieldsValue({
      codigoCatastral: {
        checkBoxFichaCatastral: e.target.checked,
      },
    });
    setFichaCatastral(e.target.checked);
  };

  const handleAmbitoChange = (e) => {
    let ambit = estate?.ambits.find((f) => f.id_ambito === e);

    if (tipoTramite === 119 && ambit.cod_ambito === 'R01') {
      form.setFieldsValue({
        codigoCatastral: {
          codCat_sector: '000',
          manzana: '000',
          nivel: '000',
          unidad: '000',
          parcela: '000',
          SubParcela: '000',
        },
      });
      setSelectSector(null);
      setSelectManzana(null);
    } else {
      form.setFieldsValue({
        codigoCatastral: {
          codCat_sector: null,
          manzana: null,
          nivel: null,
          unidad: null,
          parcela: null,
          SubParcela: null,
        },
      });

      const values = form.getFieldValue('codigoCatastral');
      let sectors = estate?.sectors.filter((f) => f.id_parroquia === values.codCat_parroquia);
      setSelectSector(sectors ?? []);
    }
  };

  return (
    <>
      <Row style={{ paddingLeft: 16 }} gutter={[16, 16]}>
        <Col xl={8} xs={24}>
          <Form.Item name={['codigoCatastral', 'checkBoxFichaCatastral']}>
            <Checkbox onChange={handleFichaCatastral} checked={fichaCatastral}>
              No posee ficha catastral
            </Checkbox>
          </Form.Item>
        </Col>
        {fichaCatastral && (
          <Col xl={24} xs={24}>
            <Form.Item name={['codigoCatastral', 'observacionFichaCatastral']}>
              <Input.TextArea placeholder='Observación...' />
            </Form.Item>
          </Col>
        )}
      </Row>
      {!fichaCatastral && (
        <Row style={{ paddingLeft: 16 }} gutter={16}>
          <Col xl={8} xs={24}>
            <Form.Item
              label='Entidad'
              name={['codigoCatastral', 'entidad']}
              rules={[{ required: true, message: 'Debe ingestatear la entidad' }]}
            >
              <Select placeholder='Entidad'>
                {estate?.entities &&
                  estate?.entities?.map((option) => (
                    <Select.Option key={`${option.municipio} ${option.entidad_federal}`} value={option.id_entidad}>
                      {`${option.entidad_federal} ${option.municipio}`}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item
              label='Parroquia'
              name={['codigoCatastral', 'codCat_parroquia']}
              rules={[{ required: true, message: 'Debe ingestatear la parroquia' }]}
            >
              <Select placeholder='Parroquia' onChange={(e) => handleParroquiaChange(e)}>
                {estate?.parishes &&
                  estate?.parishes?.map((option) => (
                    <Select.Option key={'codCat-' + option.nombre} value={option.id_parroquia}>
                      {option.nombre}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item
              label='Ambito'
              name={['codigoCatastral', 'ambito']}
              rules={[{ required: true, message: 'Debe ingestatear el ambito' }]}
            >
              <Select placeholder='Ambito' onChange={(e) => handleAmbitoChange(e)}>
                {selectAmbito &&
                  selectAmbito?.map((option) => (
                    <Select.Option key={`${option.nombre} ${option.cod_ambito}`} value={option.id_ambito}>
                      {`${option.nombre} COD. ${option.cod_ambito}`}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item
              label='Sector'
              name={['codigoCatastral', 'codCat_sector']}
              rules={[{ required: true, message: 'Debe ingestatear el sector' }]}
            >
              <Select
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option!.children.props.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                }
                placeholder='Sector'
                onChange={(e) => handleSectorChange(e)}
              >
                {selectSector &&
                  selectSector.map((option) => (
                    <Select.Option key={option.cod_sector} value={option.id_sector}>
                      <div
                        style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}
                      >{`${option.nombre} COD. ${option.cod_sector}`}</div>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item
              label='Manzana'
              name={['codigoCatastral', 'manzana']}
              rules={[{ required: true, message: 'Debe ingestatear la manzana' }]}
            >
              <Select
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option!.children.props.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                }
                placeholder='Manzana'
                // onChange={(e) => handleManzanaChange(e)}
              >
                {selectManzana &&
                  selectManzana.map((option) => (
                    <Select.Option key={`${option.cod_manzana}-${option.id_manzana}`} value={option.id_manzana}>
                      <div
                        style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}
                      >{`${option.nombre} COD. ${option.cod_manzana}`}</div>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col xl={8} xs={24}>
        <Form.Item
          label='Tipo Tierra Urbana'
          name={['codigoCatastral', 'tipoTierraUrbana']}
          rules={[{ required: true, message: 'Debe ingestatear un tipo de tierra urbana' }]}
        >
          <Select placeholder='Tipo Tierra Urbana'>
            {selectTipoTierraUrbana &&
              selectTipoTierraUrbana.map((option) => (
                <Select.Option key={option.nombre} value={option.id_tipo_tierra_urbana}>
                  <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{option.nombre}</div>
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Col> */}
          <Col xl={8} xs={24}>
            <Form.Item
              label='Parcela'
              name={['codigoCatastral', 'parcela']}
              rules={[
                { required: true, message: 'Debe ingestatear una parcela' },
                { validator: Utils.validators.alphaNumeric, message: 'Debe ingestatear un valor alfanumérico' },
              ]}
            >
              <Input maxLength={3} placeholder='Parcela' />
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item
              label='SubParcela'
              name={['codigoCatastral', 'SubParcela']}
              rules={[
                { required: true, message: 'Debe ingestatear una SubParcela' },
                { validator: Utils.validators.alphaNumeric, message: 'Debe ingestatear un valor alfanumérico' },
              ]}
            >
              <Input maxLength={3} placeholder='SubParcela' />
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item
              label='Nivel'
              name={['codigoCatastral', 'nivel']}
              rules={[
                { required: true, message: 'Debe ingestatear un nivel' },
                { validator: Utils.validators.alphaNumeric, message: 'Debe ingestatear un valor alfanumérico' },
              ]}
            >
              <Input maxLength={3} placeholder='nivel' />
            </Form.Item>
          </Col>
          <Col xl={8} xs={24}>
            <Form.Item
              label='Unidad'
              name={['codigoCatastral', 'unidad']}
              rules={[
                { required: true, message: 'Debe ingestatear una unidad' },
                { validator: Utils.validators.alphaNumeric, message: 'Debe ingestatear un valor alfanumérico' },
              ]}
            >
              <Input maxLength={3} placeholder='Unidad' />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CodigoCatastral;

interface CodigoCatastralProps {
  onChange?: (e: any) => any;
  value?: any;
  placeholder?: string;
  disabled?: boolean;
  form: FormInstance;
  tipoTramite?: Number | String;
}
