import React, { useEffect, useState } from 'react';
import GetTaxpayerInfo from '../../Taxpayer/components/GetTaxpayerInfo';
import {
  Card,
  Form,
  Divider,
  Typography,
  Row,
  Col,
  Select,
  DatePicker,
  Input,
  Button,
  message,
  Checkbox,
  InputNumber,
  Descriptions,
  Tooltip,
} from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { useParams, useHistory } from 'react-router';
import { States, State, Taxpayer } from 'sigt';
import { connect, useSelector } from 'react-redux';
import UserSelect from '../../Forms/components/UserSelect';
import moment from 'moment';
import MultipleTransactions from '../../Forms/components/MultipleTransactions';
import axios from 'axios';
import { round } from 'lodash';
import TooltipCoin from '../../TooltipCoin';
import { roundCalc } from '../PaySpecificTax';
const server = process.env.REACT_APP_SERVER_URL;

const SpecialSettlements: React.FC<SpecialSettlementsProps> = ({ thm, branches, institutions, auth }) => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [esVigente, setEsVigente] = useState(true);

  const { width } = useWindowDimensions();
  const { id } = useParams<{ id: any }>();
  const history = useHistory();
  const [form] = Form.useForm();

  const [blockMonto, setBlockMonto] = useState(false);

  const petro = useSelector((state: State) => state.coin.petro);
  const pesoBS = useSelector((state: State) => state.coin.peso);
  const dolar = useSelector((state: State) => state.coin.dolar);
  const euro = useSelector((state: State) => state.coin.euro);

  const taxPayer: Taxpayer = (history.location.state as any)?.contribuyente;
  const showAll = auth.user?.institucion?.id === 9;
  const cpuBranches = ['111', '123', '124'];

  const ramosPP = [
    291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301, 302, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256,
    257, 258, 259, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280, 281,
    282, 283, 284, 285, 286, 287, 288, 289, 290, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513, 514, 515, 516, 517, 518, 519,
    520,
  ];

  const ramosLicores = [559, 560];

  useEffect(() => {
    if (!(history.location.state as any)?.contribuyente || (history.location.state as any)?.contribuyente?.id !== id) {
      history.push('/dashboard/impuestos/especiales');
    }
    if (auth.user?.institucion?.nombreCorto !== 'SAGAS' && auth.user?.institucion?.nombreCompleto !== 'CPU') {
      history.goBack();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      montoAPagar: +((+form.getFieldValue('monto') || 0) * (+form.getFieldValue('unidad') || 1)).toFixed(2),
    });
  }, [form]);

  // useEffect(() => {
  //   if ( auth?.user?.tipoUsuario === 4 && !id) {
  //     if( auth?.user?.contribuyente?.id) {
  //       history.push(`/dashboard/impuestos/especiales/${auth?.user?.contribuyente?.id}`);
  //     }
  //     else{
  //       history.push('/dashboard');
  //     }
  //   }
  // },[])

  useEffect(() => {
    if (data.ramo) {
      let bran = branches.find((b) => b.id === data.ramo);
      console.log(data, bran);
      if (data.subramo) {
        let subr = bran?.subramos?.find((s) => s.id === data.subramo);
        form.setFieldsValue({ monto: subr?.monto && subr?.monto > 0 ? round(subr?.monto, 2) : 0 });
        setBlockMonto(subr?.monto && subr?.monto > 0 ? true : false);
      } else {
        if ((bran?.subramos?.length || 0) > 0 && bran?.monto && bran?.monto > 0) {
          form.setFieldsValue({ monto: bran?.monto });
          setBlockMonto(true);
        } else {
          form.setFieldsValue({ monto: bran?.monto && bran?.monto > 0 ? round(bran?.monto, 2) : 0 });
          setBlockMonto(bran?.monto && bran?.monto > 0 ? true : false);
        }
      }
      form.setFieldsValue({ metrosCuadrados: 0 });
    }
  }, [branches, data.ramo, data.subramo]);

  const setNewMontoPP = (e) => {
    let metrosCuadrados = e;
    let montoRamo = branches.find((b) => b.id === data.ramo)?.monto ?? 0;

    let monto = metrosCuadrados * montoRamo;
    console.log(e, metrosCuadrados, montoRamo, monto);

    form.setFieldsValue({ monto: monto ? monto : montoRamo });
  };

  const setNewMontoLicares = (e) => {
    let cantidad = e;
    let montoRamo = branches.find((b) => b.id === data.ramo)?.monto ?? 0;

    let monto = cantidad * montoRamo;
    console.log(e, cantidad, montoRamo, monto);

    form.setFieldsValue({ monto: monto ? monto : montoRamo });
  };

  const submit = async () => {
    const values = await form.validateFields();
    const ingresado = esVigente ? 0 : calcTotalIngresado();
    const montoTimesUnidad = +((+values.monto || 0) * (+values.unidad || 1)).toFixed(2);
    console.log(montoTimesUnidad, ingresado);
    if (!esVigente && (montoTimesUnidad > +ingresado || montoTimesUnidad < +ingresado)) {
      message.error('El monto de los pagos debe ser igual al monto de la liquidación especial');
    } else {
      values.fechaCancelada = {
        month: values.fechaCancelada.locale('es').format('MMMM'),
        year: parseInt(values.fechaCancelada.locale('es').format('YYYY')),
      };
      values.monto = +(+values.monto / petro).toFixed(8);
      values.impuestos = [
        {
          fechaCancelada: values.fechaCancelada,
          ramo: values.ramo,
          subramo: values.subramo,
          monto: values.monto * values.unidad,
        },
      ];
      values.tipoDocumento = taxPayer.tipoDocumento;
      values.documento = taxPayer.documento;
      values.tipoContribuyente = taxPayer.tipoContribuyente;
      if (!esVigente) {
        values.pagos
          .filter((p) => p)
          .forEach((p) => {
            Object.keys(p).forEach((k) => {
              if (moment.isMoment(p[k])) {
                p[k] = p[k].toISOString();
              }
            });
            if (p.metodoPago === 'EFECTIVO PESO' || p.metodoPago === 'EFECTIVO DOLAR' || p.metodoPago === 'EFECTIVO EURO') {
              p.costo = +(
                p.costo *
                (p.metodoPago === 'EFECTIVO PESO'
                  ? pesoBS
                  : p.metodoPago === 'EFECTIVO DOLAR'
                  ? dolar
                  : p.metodoPago === 'EFECTIVO EURO'
                  ? euro
                  : 1)
              ).toFixed(2);
            }
          });

        values.pagos.forEach((pago) => {
          if (pago?.metodoPago?.includes('EFECTIVO')) {
            delete pago.banco;
            delete pago.destino;
            delete pago.referencia;
          }
        });
      }
      values.esVigente = esVigente;
      setLoading(true);
      try {
        const response = await axios.post(`${server}/settlements/special`, values, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        message.success('Liquidación creada de manera exitosa');
        history.push('/dashboard/impuestos/especiales');
        if (response.status === 201 && response.data.solicitud.recibo) {
          if (Array.isArray(response.data.solicitud.recibo)) {
            response.data.solicitud.recibo.forEach((recibo) => window.open(recibo, '_blank'));
          } else {
            const win = window.open(response.data.solicitud.recibo, '_blank');
            win?.focus();
          }
        }
        form.resetFields();
      } catch (e) {
        message.error(e.response?.data?.message || 'Error al crear liquidación');
      } finally {
        setLoading(false);
      }
    }
  };

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(+number);

  const calcTotalIngresado = () =>
    +(
      data.pagos?.reduce((acc, p) => {
        console.log(p);
        if (!p) return 0;
        if (p.metodoPago === 'EFECTIVO PESO') {
          const amount = +p.costo * +pesoBS;
          return acc + amount;
        }
        if (p.metodoPago === 'EFECTIVO DOLAR') {
          const amount = +p.costo * +dolar;
          return acc + amount;
        }
        if (p.metodoPago === 'EFECTIVO EURO') {
          const amount = +p.costo * +euro;
          return acc + amount;
        }
        return acc + +p.costo;
      }, 0) || 0
    ).toFixed(2);

  const calcDiff = () =>
    Math.abs(calcTotalIngresado() - (+form.getFieldValue('monto') || 0) * (+form.getFieldValue('unidad') || 1));

  const calcDiffSign = () => calcTotalIngresado() >= (+form.getFieldValue('monto') || 0) * (+form.getFieldValue('unidad') || 1);

  return (
    <Card
      style={{ height: '100%' }}
      title='Liquidaciones de Tasas e Impuestos'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {!id && <GetTaxpayerInfo />}
      {id && (
        <Form
          form={form}
          layout='vertical'
          onFinish={submit}
          scrollToFirstError
          onValuesChange={(c, v) => {
            form.setFieldsValue(v);
            setData(v);
          }}
        >
          <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
            <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
              Usuario de S.U.T.
            </Typography.Title>
          </Divider>
          <UserSelect users={taxPayer?.usuarios || []} form={form} />
          <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
            <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
              Referencia Municipal (R.I.M.)
            </Typography.Title>
          </Divider>
          <Row gutter={24}>
            <Col xs={24} xl={8}>
              <Form.Item
                label='Referencia Municipal (Si posee)'
                name='rim'
                rules={[{ required: false, message: 'Debe ingresar la referencia municipal' }]}
              >
                <Select placeholder='Seleccione la referencia municipal'>
                  {taxPayer?.sucursales?.map((s) => (
                    <Select.Option value={s.referenciaMunicipal} key={s.id}>
                      {s.referenciaMunicipal}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
            <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
              Liquidación de Tasas e Impuestos
            </Typography.Title>
          </Divider>
          <Row gutter={24}>
            <Col xs={24} xl={8}>
              <Form.Item label='Ramo' name='ramo' rules={[{ required: true, message: 'Debe ingresar el ramo' }]}>
                <Select
                  placeholder='Seleccione un ramo'
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option!.children.props.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {(showAll ? branches : branches.filter((b) => cpuBranches.includes(b.codigo || '')))
                    .filter((b) => b.liquidacionEspecial)
                    .map((b) => (
                      <Select.Option value={b.id} key={b.codigo}>
                        <div style={{ overflow: 'hidden', whiteSpace: 'break-spaces' }}>{b.descripcion}</div>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {data.ramo &&
              (branches
                .find((b) => b.id === data.ramo)
                ?.subramos?.filter(
                  (s) =>
                    !s.descripcion.includes('GTIC') && !s.descripcion.includes('Convenio') && !s.descripcion.includes('ordinario')
                )?.length || 0) > 1 && (
                <Col xs={24} xl={8}>
                  <Form.Item label='Subramo' name='subramo' rules={[{ required: true, message: 'Debe ingresar el ramo' }]}>
                    <Select placeholder='Seleccione un subramo'>
                      {branches
                        .find((b) => b.id === data.ramo)
                        ?.subramos?.filter(
                          (s) =>
                            !s.descripcion.includes('GTIC') &&
                            !s.descripcion.includes('Convenio') &&
                            !s.descripcion.includes('ordinario')
                        )
                        .map((b) => (
                          <Select.Option value={b.id} key={b.id}>
                            {b.descripcion}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            <Col xs={24} xl={8}>
              <Form.Item
                label='Fecha de Liquidación'
                name='fechaCancelada'
                rules={[{ required: true, message: 'Debe ingresar la fecha de liquidación' }]}
              >
                <DatePicker
                  format='DD/MM/YYYY'
                  placeholder='Seleccione la fecha'
                  disabledDate={(current) => current > moment().endOf('day')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Unidad' name='unidad' rules={[{ required: false }]}>
                <InputNumber placeholder='Unidades' type='number' step='0.01' style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={16}>
              <Form.Item label='Descripción del caso' name='desc' rules={[{ required: true }]}>
                <Input placeholder='Descripción' />
              </Form.Item>
            </Col>

            {ramosLicores.includes(data.ramo) && (
              <Col xs={24} xl={8}>
                <Form.Item label='Cantidad' name='cantidad' rules={[{ required: true, message: 'Debe ingresar la cantidad' }]}>
                  <InputNumber
                    placeholder='Cantidad'
                    onChange={(e) => {
                      setNewMontoLicares(e);
                    }}
                    style={{ width: '100%', fontSize: 18 }}
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} xl={8}>
              <Form.Item
                label='Monto por unidad'
                name='monto'
                rules={[{ required: true, message: 'Debe ingresar el monto de la liquidación' }]}
              >
                <InputNumber
                  placeholder='Monto'
                  disabled={blockMonto}
                  // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  // parser={value => value ? value.replace(/Bs\s?|(,*)/g, '') : ''}
                  style={{ width: '100%', fontSize: 18 }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Monto a pagar' name='montoAPagar'>
                <InputNumber
                  placeholder='Monto'
                  disabled={true}
                  // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  // parser={value => value ? value.replace(/Bs\s?|(,*)/g, '') : ''}
                  style={{ width: '100%', fontSize: 18 }}
                />
              </Form.Item>
            </Col>
          </Row>
          {showAll && (
            <Checkbox checked={!esVigente} onChange={(e) => setEsVigente(!e.target.checked)}>
              Incluir pago de la liquidación
            </Checkbox>
          )}
          {!esVigente && showAll && (
            <>
              <Divider orientation='left' style={{ marginLeft: -20, marginBottom: 0 }}>
                <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                  Datos de Pago
                </Typography.Title>
              </Divider>
              <MultipleTransactions
                selectedInstitution={institutions?.find((i) => i.id === 9)}
                form={form}
                data={data}
                maxCred={0}
              />
              <Row>
                <Divider orientation='left' style={{ marginLeft: 0 }}>
                  <Typography.Title level={4}>Total Ingresado</Typography.Title>
                </Divider>
                <Col xl={24} xs={24}>
                  <Descriptions bordered>
                    <Descriptions.Item
                      label='Total Ingresado'
                      style={{
                        fontSize: '1.2rem',
                      }}
                    >
                      <TooltipCoin priceBs={calcTotalIngresado()} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                        Bs. {console.log('data: ', data) + '' === 'undefined' && formatCurrency(calcTotalIngresado())}
                      </TooltipCoin>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Divider orientation='left' style={{ marginLeft: 0 }}>
                  <Typography.Title level={4}>Monto Restante</Typography.Title>
                </Divider>
                <Col xl={24} xs={24}>
                  <Descriptions bordered>
                    <Descriptions.Item
                      label={calcDiffSign() ? 'Total a Favor' : 'Total Restante'}
                      style={{
                        color: calcDiffSign() ? '#86E92B' : 'red',
                        fontSize: '1.2rem',
                      }}
                    >
                      <TooltipCoin priceBs={calcDiff()} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                        Bs. {formatCurrency(calcDiff())}
                      </TooltipCoin>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </>
          )}
          <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              <Button
                onClick={() =>
                  history.push('/dashboard/impuestos/especiales', {
                    contribuyentes: (history.location.state as any)?.contribuyentes,
                  })
                }
              >
                Atrás
              </Button>
            </Col>
            <Row gutter={12}>
              <Col>
                <Button loading={loading} type='primary' onClick={form.submit}>
                  Crear liquidación
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      )}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  branches: state.brch.branches,
  banks: state.bk.banks,
  institutions: state.inst.institutions,
  auth: state.auth,
});

export default connect(mapStateToProps)(SpecialSettlements);

interface SpecialSettlementsProps {
  thm: States.ThemeColors;
  branches: States.Branches['branches'];
  banks: States.Banks['banks'];
  institutions: States.Institutions['institutions'];
  auth: States.Auth;
}
