import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Card, Row, Col, Form, Button, Input, message, Descriptions, Alert, Divider, Typography, Select, Checkbox } from 'antd';
import { SearchOutlined, CreditCardFilled } from '@ant-design/icons';
import { State, States } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import { deleteInterestAE, getAEInterestOfApplicationAllData } from '../../services/taxes';
import { formatCurrency, roundCalc } from './PaySpecificTax';
import Table, { ColumnsType } from 'antd/lib/table';
import MultipleTransactions from '../Forms/components/MultipleTransactions';
import TooltipCoin from '../TooltipCoin';
import axios from 'axios';
import moment from 'moment';
import { useParams, useHistory } from 'react-router';
import { CargoSedemat } from '../../routes';
import { Utils } from '../../utils/validators';

const server = process.env.REACT_APP_SERVER_URL;

const InterestAE: React.FC<InterestAEProps> = ({ thm, auth, institutions }) => {
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [AEInterests, setAEInterests] = useState<ResponseLiqInteres | null>(null);
  const [data, setData] = useState<any>({});
  const [tipoDocumento, setTipoDocumento] = useState('V');
  const [taxPayer, setTaxPayer] = useState<any>({});
  const [deleteInterest, setDeleteInterest] = useState<boolean>(false);

  const dolar = useSelector((state: State) => state.coin.dolar) || 18000000;
  const euro = useSelector((state: State) => state.coin.euro) || 18000000;
  const pesoBS = useSelector((state: State) => state.coin.peso) || 18000000;

  const selectedInstitution = institutions?.find((i) => i.id === 9);

  const [searchForm] = Form.useForm();
  const [form] = Form.useForm();
  const { id } = useParams<{ id: any }>();
  const history = useHistory();

  const RAMO_AE = 'PATENTE DE INDUSTRIA Y COMERCIO';
  const petro = useSelector((state: State) => state.coin.petro);

  const CAN_DELETE_INTEREST = [CargoSedemat.Master, CargoSedemat.Administrador];

  useEffect(() => {
    if (!(history.location.state as any)?.contribuyente || (history.location.state as any)?.contribuyente?.id !== id) {
      history.push('/dashboard/impuestos/interesesAE');
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    const values = await form.validateFields();

    values.monto = +(+(AEInterests?.totalInteres ?? 0) / petro).toFixed(8);
    values.impuestos = AEInterests
      ? AEInterests?.liquidaciones?.map((l) => ({
          ...l.datos,
          monto: Number((Number(l?.interes ?? 0) / Number(petro ?? 1)).toFixed(8)),
          ramo: 926,
          fechaCancelada: l?.datos?.fecha,
          desglose: l?.datos?.desglose?.map((d) => ({ ...d, montoCobrado: Number(d?.montoCobrado?.toFixed(2) ?? 0) })) ?? [],
        }))
      : null;

    values.tipoDocumento = tipoDocumento;
    values.documento = taxPayer.documento;
    values.rim = taxPayer.rim;
    values.tipoContribuyente = taxPayer.tipoContribuyente;
    values.esVigente = false;
    values.solicitudAE = Number(taxPayer.idSolicitud.trim());

    values.pagos
      .filter((p) => p)
      .forEach((p) => {
        Object.keys(p).forEach((k) => {
          if (moment.isMoment(p[k])) {
            p[k] = p[k].toISOString();
          }
        });
        if (p.metodoPago === 'EFECTIVO PESO' || p.metodoPago === 'EFECTIVO DOLAR' || p.metodoPago === 'EFECTIVO EURO') {
          p.costo = +(
            p.costo *
            (p.metodoPago === 'EFECTIVO PESO'
              ? pesoBS
              : p.metodoPago === 'EFECTIVO DOLAR'
              ? dolar
              : p.metodoPago === 'EFECTIVO EURO'
              ? euro
              : 1)
          ).toFixed(2);
        }
      });

    values.pagos.forEach((pago) => {
      if (pago?.metodoPago?.includes('EFECTIVO')) {
        delete pago.banco;
        delete pago.destino;
        delete pago.referencia;
      }
    });

    setLoading(true);
    try {
      const response = await axios.post(`${server}/settlements/special`, values, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      message.success('Liquidación de intereses creada de manera exitosa');
      if (response.status === 201 && response.data.solicitud.recibo) {
        if (Array.isArray(response.data.solicitud.recibo)) {
          response.data.solicitud.recibo.forEach((recibo) => window.open(recibo, '_blank'));
        } else {
          const win = window.open(response.data.solicitud.recibo, '_blank');
          win?.focus();
        }

        setAEInterests(null);
        form.resetFields();
        searchForm.resetFields();
      }
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al crear liquidación');
    } finally {
      setLoading(false);
    }
  };

  const firstLetterMayus = (s: string) => (s ? s.charAt(0).toUpperCase() + s.slice(1) : s);

  const search = async () => {
    try {
      setSearching(true);
      const values = await searchForm.validateFields();
      const { idSolicitud, tipoContribuyente, documento, rim } = values;

      const response: ResponseLiqInteres = await getAEInterestOfApplicationAllData({ ...values, tipoDocumento }, auth.token);
      setAEInterests({ liquidaciones: response.liquidaciones, totalInteres: response.totalInteres });
      setTaxPayer({ idSolicitud, tipoContribuyente, documento, rim });
    } catch (error) {
      message.error(error?.response?.data?.message ?? 'Error al obtener los datos de intereses de actividades económicas');
      console.error('Error al obtener los datos de intereses de actividades económicas', error);
    } finally {
      setSearching(false);
    }
  };

  const handleDeleteInterest = async () => {
    try {
      setLoading(true);
      const values = await searchForm.validateFields();
      const { idSolicitud } = values;

      const response = await deleteInterestAE(idSolicitud, auth.token ?? '');
      message.error(response?.data?.message ?? 'Intereses de actividades económicas eliminados de exitosamente');
    } catch (error) {
      message.error(error?.response?.data?.message ?? 'Error al momento de eliminar los intereses de actividades económicas');
    } finally {
      setLoading(false);
    }
  };

  const AEInterestsColumns: ColumnsType<any> = [
    {
      title: 'Tipo de Liquidacion',
      render: () => <span>INTERESES {RAMO_AE}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (record) => (
        <span>
          {firstLetterMayus(record.month)} {record.year}
        </span>
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'interes',
      render: (record) => <span>Bs. {formatCurrency(Number(record ?? 0))}</span>,
    },
  ];

  const calcTotalIngresado = () =>
    data.pagos?.reduce((acc, p) => {
      if (!p) return 0;
      if (p.metodoPago === 'EFECTIVO PESO') {
        const amount = +p.costo * +pesoBS;
        return acc + amount;
      }
      if (p.metodoPago === 'EFECTIVO DOLAR') {
        const amount = +p.costo * +dolar;
        return acc + amount;
      }
      if (p.metodoPago === 'EFECTIVO EURO') {
        const amount = +p.costo * +euro;
        return acc + amount;
      }
      return acc + +p.costo;
    }, 0) || 0;

  const calcDiffSign = () => roundCalc(calcTotalIngresado()) >= roundCalc(Number(AEInterests?.totalInteres ?? 0));

  const calcDiff = () => Math.abs(calcTotalIngresado() - roundCalc(Number(AEInterests?.totalInteres ?? 0)));

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  return (
    <Card
      style={{ height: '100%' }}
      title='Intereses De Actividades Económicas'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', padding: 40 }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {CAN_DELETE_INTEREST.includes(auth?.user?.institucion?.cargo?.id ?? 0) && (
        <Checkbox style={{ marginBottom: 40 }} checked={deleteInterest} onClick={() => setDeleteInterest(!deleteInterest)}>
          Eliminar intereses
        </Checkbox>
      )}
      <Form layout='vertical' form={searchForm}>
        {deleteInterest ? (
          <Row gutter={24}>
            <Col xl={7} xs={24}>
              <Form.Item
                label='Id Solicitud'
                name='idSolicitud'
                rules={[{ required: true, message: 'Debe indicar el numero de la solicitud' }]}
                normalize={Utils.normalize.isNumber}
              >
                <Input placeholder='Id Solicitud' />
              </Form.Item>
            </Col>
            <Col xl={6} xs={12}>
              <Button
                onClick={() => handleDeleteInterest()}
                style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }}
                loading={searching}
                icon={<SearchOutlined />}
                type='primary'
              >
                Eliminar
              </Button>
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={24}>
              <Col xl={7} xs={24}>
                <Form.Item
                  label='Tipo de Contribuyente'
                  name='tipoContribuyente'
                  rules={[{ required: true, message: 'Debe indicar el tipo de contribuyente' }]}
                >
                  <Select placeholder='Tipo de Contribuyente'>
                    <Select.Option value='NATURAL'>Natural</Select.Option>
                    <Select.Option value='JURIDICO'>Jurídico</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} xl={7}>
                <Form.Item
                  label='Documento de Identidad'
                  name='documento'
                  rules={[{ required: true, message: 'Debe indicar el documento de identidad' }]}
                >
                  <Input
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') search();
                    }}
                    placeholder='Documento de Identidad'
                    addonBefore={tipoAddon}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={7}>
                <Form.Item label='R.I.M.' name='rim' rules={[{ required: true, message: 'Debe indicar el R.I.M.' }]}>
                  <Input placeholder='R.I.M.' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xl={7} xs={24}>
                <Form.Item
                  label='Id Solicitud'
                  name='idSolicitud'
                  rules={[{ required: true, message: 'Debe indicar el numero de la solicitud' }]}
                  normalize={Utils.normalize.isNumber}
                >
                  <Input placeholder='Id Solicitud' />
                </Form.Item>
              </Col>
              <Col xl={6} xs={12}>
                <Button
                  onClick={() => search()}
                  style={{ marginTop: width < 1200 ? 0 : 38, marginBottom: width < 1200 ? 20 : 0 }}
                  loading={searching}
                  icon={<SearchOutlined />}
                  type='primary'
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form>

      {AEInterests && (
        <>
          <Row gutter={24} style={{ marginTop: 20 }}>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Intereses de actividades económicas</Typography.Title>
            </Divider>
            <Col span={24} style={{ marginBlock: 20 }}>
              <Alert
                description=''
                message='En la siguiente tabla se muestra intereses sobre el ramo de actividades económicas.'
                type='warning'
              />
            </Col>
            <Col span={24}>
              <Table
                columns={AEInterestsColumns}
                dataSource={AEInterests?.liquidaciones.map((l) => ({
                  interes: l.interes,
                  fecha: l.datos.fecha,
                  key: l.id_liquidacion,
                }))}
                bordered
                pagination={false}
              />
            </Col>
            <Col span={24}>
              <Descriptions bordered>
                <Descriptions.Item label='Total de Intereses'>
                  Bs. {formatCurrency(Number(AEInterests?.totalInteres ?? 0))}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Form
            layout='vertical'
            style={{ marginTop: 20 }}
            form={form}
            initialValues={{ metodoPago: 'TRANSFERENCIA' }}
            onValuesChange={(c, v) => {
              form.setFieldsValue(v);
              setData(v);
            }}
          >
            <MultipleTransactions selectedInstitution={selectedInstitution} form={form} data={data} />
          </Form>
          <Row>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Total Ingresado</Typography.Title>
            </Divider>
            <Col xl={24} xs={24}>
              <Descriptions bordered>
                <Descriptions.Item
                  label='Total Ingresado'
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  <TooltipCoin priceBs={calcTotalIngresado()} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                    Bs.{' '}
                    {console.log('data: ', data) + '' === 'undefined' &&
                      formatCurrency(
                        // data.pagos
                        //   ?.filter((p) => p)
                        //   ?.map((p) => p.costo)
                        //   ?.reduce((c, v) => c + v, 0) || 0

                        calcTotalIngresado()
                      )}
                  </TooltipCoin>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Monto Restante</Typography.Title>
            </Divider>
            <Col xl={24} xs={24}>
              <Descriptions bordered>
                <Descriptions.Item
                  label={
                    // (data.pagos
                    //   ?.filter((p) => p)
                    //   ?.map((p) => p.costo)
                    //   ?.reduce((c, v) => c + v, 0) || 0)
                    calcDiffSign() ? 'Total a Favor' : 'Total Restante'
                  }
                  style={{
                    color:
                      // (data.pagos
                      // ?.filter((p) => p)
                      // ?.map((p) => p.costo)
                      // ?.reduce((c, v) => c + v, 0) || 0)
                      calcDiffSign() ? '#86E92B' : 'red',
                    fontSize: '1.2rem',
                  }}
                >
                  <TooltipCoin priceBs={calcDiff()} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                    Bs. {formatCurrency(calcDiff())}
                  </TooltipCoin>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              <Button
                type='primary'
                loading={loading}
                style={{ marginTop: 15 }}
                onClick={() => handleSubmit()}
                icon={<CreditCardFilled />}
              >
                Pagar
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  auth: state.auth,
  institutions: state.inst.institutions,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InterestAE);

interface InterestAEProps {
  thm: States.ThemeColors;
  auth: States.Auth;
  institutions: States.Institutions['institutions'];
}
