import { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { EstateTypes, SET_DATA_ESTATE } from './actionsTypes';
import { DataEstate } from 'sigt';
import { getDataIU } from '../../services/estates';

export const setDataEstate = (data: DataEstate): EstateTypes => ({ type: SET_DATA_ESTATE, payload: data });

export const fetchDataIU =
  (token: string): ThunkAction<Promise<void>, {}, {}, EstateTypes> =>
  async (dispatch: ThunkDispatch<{}, {}, EstateTypes>): Promise<void> => {
    try {
      const response: DataEstate = await getDataIU(token);
      dispatch(setDataEstate(response));
    } catch (e) {
      throw e;
    }
  };
