import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { States, State } from 'sigt';
import {
  Card,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Table,
  Tag,
  message,
  InputNumber,
  Typography,
  Divider,
  Alert,
  Descriptions,
} from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { getStatements, declareSettlements } from '../../services/statements';
import { cloneDeep } from 'lodash';
import Petro from '../Icons/Petro';
import { isBeforeOctober2023 } from '../../utils/fromMonthToNumber';

const SubstituteStatement: React.FC<SubstituteStatementProps> = ({ thm, auth }) => {
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [contribuyente, setContribuyente] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [declaring, setDeclaring] = useState(false);
  const [liquidaciones, setLiquidaciones] = useState<any[]>([]);
  const [selectedLiq, setSelectedLiq] = useState<any>(null);
  const [data, setData] = useState<any>({});

  const petro = useSelector((state: State) => state.coin.petro);
  // const solvencia = +((selectedLiq?.datos?.desglose?.[0]?.costoSolvencia || 0) / petro).toFixed(2)
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();

  const firstLetterMayus = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  useEffect(() => {
    if (selectedLiq) {
      for (const item of selectedLiq.datos.desglose) {
        form.setFieldsValue({ [item.id]: item.montoDeclarado });
        setData((data) => ({ ...data, [item.id]: item.montoDeclarado }));
      }
    }
    // eslint-disable-next-line
  }, [selectedLiq]);

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);
  const formatCurrencyRound = (number: number) =>
    new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(number);

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando pago', color: 'warning' };
    else return { name: 'Solvente', color: 'green' };
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Documento de Identidad',
      dataIndex: ['contribuyente', 'documento'],
      render: (doc, r) => (
        <span>
          {r.contribuyente.tipoDocumento}-{doc}
        </span>
      ),
    },
    {
      title: 'R.I.M.',
      dataIndex: 'referencia',
      render: (rim) => <span>{rim ? rim : 'N/A'}</span>,
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      render: (tipo) => <span>Actividades Económicas</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => <span>{fecha && fecha.month ? `${firstLetterMayus(fecha.month)} ${fecha.year}` : 'Sin Fecha'}</span>,
    },
    {
      title: 'Monto en Bs.',
      dataIndex: 'montoPetro',
      render: (monto, record) => <span>Bs. {formatCurrency(+((+monto || 0) * petro || record.monto))}</span>,
    },
    {
      title: 'Monto (MMV)',
      dataIndex: 'montoPetro',
      render: (monto, record) => (
        <span>
          {monto || Number(+((record.monto || 0) / petro).toFixed(8))} <Petro />
        </span>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'monto',
      render: (_, record) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(record.estado).color}>
          {getState(record.estado).name}
        </Tag>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      render: (_, record) => (
        <Button type='primary' onClick={() => setSelectedLiq(record)}>
          Declarar
        </Button>
      ),
    },
  ];

  const AEcolumns: ColumnsType<any> = [
    {
      title: 'Aforo',
      dataIndex: 'nombreActividad',
      width: '40%',
    },
    {
      title: 'Alicuota',
      dataIndex: 'alicuota',
      key: 'alicuota',
      render: (record) => <span>{parseFloat((record * 100).toPrecision(15)).toFixed(2)}%</span>,
    },
    {
      title: 'Mínimo Tributable',
      dataIndex: 'minimoTributable',
      render: (mt, record) =>
        !record.exonerado ? (
          <span>{`Bs. ${formatCurrencyRound(
            isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && record.id === 527
              ? 6.6 * petro
              : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
              ? 11.3 * petro
              : mt * petro
          )}`}</span>
        ) : (
          <b>EXONERADO</b>
        ),
    },
    {
      title: 'Monto a Declarar (Bs.)',
      dataIndex: 'date',
      render: (date, record) => {
        return (
          <Form.Item name={record.id} style={{ margin: 0, width: '100%' }}>
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
              min={0}
              step={1}
              style={{ width: '100%' }}
              placeholder='Monto'
            />
          </Form.Item>
        );
      },
    },
    {
      title: 'Impuestos',
      dataIndex: 'montoDeclarado',
      render: (date, record) => {
        const exists = data[record.id];
        return (
          <span>
            {exists
              ? `Bs. ${formatCurrency(
                  parseFloat((data[record.id] * record.alicuota).toFixed(2)) <= +(record.minimoTributable * petro).toFixed(2) &&
                    isMinTributable(record)
                    ? isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && record.id === 527
                      ? +(6.6 * petro).toFixed(2)
                      : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
                      ? +(11.3 * petro).toFixed(2)
                      : +(record.minimoTributable * petro).toFixed(2)
                    : parseFloat((data[record.id] * record.alicuota).toFixed(2))
                )}`
              : exists === 0
              ? `Bs. ${
                  isMinTributable(record)
                    ? isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && record.id === 527
                      ? formatCurrencyRound(6.6 * petro)
                      : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
                      ? formatCurrencyRound(11.3 * petro)
                      : formatCurrencyRound(record.minimoTributable * petro)
                    : 0
                }`
              : 'N/A'}
          </span>
        );
      },
    },
    {
      title: 'MMV',
      dataIndex: 'date',
      render: (date, record) => {
        const exists = data[record.id];
        return (
          <span>
            {exists
              ? `${
                  Number((data[record.id] * record.alicuota).toFixed(2)) <= +(record.minimoTributable * petro).toFixed(2) &&
                  isMinTributable(record)
                    ? isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && record.id === 527
                      ? (6.6).toFixed(8)
                      : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
                      ? (11.3).toFixed(8)
                      : Number(+record.minimoTributable)
                    : Number(
                        +(
                          (data[record.id] + (selectedLiq?.estado !== 'finalizado' ? record.montoCobrado : 0)) *
                          record.alicuota
                        ).toFixed(8) / petro
                      ).toFixed(8)
                }`
              : exists === 0
              ? `${
                  isMinTributable(record)
                    ? isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && record.id === 527
                      ? (6.6).toFixed(8)
                      : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
                      ? (11.3).toFixed(8)
                      : Number(+record.minimoTributable)
                    : Number(+(selectedLiq?.estado !== 'finalizado' ? +(record.montoCobrado / petro).toFixed(8) : 0))
                }`
              : 'N/A'}{' '}
            <Petro />
          </span>
        );
      },
    },
  ];

  const readOnlyAE = cloneDeep(AEcolumns);
  readOnlyAE[3] = {
    title: 'Monto Declarado',
    dataIndex: 'montoDeclarado',
    render: (monto) => <span>Bs. {formatCurrency(monto)}</span>,
  };
  readOnlyAE[4] = {
    title: 'Impuestos',
    dataIndex: 'montoCobrado',
    render: (monto) => <span>Bs. {formatCurrency(monto)}</span>,
  };
  readOnlyAE[5] = {
    title: 'MMV',
    dataIndex: 'montoCobrado',
    render: (monto) => (
      <span>
        {+(monto / petro).toFixed(8)} <Petro />
      </span>
    ),
  };

  const search = async () => {
    const values = await form.validateFields();
    const params = {
      referencia: values.referencia,
      documento: values.documento || auth.user?.contribuyente?.documento,
      tipoDocumento: auth.user?.tipoUsuario === 4 ? auth.user?.contribuyente?.tipoDocumento : tipoDocumento,
    };
    setContribuyente(params);

    setLoading(true);
    try {
      const liqs = await getStatements('sustitutiva', params as any, auth.token);
      setLiquidaciones(
        liqs.map((l) => ({
          ...l,
          ...params,
          contribuyente: params,
        }))
      );
      message.success('Liquidaciones obtenidas de manera exitosa');
    } catch (e) {
      message.error(
        e.response?.data?.message || 'No se encontraron liquidaciones a las cuales se les pueda hacer una declaración sustitutiva'
      );
    } finally {
      setLoading(false);
    }
  };

  const declare = async () => {
    if (selectedLiq && isNaN(getAESubtotal() as any)) return message.error('Debe declarar todos los aforos para poder continuar');
    setDeclaring(true);
    try {
      if (selectedLiq.estado === 'finalizado') {
        if (getAESubtotal() > getPreviousDeclareSubTotal()) {
          message.error(
            'El monto a declarar es mayor al monto de la declaración previa. Si busca complementar la declaración puede utilizar el modulo de declaración complementaria',
            10
          );
          return;
        }
      }

      const desglose = selectedLiq.datos.desglose.map((d) => ({
        montoDeclarado: data[d.id],
        montoCobrado: isMinTributable(d)
          ? isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && d.id === 527
            ? +(6.6 * petro).toFixed(2)
            : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
            ? +(11.3 * petro).toFixed(2)
            : +(d.minimoTributable * petro).toFixed(2)
          : +(data[d.id] * d.alicuota).toFixed(2),
        aforo: d.id,
        minimoTributable:
          isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && d.id === 527
            ? 6.6
            : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
            ? 11.3
            : d.minimoTributable,
      }));
      const desgloseFull = selectedLiq.datos.desglose.map((d) => ({
        ...d,
        montoDeclarado: data[d.id],
        montoCobrado: isMinTributable(d)
          ? isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && d.id === 527
            ? +(6.6 * petro).toFixed(2)
            : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
            ? +(11.3 * petro).toFixed(2)
            : +(d.minimoTributable * petro).toFixed(2)
          : +(data[d.id] * d.alicuota).toFixed(2),
        aforo: d.id,
        minimoTributable:
          isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && d.id === 527
            ? 6.6
            : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
            ? 11.3
            : d.minimoTributable,
      }));
      const liquidacion = {
        id: selectedLiq.id,
        desglose,
        monto: +(parseFloat(getAESubtotal() || '0') / petro).toFixed(8),
        contribuyente,
      };
      const liq = await declareSettlements('sustitutiva', [liquidacion], auth.token);
      message.success('Declaración sustitutiva creada exitosamente');
      const lIndex = liquidaciones.findIndex((l) => l.id === liq.id);
      const _liqs = cloneDeep(liquidaciones);
      _liqs[lIndex] = {
        ..._liqs[lIndex],
        datos: {
          ..._liqs[lIndex].datos,
          desglose: desgloseFull,
        },
        monto: Math.round(liq.monto * petro),
        montoPetro: liq.monto,
      };
      setLiquidaciones(_liqs);
      setSelectedLiq(null);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al crear declaración sustitutiva');
    } finally {
      setDeclaring(false);
    }
  };

  // const isMinTributable = (record) => {
  //   if (record.exonerado) return false;
  //   if (typeof data[record.id] !== 'undefined' && typeof data[record.id] === 'number') {
  //     const lowerFee = selectedLiq.datos.desglose
  //       .sort((a, b) => {
  //         if (a.alicuota > b.alicuota) return 1;
  //         if (b.alicuota > a.alicuota) return -1;
  //         return 0;
  //       })
  //       .find((ae) => data[record.id] * ae.alicuota <= ae.minimoTributable);
  //     return lowerFee?.id === record.id;
  //   }
  //   return false;
  // };

  const isMinTributable = (record) => {
    if (record.exonerado) return false;
    if (typeof data[record.id] !== 'undefined' && typeof data[record.id] === 'number') {
      const currentAE = selectedLiq.datos.desglose.map((ae) => ({ ...ae, montoDeclarado: data[ae.id] }));
      // let arrayData: any = [];
      // for (const key in data) {
      //   if (
      //     Object.prototype.hasOwnProperty.call(data, key) &&
      //     typeof data[key] === 'number' &&
      //     typeof data[key] !== 'undefined'
      //   ) {
      //     arrayData.push(data[key] * selectedLiq.datos.desglose.find((ae) => ae.id === key).alicuota);
      //   }
      // }

      const correctFee = currentAE.every(
        (ae) =>
          ae.montoDeclarado * ae.alicuota <=
          (isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && record.id === 527
            ? 6.6 * petro
            : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
            ? 11.3 * petro
            : ae.minimoTributable * petro)
      )
        ? currentAE
            .sort((a, b) => {
              if (
                isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && record.id === 527
                  ? 6.6 * petro
                  : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
                  ? 11.3 * petro
                  : a.minimoTributable > isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && record.id === 527
                  ? 6.6 * petro
                  : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
                  ? 11.3 * petro
                  : b.minimoTributable * petro
              )
                return 1;
              if (
                isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && record.id === 527
                  ? 6.6 * petro
                  : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
                  ? 11.3 * petro
                  : a.minimoTributable < isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && record.id === 527
                  ? 6.6 * petro
                  : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
                  ? 11.3 * petro
                  : b.minimoTributable * petro
              )
                return -1;
              return 0;
            })
            .find(
              (ae) => Math.max(...currentAE.map((cae) => cae.montoDeclarado * cae.alicuota)) === ae.montoDeclarado * ae.alicuota
            )
        : false;

      return correctFee?.id === record.id;
    }
    return false;
  };

  const getAESubtotal = () => {
    const ids: number[] = selectedLiq.datos.desglose.map((d) => d.id);
    if (ids.every((id) => typeof data[id] !== 'undefined' && typeof data[id] === 'number')) {
      return selectedLiq.datos.desglose
        .map((l) =>
          isMinTributable(l)
            ? isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year) && l.id === 527
              ? +(6.6 * petro).toFixed(2)
              : isBeforeOctober2023(selectedLiq.fecha.month, selectedLiq.fecha.year)
              ? +(11.3 * petro).toFixed(2)
              : +(l.minimoTributable * petro).toFixed(2)
            : +(data[l.id] * l.alicuota).toFixed(2)
        )
        .reduce((p, c) => p + c, 0);
    }
    return 'N/A';
  };

  const getPreviousDeclareSubTotal = () => {
    return selectedLiq?.datos?.desglose.map((l) => l.montoCobrado).reduce((p, c) => p + c, 0) || 'N/A';
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Declaración Sustitutiva'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form
        layout='vertical'
        form={form}
        onValuesChange={(c, v) => {
          form.setFieldsValue(v);
          setData(v);
        }}
      >
        {!selectedLiq ? (
          <>
            <Row gutter={24}>
              {auth.user?.tipoUsuario !== 4 && (
                <Col xs={24} xl={7}>
                  <Form.Item
                    label='Documento de Identidad'
                    name='documento'
                    rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
                  >
                    <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} xl={7}>
                <Form.Item
                  label='R.I.M.'
                  name='referencia'
                  rules={[{ required: true, message: 'Debe ingresar la referencia municipal' }]}
                >
                  <Input placeholder='R.I.M.' />
                </Form.Item>
              </Col>
              <Col xl={3} xs={12}>
                <Button
                  onClick={() => search()}
                  style={{ marginTop: width < 1200 ? 0 : 38, width: '100%' }}
                  loading={loading}
                  icon={<SearchOutlined />}
                  type='primary'
                >
                  Buscar
                </Button>
              </Col>
              <Col span={24}>
                <Table
                  style={{ marginTop: width < 1200 ? 38 : 0 }}
                  columns={columns}
                  dataSource={liquidaciones}
                  rowKey='id'
                  bordered
                  loading={loading}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row style={{ marginBottom: 5 }}>
              <Col span={24}>
                <Alert
                  style={{ fontWeight: 'bold' }}
                  type='info'
                  message='Debe tener en cuenta que la declaración sustitutiva reemplazará la declaración previa y por consiguiente el monto a pagar se verá afectado'
                />
              </Col>
            </Row>
            <Divider orientation='left' style={{ marginLeft: -20 }}>
              <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                Declaración Previa
              </Typography.Title>
            </Divider>
            <Row>
              <Col span={24}>
                <Table
                  columns={readOnlyAE}
                  dataSource={selectedLiq.datos.desglose}
                  bordered
                  pagination={false}
                  rowKey='id'
                  style={{ marginTop: -5 }}
                />
                <Descriptions layout={width > 778 ? 'horizontal' : 'vertical'} bordered style={{ border: '1px solid #f0f0f0' }}>
                  <Descriptions.Item label='Sub-Total'>
                    {isNaN(getPreviousDeclareSubTotal() as any)
                      ? 'N/A'
                      : `Bs. ${formatCurrency(parseFloat(getPreviousDeclareSubTotal() || '0'))}`}
                  </Descriptions.Item>
                  <Descriptions.Item label='Total'>
                    {isNaN(getPreviousDeclareSubTotal() as any)
                      ? 'N/A'
                      : `Bs. ${formatCurrency(parseFloat(getPreviousDeclareSubTotal() || '0') || 0)}`}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>

            <Divider orientation='left' style={{ marginLeft: -20 }}>
              <Typography.Title ellipsis level={4} style={{ marginLeft: 5 }}>
                Declaración Sustitutiva
              </Typography.Title>
            </Divider>
            <Row>
              <Col span={24}>
                <Table
                  columns={AEcolumns}
                  dataSource={selectedLiq.datos.desglose.map((d) => {
                    return { ...d, key: d.id };
                  })}
                  bordered
                  pagination={false}
                  rowKey='id'
                />
                <Descriptions layout={width > 778 ? 'horizontal' : 'vertical'} bordered style={{ border: '1px solid #f0f0f0' }}>
                  <Descriptions.Item label='Sub-Total'>
                    {isNaN(getAESubtotal() as any) ? 'N/A' : `Bs. ${formatCurrency(parseFloat(getAESubtotal() || '0'))}`}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label='Costo por Solvencia'>{selectedLiq?.datos?.desglose?.[0] ? `Bs. ${formatCurrency(selectedLiq?.datos?.desglose[0].costoSolvencia || 0)}` : 'N/A'}</Descriptions.Item> */}
                  <Descriptions.Item label='Total'>
                    {isNaN(getAESubtotal() as any) ? 'N/A' : `Bs. ${formatCurrency(parseFloat(getAESubtotal() || '0') || 0)}`}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            {selectedLiq.estado === 'finalizado' && (
              <Row gutter={12} style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
                <Col span={24}>
                  <Descriptions layout={width > 778 ? 'horizontal' : 'vertical'} bordered style={{ border: '1px solid #f0f0f0' }}>
                    <Descriptions.Item label='Crédito fiscal a favor generado por la declaración sustitutiva'>
                      {isNaN((getPreviousDeclareSubTotal() - getAESubtotal()) as any)
                        ? 'N/A'
                        : `Bs. ${formatCurrency(parseFloat((getPreviousDeclareSubTotal() - getAESubtotal()) as any) || 0)}`}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            )}
            <Row gutter={12} style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <Button onClick={() => setSelectedLiq(null)}>Atrás</Button>
              </Col>
              <Row gutter={12}>
                <Col>
                  <Button type='primary' loading={declaring} onClick={() => declare()}>
                    Declarar
                  </Button>
                </Col>
              </Row>
            </Row>
          </>
        )}
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(SubstituteStatement);

interface SubstituteStatementProps {
  thm: States.ThemeColors;
  auth: States.Auth;
}
