import React, { useState } from 'react';
import { States, State } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { Card, Typography, Form, Divider, Select, Table, Row, Col, DatePicker, Button, Input, message } from 'antd';
import { useWindowDimensions } from '../utils/hooks';
import moment from 'moment';
import { useParams, useHistory } from 'react-router';
import { CreditCardOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { changeFineCertificate, changeFineStatus } from '../redux/actions/procedures';
import Axios from 'axios';
import VoucherUpload from './Forms/components/VoucherUpload';
const server = process.env.REACT_APP_SERVER_URL;

const PayFine: React.FC<PayFineProps> = ({ banks, thm, fines, inst, auth, changeFineStatus, changeFineCertificate }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const { idMulta } = useParams<any>();
  const [form] = Form.useForm();
  const history = useHistory();
  const [comprobantes, setComprobantes] = useState<any>([]);

  const PETRO = useSelector((state: State) => state.coin.petro);

  const selectedFine = fines.find((f) => f.id === parseInt(idMulta || '0'));
  const selectedInst = inst?.filter((i) => [9].includes(i.id));
  const cuentasBancarias =
    selectedInst?.reduce((prev, curr) => {
      if (curr?.cuentasBancarias) {
        return prev.concat(curr?.cuentasBancarias);
      } else {
        return prev;
      }
    }, [] as any) ?? [];

  function disabledDate(current) {
    return current > moment().endOf('day');
  }

  const handleBankChange = (banco) => {
    form.setFieldsValue({
      banco,
    });
  };

  const formatCurrency = (number: number) =>
    new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);

  const isProntoPago = () => {
    const fechaCreacion = moment(selectedFine?.fechaCreacion).locale('es');
    return moment().locale('es').diff(fechaCreacion, 'days') <= 30;
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      Object.keys(values).forEach((k) => {
        if (moment.isMoment(values[k])) {
          values[k] = values[k].toISOString();
        }
      });
      const pago = [
        {
          fecha: values.fecha,
          banco: values?.bancoOrigen || values.banco,
          destino: values?.bancoDestino || values.banco,
          referencia: values.referencia,
          costo: isProntoPago()
            ? (Number(selectedFine?.montoPetro ?? 0) * PETRO * (1 - 0.3)).toFixed(2)
            : (Number(selectedFine?.montoPetro ?? 0) * PETRO).toFixed(2),
          montoPetro: isProntoPago() ? Number(selectedFine?.montoPetro ?? 0) * (1 - 0.3) ?? 0 : selectedFine?.montoPetro ?? 0,
        },
      ];
      const tramite = {
        estado: 'ingresardatos',
        pago,
        idTramite: selectedFine?.id,
        tipoTramite: selectedFine?.tipoTramite,
        costo: isProntoPago()
          ? (Number(selectedFine?.montoPetro ?? 0) * PETRO * (1 - 0.3)).toFixed(2)
          : (Number(selectedFine?.montoPetro ?? 0) * PETRO).toFixed(2),
        montoPetro: isProntoPago() ? Number(selectedFine?.montoPetro ?? 0) * (1 - 0.3) ?? 0 : selectedFine?.montoPetro ?? 0,
      };
      const response = await Axios.put(
        `${server}/fines/update`,
        { tramite },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      if (response.status === 200) {
        const fd = new FormData();
        if (comprobantes) {
          comprobantes.forEach((e) => fd.append('comprobantesMultas', e.file));
          const responseFile = await Axios.post(`${server}/uploads/comprobantesMultas/${idMulta}`, fd, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });
          if (responseFile.status !== 200) message.error('Error al subir los comprobantes');
        }

        changeFineStatus(response.data.multa.id, response.data.multa.estado);
        if (response.data.multa.certificado) {
          changeFineCertificate(response.data.multa.id, response.data.multa.certificado);
        }
        message.success('Pago registrado de manera exitosa.');

        if (response.data.multa.certificado) {
          if (Array.isArray(response.data.multa.certificado)) {
            response.data.multa.certificado.forEach((cert) => {
              window.open(cert, '_blank');
            });
          } else {
            const win = window.open(response.data.multa.certificado, '_blank');
            win?.focus();
          }
        }

        setLoading(false);

        history.push('/dashboard/bandejas?tipo=multas');
      }
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al procesar el pago.');
      setLoading(false);
    }
  };

  const addVoucher = (e) => {
    setComprobantes([...comprobantes, e]);
  };

  const removeVoucher = (e) => {
    let newComprobantes = comprobantes.filter((f) => f.file.name !== e);
    setComprobantes(newComprobantes);
  };

  const columns: any = [
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      fixed: 'left',
    },
    {
      title: 'Numero de Cuenta',
      dataIndex: 'numeroCuenta',
      key: 'numeroCuenta',
    },
    {
      title: 'Titular',
      dataIndex: 'nombreTitular',
      key: 'nombreTitular',
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      key: 'documento',
    },
  ];

  return (
    <Card
      style={{ height: '100%' }}
      title='Pagar Multa'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text strong>Codigo de Multa: {selectedFine?.codigoMulta}</Typography.Text>
        <Typography.Text strong>Fecha: {moment(selectedFine?.fechaCreacion).format('DD-MM-YYYY')}</Typography.Text>
      </div>
      <Form form={form} layout='vertical' style={{ padding: width < 992 ? '0 10px' : '0px 20px' }} onFinish={handleSubmit}>
        <React.Fragment>
          <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
            <Typography.Title level={4}>Boleta de la Multa</Typography.Title>
          </Divider>
          <Button type='primary' icon={<CloudDownloadOutlined />} target='_blank' href={`${server}/fines/ticket/${idMulta}`}>
            Ver Boleta
          </Button>
        </React.Fragment>
        <Divider orientation='left' style={{ marginLeft: -30, marginBottom: 0 }}>
          <Typography.Title level={4}>Datos de Pago</Typography.Title>
        </Divider>
        {isProntoPago() ? (
          <Typography.Text strong type='danger'>
            Por motivo de pronto pago se aplicara un descuento del 30% a la multa. La multa tiene un costo de Bs.{' '}
            {formatCurrency(Number(selectedFine?.montoPetro ?? 0) * PETRO * (1 - 0.3))} (equivalente a{' '}
            {formatCurrency(Number(selectedFine?.montoPetro ?? 0) * (1 - 0.3))} MMV). El monto de la transferencia debe coincidir
            exactamente con el costo de la multa y debe ser realizada desde el mismo banco.
          </Typography.Text>
        ) : (
          <Typography.Text strong type='danger'>
            La multa tiene un costo de Bs. {(Number(selectedFine?.montoPetro ?? 0) * PETRO).toFixed(2)} (equivalente a{' '}
            {selectedFine?.datos?.funcionario?.utmm} MMV). El monto de la transferencia debe coincidir exactamente con el costo de
            la multa y debe ser realizada desde el mismo banco.
          </Typography.Text>
        )}
        <ul>
          {selectedFine?.datos?.funcionario?.infraccion?.map((f) => (
            <li>
              <Typography.Text strong type='danger'>
                {f?.numeral?.descripcion ?? ''}
              </Typography.Text>
            </li>
          ))}
        </ul>
        <Table
          bordered
          style={{ margin: '20px 0px' }}
          pagination={false}
          columns={columns}
          dataSource={cuentasBancarias?.map((c) => ({
            ...c,
            documento: `${c.rif ? 'RIF: ' : 'Cedula: '}${c.rif || c.cedula}`,
            banco: banks?.find((b) => b.id === c.banco)?.nombre,
            key: c.banco + c.institucion,
          }))}
        />
        <Row gutter={24}>
          <Col xs={24} xl={8}>
            <Form.Item label='Banco' name='banco' rules={[{ required: true, message: 'Debe indicar el banco' }]}>
              {banks.length > 0 ? (
                <Select placeholder='Banco' onChange={handleBankChange}>
                  {banks
                    ?.filter((b) => cuentasBancarias?.map((c) => c.banco).includes(b.id))
                    .map((b, i) => (
                      <Select.Option key={`b-${b.id}`} value={b.id}>
                        {b.nombre}
                      </Select.Option>
                    ))}
                </Select>
              ) : (
                <div></div>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item
              label='Numero de Referencia'
              name='referencia'
              rules={[{ required: true, message: 'Por favor ingrese el numero de referencia.' }]}
            >
              <Input placeholder='Referencia' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item
              label='Fecha de Pago'
              name='fecha'
              rules={[{ required: true, message: 'Por favor ingrese la fecha de pago.' }]}
            >
              <DatePicker style={{ width: '100%' }} format={'DD-MM-YYYY'} disabledDate={disabledDate} />
            </Form.Item>
          </Col>
          {auth.user?.tipoUsuario === 4 && (
            <Col xs={24} xl={24}>
              <VoucherUpload
                showVoucher={true}
                onUpload={(e) => {
                  addVoucher(e);
                }}
                onRemove={(e) => {
                  removeVoucher(e);
                }}
              />
            </Col>
          )}
        </Row>
      </Form>
      <Button loading={loading} type='primary' style={{ marginTop: 15 }} icon={<CreditCardOutlined />} onClick={form.submit}>
        Pagar
      </Button>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  banks: state.bk.banks,
  thm: state.thm,
  fines: state.prcd.fines,
  inst: state.inst.institutions,
  auth: state.auth,
});

export default connect(mapStateToProps, { changeFineStatus, changeFineCertificate })(PayFine);

interface PayFineProps {
  banks: States.Banks['banks'];
  thm: States.ThemeColors;
  fines: States.Procedures['fines'];
  inst: States.Institutions['institutions'];
  auth: States.Auth;
  changeFineStatus: (id: number, status: string) => void;
  changeFineCertificate: (id: number, certificate: string) => void;
}
