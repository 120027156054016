import React, { useEffect, useState } from 'react';
import { fetchAgreements, payAgreement, setAgreements } from '../../../redux/actions/agreements';
import {
  Card,
  Table,
  Tooltip,
  Button,
  Modal,
  Form,
  message,
  Tag,
  Typography,
  Select,
  Row,
  Col,
  Input,
  Spin,
  Divider,
  Descriptions,
} from 'antd';
import { States, State, AgreementPart, Agreement } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { useWindowDimensions } from '../../../utils/hooks';
import { CreditCardOutlined, SearchOutlined, LoadingOutlined, FileDoneOutlined } from '@ant-design/icons';
import moment from 'moment';
import MultipleWireTransfers from '../../Forms/components/MultipleWireTransfers';
import axios, { AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import MultipleTransactions from '../../Forms/components/MultipleTransactions';
import { ColumnsType } from 'antd/lib/table';
import Petro from '../../Icons/Petro';
import { roundCalc } from '../PaySpecificTax';
import TooltipCoin from '../../TooltipCoin';
import { CargoSedemat } from '../../../routes';
const server = process.env.REACT_APP_SERVER_URL;

const formatCurrency = (number: number | string) => {
  number = typeof number !== 'number' ? parseFloat(number) : number;
  return new Intl.NumberFormat('de-DE').format(Math.round((number + Number.EPSILON) * 100) / 100);
};

const AgreementTaxPayer: React.FC<AgreementTaxPayerProps> = ({
  thm,
  agrm,
  auth,
  institutions,
  petro,
  fetchAgreements,
  payAgreement,
  setAgreements,
}) => {
  const [userType, setUserType] = useState('JURIDICO');
  const [data, setData] = useState<any>({});
  const { width } = useWindowDimensions();
  const [selectedFragment, setSelectedFragment] = useState<AgreementPart>();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [payForm] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [downloading, setDownloading] = useState(false);

  const canPay = [42, 25, 22, 40, 41, CargoSedemat.CajeroIntegralEspecial];

  const selectedInstitution = institutions?.find((i) => i.id === 9);

  const pesoBS = useSelector((state: State) => state.coin.petro) || 18000000;
  const dolar = useSelector((state: State) => state.coin.dolar) || 18000000;
  const euro = useSelector((state: State) => state.coin.euro) || 18000000;

  useEffect(() => {
    searchForm.setFieldsValue({ docType: userType === 'JURIDICO' ? 'J' : 'V' });
  }, [userType, searchForm]);

  useEffect(() => {
    if (selectedFragment) {
      selectedFragment.monto = +Math.round(Number(selectedFragment?.montoPetro || 0) * petro).toFixed(2);
      console.log(selectedFragment.monto);
    }
    //eslint-disable-next-line
  }, [selectedFragment]);

  useEffect(() => {
    if (auth.user?.tipoUsuario === 4) fetchAgreements();
    return () => setAgreements([]);
    //eslint-disable-next-line
  }, [auth]);

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando pago', color: 'warning' };
    else return { name: 'Solvente', color: 'green' };
  };

  const docTypeSelect = (
    <Form.Item noStyle name='docType'>
      <Select>
        {userType !== 'JURIDICO' ? null : (
          <>
            <Select.Option value='J'>J</Select.Option>
            <Select.Option value='G'>G</Select.Option>
          </>
        )}
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );

  const onSearchTaxPayer = async () => {
    const values = await searchForm.validateFields();
    const key = 'search';
    message.loading({ content: 'Buscando contribuyente...', key, duration: 0 });
    setLoading(true);
    try {
      const response: AxiosResponse<{ convenios: Agreement[]; message: string }> = await axios.get(
        `${server}/settlements/agreements/${values.userType}?documento=${values.document}&tipoDocumento=${values.docType}&referencia=${values.rim}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setAgreements(response.data.convenios);
      searchForm.resetFields();
      message.success({ content: response.data?.message || 'Busqueda exitosa!', key, duration: 2.5 });
    } catch (e) {
      message.error({
        content: e?.response?.data?.error || e?.response?.data?.message || 'Error en el servidor',
        key,
        duration: 2.5,
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadTicket = async (idFragment: number) => {
    setDownloading(true);
    message.loading({ message: 'Generando recibo...', key: 'receipt' });
    try {
      const response = await axios.post(`${server}/settlements/receipt/${idFragment}/agreement`, null, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      message.success({ message: 'Recibo generado de manera exitosa.', key: 'receipt' });
      window.open(response.data.url, '_blank');
    } catch (e) {
      message.error({ message: 'Error al generar recibo para la solicitud.', key: 'receipt' });
    }
    setDownloading(false);
  };

  const Search = (
    <Form
      form={searchForm}
      layout='vertical'
      style={{ padding: width < 992 ? '0 10px' : '0px 20px', marginBottom: 16 }}
      onFinish={onSearchTaxPayer}
    >
      <Row gutter={24}>
        {loading && (
          <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Spin
              tip='Consultando contribuyente...'
              indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />}
            />
          </Col>
        )}
        {!loading && (
          <React.Fragment>
            <Col xl={7} xs={24}>
              <Form.Item
                label='Tipo de Contribuyente'
                name='userType'
                rules={[{ required: true, message: 'Debe ingresar el tipo de contribuyente' }]}
              >
                <Select placeholder='Tipo de Contribuyente' onChange={(value: string) => setUserType(value)}>
                  <Select.Option value='NATURAL'>Natural</Select.Option>
                  <Select.Option value='JURIDICO'>Jurídico</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={7}>
              <Form.Item
                label='Documento de Identidad'
                name='document'
                rules={[{ required: true, message: 'Debe ingresar el documento de identidad del contribuyente' }]}
              >
                <Input placeholder='Documento de Identidad' addonBefore={docTypeSelect} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={7}>
              <Form.Item
                name='rim'
                label='R.I.M'
                rules={[{ required: userType === 'JURIDICO', message: 'Debe ingresar un R.I.M' }]}
              >
                <Input placeholder='Referencia Municipal' />
              </Form.Item>
            </Col>
            <Col xl={3} xs={12}>
              <Button
                style={{ marginTop: width < 1200 ? 0 : 40, width: '100%' }}
                loading={loading}
                type='primary'
                htmlType='submit'
                icon={<SearchOutlined />}
              >
                Buscar
              </Button>
            </Col>
          </React.Fragment>
        )}
      </Row>
    </Form>
  );

  const onSubmitPayment = async () => {
    await payForm.validateFields();
    const pagos = Array.isArray(payForm.getFieldValue('pagos')) ? cloneDeep(payForm.getFieldValue('pagos')) || [] : [];
    pagos
      .filter((p) => p)
      .forEach((p) => {
        Object.keys(p).forEach((k) => {
          if (moment.isMoment(p[k])) {
            p[k] = p[k].toISOString();
          }
        });
      });
    console.log(pagos);
    pagos.forEach((pago) => (pago.costo = pago.metodoPago === 'EFECTIVO PESO' ? +(pago.costo * pesoBS).toFixed(2) : pago.costo));
    pagos.forEach((pago) => (pago.costo = pago.metodoPago === 'EFECTIVO DOLAR' ? +(pago.costo * dolar).toFixed(2) : pago.costo));
    pagos.forEach((pago) => (pago.costo = pago.metodoPago === 'EFECTIVO EURO' ? +(pago.costo * euro).toFixed(2) : pago.costo));

    pagos.forEach((pago) => {
      if (pago?.metodoPago?.includes('EFECTIVO')) {
        delete pago.banco;
        delete pago.destino;
        delete pago.referencia;
      }
    });

    const montoAPagar = Number(selectedFragment?.monto || +(selectedFragment?.montoPetro || 0) * petro);
    // +parseFloat(selectedReq?.monto || ((+(selectedReq?.montoPetro || 0) * petro) as unknown as string) || '0').toFixed(2)

    console.log(
      +pagos
        .map((p) => p.costo)
        .reduce((c, v) => c + v, 0)
        .toFixed(2),
      montoAPagar
    );
    if (
      +pagos
        .map((p) => p.costo)
        .reduce((c, v) => c + v, 0)
        .toFixed(2) < montoAPagar
    )
      return message.error('La suma de los montos de las transacciones debe ser igual o mayor al total de la fracción');
    setLoading(true);
    const complete = message.loading('Agregando pago del para la fraccion');
    try {
      const response = await axios.put(
        `${server}/settlements/${selectedFragment?.idConvenio}/payment/${selectedFragment?.id}`,
        { procedimiento: { pagos } },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      payAgreement(response.data.solicitud);
      message.success('Pago registrado de manera exitosa');
      payForm.resetFields();
      setModal(false);
    } catch (e) {
      message.error(
        e.response?.data?.error
          ? e.response?.data?.error
          : (e.response.data.message as string).includes('referencia')
          ? 'La referencia bancaria ya ha sido utilizada.'
          : e.response.data.message
          ? e.response.data.message
          : 'Error al crear el pago'
      );
    } finally {
      setLoading(false);
      complete();
    }
  };

  const expandedRowRender = ({ porciones, id }) => {
    const columns: ColumnsType<any> = [
      {
        title: 'Monto (MMV)',
        dataIndex: 'montoPetro',
        render: (monto) => (
          <span>
            {Number(monto).toFixed(8)} <Petro />
          </span>
        ),
      },
      {
        title: 'Monto (Bs.)',
        dataIndex: 'montoPetro',
        render: (monto, record) => <span>{formatCurrency(Number(record.monto) || Number(monto) * petro)}</span>,
      },
      { title: 'Fecha limite', dataIndex: 'fecha', key: 'fecha', render: (value) => moment(value).format('DD-MM-YYYY') },
      {
        title: 'Estado',
        dataIndex: 'estado',
        align: 'center',
        render: (value) => (
          <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(value).color}>
            {getState(value).name}
          </Tag>
        ),
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'actions',
        render: (id, record) =>
          record.estado === 'ingresardatos' ? (
            auth.user?.tipoUsuario === 4 || canPay.includes(auth.user?.institucion?.cargo?.id || 0) ? (
              <Tooltip title='Pagar'>
                <Button
                  size='middle'
                  type='primary'
                  icon={<CreditCardOutlined />}
                  onClick={() => {
                    setSelectedFragment(porciones.find((e) => e.id === id));
                    setModal(true);
                  }}
                >
                  {width > 440 ? 'Pagar' : null}
                </Button>
              </Tooltip>
            ) : null
          ) : (
            porciones.find((e) => e.id === id).aprobado && (
              <Tooltip title='Recibo'>
                <Button
                  size='middle'
                  type='primary'
                  icon={<FileDoneOutlined />}
                  loading={downloading}
                  onClick={() => downloadTicket(id)}
                >
                  {width > 440 ? 'Recibo' : null}
                </Button>
              </Tooltip>
            )
          ),
      },
    ];

    return (
      <Table
        bordered={false}
        size='middle'
        style={{ marginLeft: 83, width: '100%' }}
        key={id}
        rowKey={({ idConvenio }) => idConvenio}
        columns={columns}
        dataSource={porciones}
        pagination={false}
      />
    );
  };

  const columns = [
    { title: 'Razon Social', dataIndex: 'contribuyente', key: 'contribuyente', render: (value) => value.razonSocial },
    {
      title: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
      render: (value, record) => `${record.tipoDocumento}-${value}`,
    },
    { title: 'R.I.M', dataIndex: 'referenciaMunicipal', key: 'referenciaMunicipal' },
    { title: 'Ramo', dataIndex: 'ramo', key: 'ramo', width: 300 },
    { title: 'Fecha', dataIndex: 'fecha', key: 'fecha', render: (value) => moment(value).format('DD-MM-YYYY') },
    {
      title: 'Monto (MMV)',
      dataIndex: 'montoPetro',
      render: (monto) => (
        <span>
          {Number(monto).toFixed(8)} <Petro />
        </span>
      ),
    },
    {
      title: 'Monto (Bs.)',
      dataIndex: 'montoPetro',
      render: (monto, record) => <span>{formatCurrency(Number(record.monto) || Number(monto) * petro)}</span>,
    },
  ];

  const calcTotalIngresado = () =>
    +(
      data.pagos?.reduce((acc, p) => {
        if (!p) return 0;
        if (p.metodoPago === 'EFECTIVO PESO') {
          const amount = +p.costo * +pesoBS;
          return acc + amount;
        }
        if (p.metodoPago === 'EFECTIVO DOLAR') {
          const amount = +p.costo * +dolar;
          return acc + amount;
        }
        if (p.metodoPago === 'EFECTIVO EURO') {
          const amount = +p.costo * +euro;
          return acc + amount;
        }
        return acc + +p.costo;
      }, 0) || 0
    ).toFixed(2);

  const calcDiffSign = () =>
    calcTotalIngresado() >= Number(selectedFragment?.monto || +(selectedFragment?.montoPetro || 0) * petro);

  const calcDiff = () =>
    Math.abs(calcTotalIngresado() - Number(selectedFragment?.monto || +(selectedFragment?.montoPetro || 0) * petro));

  return (
    <Card
      style={{ height: '100%' }}
      title='Convenios'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {auth.user?.tipoUsuario !== 4 && Search}
      <Table
        scroll={{ x: 'auto' }}
        rowKey={({ id }) => id}
        columns={columns}
        expandable={{ expandedRowRender }}
        dataSource={agrm.agreements}
        loading={loading}
      />
      <Modal
        bodyStyle={{ padding: 50, height: '80vh', overflowY: 'scroll' }}
        centered
        confirmLoading={loading}
        width={900}
        maskClosable
        visible={modal}
        onCancel={() => {
          setModal(false);
        }}
        onOk={onSubmitPayment}
      >
        <TooltipCoin priceBs={Number(selectedFragment?.monto || 0)} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
          <Typography.Text strong>
            El monto de la fracción de pago seleccionada tiene un total en petro de{' '}
            <Typography.Text strong type='danger'>
              {Number(selectedFragment?.montoPetro).toFixed(8)}
            </Typography.Text>
            , y debe cancelar el equivalente de <br />
            <Typography.Text strong type='danger'>
              Bs. {+(selectedFragment?.monto || 0)}.{' '}
            </Typography.Text>
            El monto de la(s) transaccion(es) debe ser igual o mayor al total de pagar y deben ser realizadas desde el mismo
            banco.
          </Typography.Text>
        </TooltipCoin>
        <Form
          layout='vertical'
          style={{ marginTop: 20, marginBottom: 16 }}
          form={payForm}
          initialValues={{ metodoPago: 'TRANSFERENCIA' }}
          onValuesChange={(c, v) => {
            searchForm.setFieldsValue(v);
            setData(v);
          }}
        >
          {auth.user?.tipoUsuario !== 4 ? (
            <MultipleTransactions
              selectedInstitution={selectedInstitution}
              form={payForm}
              data={data}
              maxCred={agrm?.agreements?.find((a) => a.id === selectedFragment?.idConvenio)?.creditoFiscal}
            />
          ) : (
            <MultipleWireTransfers selectedInstitution={selectedInstitution} form={payForm} />
          )}
        </Form>
        <Row>
          <Divider orientation='left' style={{ marginLeft: 0 }}>
            <Typography.Title level={4}>Total Ingresado</Typography.Title>
          </Divider>
          <Col xl={24} xs={24}>
            <Descriptions bordered>
              <Descriptions.Item
                label='Total Ingresado'
                style={{
                  fontSize: '1.2rem',
                }}
              >
                <TooltipCoin priceBs={calcTotalIngresado()} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                  Bs. {console.log('data: ', data) + '' === 'undefined' && formatCurrency(calcTotalIngresado())}
                </TooltipCoin>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Divider orientation='left' style={{ marginLeft: 0 }}>
            <Typography.Title level={4}>Monto Restante</Typography.Title>
          </Divider>
          <Col xl={24} xs={24}>
            <Descriptions bordered>
              <Descriptions.Item
                label={calcDiffSign() ? 'Total a Favor' : 'Total Restante'}
                style={{
                  color: calcDiffSign() ? '#86E92B' : 'red',
                  fontSize: '1.2rem',
                }}
              >
                <TooltipCoin priceBs={calcDiff()} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                  Bs. {formatCurrency(calcDiff())}
                </TooltipCoin>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Modal>
    </Card>
  );
};

const mapStateToProps = ({ thm, agrm, inst, auth, coin }: State) => ({
  thm,
  agrm,
  petro: coin.petro,
  institutions: inst.institutions,
  auth,
});

export default connect(mapStateToProps, { fetchAgreements, payAgreement, setAgreements })(AgreementTaxPayer);

interface AgreementTaxPayerProps {
  auth: States.Auth;
  thm: States.ThemeColors;
  agrm: States.Agreements;
  institutions: States.Institutions['institutions'];
  petro: States.Coins['petro'];
  fetchAgreements: () => Promise<void>;
  payAgreement: (fragment: AgreementPart) => void;
  setAgreements: (agreements: Agreement[]) => void;
}
