import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const fetchTaxpayerUrbanEstates = async ({
  document,
  docType,
  rim,
  page = 1,
}: {
  document: number;
  docType: string;
  rim?: string;
  page?: number;
}) => {
  try {
    if (rim) {
      const response = await axios.get(
        `${server}/estates/sedemat/contributor/rim/?typeDoc=${docType}&rim=${rim.trim()}&rif=${document}&page=${page}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      return response.data;
    } else {
      const response = await axios.get(`${server}/estates/sedemat/natural/?typeDoc=${docType}&doc=${document}&page=${page}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const linkUrbanEstate = async ({ codCat, doc, typeDoc, rim, relacion }: linkUrbanEstateTaxProps) => {
  try {
    if (rim) {
      const response = await axios.post(
        `${server}/estates/sedemat/rim/link/`,
        { codCat, rim, relacion },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      return response.data;
    } else {
      const response = await axios.post(
        `${server}/estates/sedemat/natural/link/`,
        { codCat, doc, typeDoc, relacion },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const unlinkUrbanEstate = async ({ codCat, doc, typeDoc, rim }) => {
  try {
    if (rim) {
      const response = await axios.post(
        `${server}/estates/sedemat/rim/unlink/`,
        { codCat, rim },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      return response.data;
    } else {
      const response = await axios.post(
        `${server}/estates/sedemat/natural/unlink/`,
        { codCat, doc, typeDoc },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const setUrbanEstateInitDate = async (body: { id: number; date?: string; rim?: string; taxpayer: number | undefined }) => {
  try {
    const response = await axios.patch(`${server}/estates/sedemat/estate/date`, body, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

interface linkUrbanEstateTaxProps {
  codCat: string;
  doc?: string;
  typeDoc?: string;
  rim?: string;
  relacion: string;
}
