import React, { useState, useRef } from 'react';
import { SearchOutlined, CalendarOutlined } from '@ant-design/icons';
import { Table, Button, Card, Row, Input, Col, DatePicker, Select, Form } from 'antd';
import { connect } from 'react-redux';
import { State, States } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import Highlighter from 'react-highlight-words';
import { ColumnsType } from 'antd/lib/table';
import '../../assets/css/components/TableOfficial.css';
import moment, { Moment } from 'moment';
import { getMovements } from '../../services/movements';

const TableMovements: React.FC<PropsTable> = ({ thm, auth }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();
  const [data, setData] = useState<DataArray[]>([]);
  const [keys, setKeys] = useState<Moment[]>([]);
  // const [filterStatus, SetfilterStatus] = useState<any[]>([]);
  const [selectedTypeMovement, setSelectedTypeMovement] = useState('');

  // const [paginatedData, currentPage, setCurrentPage, pageSize] = usePagination(data);
  // const [pageTotal, setPageTotal] = useState(0);

  const getType = (text: string) => {
    if (text.includes('aprobar_rc')) return 'Registro de contribuyente aprobado.';
    else if (text.includes('procesar_rc')) return 'Registro de contribuyente iniciado.';
    else if (text.includes('rechazar_rc')) return 'Registro de contribuyente rechazado.';
    else if (text.includes('object')) return 'Tramite procesado.';
    else return text;
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    if (dataIndex === 'consumido') setSearchText(selectedKeys[0]);
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
      return (
        <Row style={{ width: 250, padding: 8 }} gutter={[8, 8]}>
          <Col span={24}>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              format='DD/MM/YYYY'
              placeholder={['Fecha inicio ', 'Fecha fin']}
              size='small'
              onChange={(values) => {
                setSelectedKeys(values);
              }}
              value={selectedKeys}
            />
          </Col>
          <Col span={12}>
            <Button
              style={{ width: '100%' }}
              type='primary'
              icon={<SearchOutlined />}
              size='small'
              onClick={() => {
                confirm();
              }}
            >
              Buscar
            </Button>
          </Col>
          <Col span={12}>
            <Button style={{ width: '100%' }} onClick={() => clearFilters()} size='small'>
              Restablecer
            </Button>
          </Col>
        </Row>
      );
    },
    filterIcon: (filtered) => <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => {
      return (
        moment(record[dataIndex]).utc().format('DD-MM-YYYY') === keys[0]?.utc().format('DD-MM-YYYY') ||
        moment(record[dataIndex]).utc().isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
      );
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: 'Id Solicitud',
      dataIndex: 'id_solicitud',
      key: 'id_solicitud',
      align: 'center',
      fixed: 'left',
      ...getColumnSearchProps('id_solicitud'),
      render: (text, record) => <>{text ?? 'NO APLICA'}</>,
    },
    {
      title: 'Funcionario',
      dataIndex: 'nombre_completo',
      key: 'nombre_completo',
      align: 'center',
      ...getColumnSearchProps('nombre_completo'),
      render: (text, record) => <>{text ?? 'NO APLICA'}</>,
    },
    {
      title: 'Contribuyente',
      dataIndex: 'razon_social',
      key: 'razon_social',
      align: 'center',
      ...getColumnSearchProps('razon_social'),
      render: (text, record) => <>{text ?? 'NO APLICA'}</>,
    },
    {
      title: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
      align: 'center',
      ...getColumnSearchProps('documento'),
      render: (text, record) => <>{text ?? 'NO APLICA'}</>,
    },
    {
      title: 'Referencia',
      dataIndex: 'referencia',
      key: 'referencia',
      align: 'center',
      ...getColumnSearchProps('referencia'),
      render: (text, record) => <>{text ?? 'NO APLICA'}</>,
    },
    {
      title: 'Banco Destino',
      dataIndex: 'banco_destino',
      key: 'banco_destino',
      align: 'center',
      ...getColumnSearchProps('banco_destino'),
      render: (text, record) => <>{text ?? 'NO APLICA'}</>,
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      key: 'monto',
      align: 'center',
      render: (text, record) => <>{text ?? 'NO APLICA'}</>,
    },
    {
      title: 'Fecha de pago',
      dataIndex: 'fecha_de_pago',
      align: 'center',
      ...getColumnFilterDate('fecha_de_pago'),
      render: (text, record) => <>{text ? moment.utc(text).format('DD/MM/YYYY') : 'NO APLICA'}</>,
    },
    {
      title: 'Fecha movimiento',
      dataIndex: 'fecha_movimiento',
      align: 'center',
      ...getColumnFilterDate('fecha_movimiento'),
      render: (text, record) => <>{text ? moment.utc(text).format('DD/MM/YYYY') : 'NO APLICA'}</>,
    },
    {
      title: 'Concepto',
      dataIndex: 'concepto_pago',
      key: 'concepto_pago',
      align: 'center',
      ...getColumnSearchProps('concepto_pago'),
      render: (text, record) => <>{text ?? 'NO APLICA'}</>,
    },
    {
      title: 'Tipo de Proceso',
      dataIndex: 'tipo',
      key: 'tipo',
      align: 'center',
      ...getColumnSearchProps('tipo'),
      render: (text, record) => <>{text ?? 'NO APLICA'}</>,
    },
  ];

  const aux = async (page = 1) => {
    if (auth.token) {
      try {
        setLoading(true);
        let dat: any[] = [];
        const response = await getMovements(selectedTypeMovement, page, auth.token);
        response.movimientos.forEach((ele) => {
          let tipo = getType(ele.tipo_movimiento);
          dat.push({ ...ele, tipo });
        });
        setData(dat);
        // setPageTotal(response.totalRows);
        // setCurrentPage(page);
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
      }
    }
  };

  const tipoMovimiento = ['Aprobar pago', 'actualizando datos de liquidacion', 'liquidacion borrada'];

  return (
    <Card
      title='Bitácora'
      headStyle={{ backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: '#fff' }}
      bodyStyle={{ overflowY: 'auto' }}
    >
      <Row>
        <Col span={10}>
          <Col span={24}>
            <label>Tipo de movimiento</label>
          </Col>
          <Col span={24}>
            <Select
              disabled={loading}
              placeholder='Selecciona un tipo de movimiento'
              style={{ width: '100%' }}
              onChange={(e: any) => setSelectedTypeMovement(e)}
            >
              {tipoMovimiento.map((t) => (
                <Select.Option key={t} value={t}>
                  {t}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Col>
        <Col span={6}>
          <Button type='primary' loading={loading} onClick={() => aux()} style={{ marginTop: 22 }}>
            Buscar
          </Button>
        </Col>

        <Col span={24} style={{ marginBlock: 20 }}>
          <Table
            className='tableScroll'
            scroll={{ x: 'max-content' }}
            style={{ height: '100%' }}
            size='large'
            loading={loading}
            columns={columns}
            dataSource={data}
            // pagination={{
            //   current: currentPage,
            //   total: pageTotal,
            //   onChange: (page, pageSize) => aux(page),
            //   pageSize: pageSize,
            //   size: 'small',
            // }}
          />
        </Col>
      </Row>
    </Card>
  );
};
const mapStateToProps = ({ auth, thm }: State) => ({ auth, thm });

export default connect(mapStateToProps, {})(TableMovements);

interface PropsTable {
  auth: States.Auth;
  thm: States.ThemeColors;
}

interface DataArray {
  nombre_completo: string;
  nombre_de_usuario: string;
  telefono: string;
  fecha_movimiento: string;
  tipo_movimiento: string;
}
