import React from 'react';
import { Card } from 'antd';

const DisplayDoc: React.FC<DisplayDocProps> = ({ url, content: Content, expand, preview }) => {
  const FORMAT_URL = preview ? `${url}#toolbar=0&navpanes=0&scrollbar=0&zoom=125` : url;
  return (
    <Card
      style={{ width: '100%' }}
      bodyStyle={{ padding: 0 }}
      cover={
        <iframe
          style={{ height: expand ? '60vh' : '80vh', border: 'none', borderBottom: '1px solid lightgrey' }}
          title='DOCS'
          src={FORMAT_URL}
        />
      }
    >
      {Content ? typeof Content === 'string' ? Content : <Content /> : null}
    </Card>
  );
};

interface DisplayDocProps {
  url: string;
  content?: React.FC | string;
  expand?: boolean;
  preview?: boolean;
}

export default DisplayDoc;
