import axios, { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { TaxesTypes, SET_TAXES, UPDATE_CERT, UPDATE_RECIBO, SET_TAX, CHANGE_TAX_DATA } from "./actionsTypes";
import { Liquidacion } from "sigt";
const server = process.env.REACT_APP_SERVER_URL;

export const setTaxes = (taxes: Liquidacion[]): TaxesTypes => ({ type: SET_TAXES, payload: taxes });
export const updateCert = (id: number, certificado: string): TaxesTypes => ({ type: UPDATE_CERT, payload: { id, certificado } });
export const updateRecib = (id: number, recibo: string): TaxesTypes => ({ type: UPDATE_RECIBO, payload: { id, recibo } });
export const setTax = (tax: Liquidacion): TaxesTypes => ({ type: SET_TAX, payload: tax });
export const changeTaxData = (id: number, data: Liquidacion): TaxesTypes => ({ type: CHANGE_TAX_DATA, payload: { id, data } });


export const createFile = (id: number, tipoCertificado: string, index: string | number, token:string): ThunkAction<Promise<void>, {}, {}, TaxesTypes> => 
  async (dispatch: ThunkDispatch<{}, {}, TaxesTypes>): Promise<void> => {
    try {
      const response: AxiosResponse<any> = await axios.post(`${server}/settlements/${id}/${tipoCertificado}`, {index: index}, { headers: { Authorization: `Bearer ${token}` } });
      if(tipoCertificado === 'solvencia')dispatch(updateCert(id, response.data.media));
      if(tipoCertificado === 'recibo')dispatch(updateRecib(id, response.data.media));
      return response.data;
    } catch (e) {
      throw e;
    }
  };