import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const validateTramite = async (id) => {
  try {
    const response = await axios.get(`${server}/validateDoc/validarTramite/${id}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const validateDoc = async (id) => {
  try {
    const response = await axios.get(`${server}/validateDoc/${id}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const validateSedemat = async (id) => {
  try {
    const response = await axios.get(`${server}/validateDoc/validarSedemat/${id}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
