import React, { useState, useEffect } from 'react';
import { Modal, InputNumber, Row, Col, Button, Divider, Spin, message } from 'antd';
import { useHistory } from 'react-router';
import handlingMessage from '../utils/handlingMessage';
import { SyncOutlined, LoadingOutlined } from '@ant-design/icons';
import { State, States } from 'sigt';
import { connect } from 'react-redux';
// import { updatePetro } from '../redux/actions/coins';
import { updatePeso, updateDolar, updateEuro, updatePetro } from '../redux/actions/coins';
import axios from 'axios';
import Petro from './Icons/Petro';
const server = process.env.REACT_APP_SERVER_URL;

const Scale: React.FC<ScaleProps> = ({
  thm,
  petro,
  peso,
  dolar,
  euro,
  updatePetro,
  updatePeso,
  updateDolar,
  updateEuro,
  auth,
}) => {
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState<{ [id: string]: boolean }>({});
  const [petroBs, setPetrobs] = useState<number | undefined>();
  const [pesoBs, setPesobs] = useState<number | undefined>();
  const [euroBs, setEurobs] = useState<number | undefined>();
  const [dolarBs, setDolarbs] = useState<number | undefined>();
  const [scales, setScales] = useState<{ id: number; descripcion: string; indicador: string | number }[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (petro) setPetrobs(petro);
  }, [petro]);

  useEffect(() => {
    if (peso) setPesobs(peso);
  }, [peso]);

  useEffect(() => {
    if (dolar) setDolarbs(dolar);
  }, [dolar]);

  useEffect(() => {
    if (euro) setEurobs(euro);
  }, [euro]);

  const getScales = async () => {
    try {
      const response = await axios.get(`${server}/scales`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setScales(response.data?.scales);
      message.success(response.data?.message || 'Baremo de tarifas obtenido!');
    } catch (e) {
      message.error(e.response?.data?.error || e.response?.data?.message || 'Error al obtener tasas y limites');
    }
  };

  const onChangeValue = (id: number, value: number) => {
    const _scales = [...scales];
    const sIndex = _scales.findIndex((e) => e.id === id);
    const _scale = _scales[sIndex];
    _scale.indicador = value;
    _scales[sIndex] = _scale;
    setScales(_scales);
  };

  useEffect(() => {
    getScales();
  }, []);

  const updateValue = async (id: number, indicador: number | string) => {
    setLoading({ ...loading, [id]: true });
    try {
      const response = await axios.put(
        `${server}/scales/${id}`,
        { indicador },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      message.success(response.data?.message || 'Baremo de tarifas obtenido!');
    } catch (e) {
      message.error(e.response?.data?.error || e.response?.data?.message || 'Error al obtener tasas y limites');
    } finally {
      setLoading({ ...loading, [id]: false });
    }
  };

  const handleCancel = () => {
    setVisible(false);
    history.goBack();
  };

  const handleSubmit = (coinName: string, coinValue: number | undefined, update: Function) => {
    setLoading({ ...loading, [coinName]: true });
    handlingMessage({
      action: () => update(coinValue),
      key: `update${coinName}`,
      loadingMessage: `Actualizando ${coinName}...`,
      cb: () => {
        setLoading({ ...loading, [coinName]: false });
      },
    });
  };

  return (
    <React.Fragment>
      <Row style={{ height: '100%', width: '100%' }} justify='center' align='middle'>
        <Petro style={{ width: 300, height: 300, textAlign: 'center' }} />
      </Row>
      <Modal
        width={600}
        bodyStyle={{ height: '80vh', overflowY: 'scroll' }}
        centered
        visible={visible}
        footer={null}
        closable
        maskClosable
        onCancel={handleCancel}
        title='Baremo de tarifas'
      >
        {scales.length > 0 ? (
          <>
            <Divider orientation='left'>Moneda de mayor valor</Divider>
            <Row justify='space-between' style={{ marginLeft: 20 }} gutter={[16, 16]}>
              <Col>
                <InputNumber
                  style={{ width: '100%' }}
                  value={petroBs}
                  formatter={(value) => `Bs ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                  onChange={(value) => {
                    if (value) setPetrobs(value);
                  }}
                />
              </Col>
              <Col>
                <Button
                  loading={loading.PETRO}
                  type='primary'
                  icon={<SyncOutlined />}
                  onClick={() => handleSubmit('MMV', petroBs, updatePetro)}
                >
                  Actualizar
                </Button>
              </Col>
            </Row>
            <Divider orientation='left'>Peso</Divider>
            <Row justify='space-between' style={{ marginLeft: 20 }} gutter={[16, 16]}>
              <Col>
                <InputNumber
                  style={{ width: '100%' }}
                  value={pesoBs}
                  formatter={(value) => `Bs ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                  onChange={(value) => {
                    if (value) setPesobs(value);
                  }}
                />
              </Col>
              <Col>
                <Button
                  loading={loading.PESO}
                  type='primary'
                  icon={<SyncOutlined />}
                  onClick={() => handleSubmit('PESO', pesoBs, updatePeso)}
                >
                  Actualizar
                </Button>
              </Col>
            </Row>
            <Divider orientation='left'>Dolar</Divider>
            <Row justify='space-between' style={{ marginLeft: 20 }} gutter={[16, 16]}>
              <Col>
                <InputNumber
                  style={{ width: '100%' }}
                  value={dolarBs}
                  formatter={(value) => `Bs ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                  onChange={(value) => {
                    if (value) setDolarbs(value);
                  }}
                />
              </Col>
              <Col>
                <Button
                  loading={loading.DOLAR}
                  type='primary'
                  icon={<SyncOutlined />}
                  onClick={() => handleSubmit('DOLAR', dolarBs, updateDolar)}
                >
                  Actualizar
                </Button>
              </Col>
            </Row>
            <Divider orientation='left'>Euro</Divider>
            <Row justify='space-between' style={{ marginLeft: 20 }} gutter={[16, 16]}>
              <Col>
                <InputNumber
                  style={{ width: '100%' }}
                  value={euroBs}
                  formatter={(value) => `Bs ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                  onChange={(value) => {
                    if (value) setEurobs(value);
                  }}
                />
              </Col>
              <Col>
                <Button
                  loading={loading.EURO}
                  type='primary'
                  icon={<SyncOutlined />}
                  onClick={() => handleSubmit('EURO', euroBs, updateEuro)}
                >
                  Actualizar
                </Button>
              </Col>
            </Row>
            {scales.map((e) => (
              <>
                <Divider orientation='left'>{e.descripcion}</Divider>
                <Row justify='space-between' style={{ marginLeft: 20 }} gutter={[16, 16]}>
                  <Col>
                    <InputNumber
                      min={0}
                      style={{ width: '100%' }}
                      value={typeof e.indicador === 'number' ? e.indicador : parseFloat(e.indicador)}
                      onChange={(value) => {
                        if (value) onChangeValue(e.id, value);
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      loading={loading[e.id]}
                      type='primary'
                      icon={<SyncOutlined />}
                      onClick={() => updateValue(e.id, e.indicador)}
                    >
                      Actualizar
                    </Button>
                  </Col>
                </Row>
              </>
            ))}
          </>
        ) : (
          <Spin
            className='custom-spinner'
            indicator={<LoadingOutlined style={{ fontSize: 40, color: thm.primaryColor }} spin />}
            size='large'
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({
  petro: state.coin.petro,
  peso: state.coin.peso,
  thm: state.thm,
  dolar: state.coin.dolar,
  euro: state.coin.euro,
});

export default connect(mapStateToProps, { updatePetro, updatePeso, updateDolar, updateEuro })(Scale);

interface ScaleProps {
  auth: States.Auth;
  petro: number;
  peso: number;
  euro: number;
  dolar: number;
  thm: States.ThemeColors;
  updatePetro: (number) => Promise<any>;
  updatePeso: (number) => Promise<any>;
  updateDolar: (number) => Promise<any>;
  updateEuro: (number) => Promise<any>;
}
