import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router';

export const useWindowDimensions = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

export const usePagination = <T>(
  data: T[],
  initialSize = 10,
  initialPage = 1
): [T[], number, Dispatch<SetStateAction<number>>, number, Dispatch<SetStateAction<number>>] => {
  const [pageSize, setPageSize] = useState(initialSize);
  const [currentPage, setCurrentPage] = useState(initialPage);

  const paginatedData = data?.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize) as T[];

  return [paginatedData, currentPage, setCurrentPage, pageSize, setPageSize];
};

export default usePagination;
