import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Divider,
  Typography,
  Col,
  Descriptions,
  Form,
  Button,
  message,
  Alert,
  InputNumber,
  Collapse,
  Modal,
  Checkbox,
} from 'antd';
import { States, State, Liquidacion, Request } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { useWindowDimensions } from '../../utils/hooks';
import { useParams, useHistory } from 'react-router';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { CloudDownloadOutlined, CreditCardFilled, MinusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { changeRequestStatus, payRequest, rebateRequest, updateRequest } from '../../redux/actions/requests';
import { cloneDeep } from 'lodash';
import { changeTaxData } from '../../redux/actions/taxes';
import MultipleTransactions from '../Forms/components/MultipleTransactions';
import MultipleWireTransfers from '../Forms/components/MultipleWireTransfers';
import Petro from '../Icons/Petro';
import TooltipCoin from '../TooltipCoin';
import { EyeOutlined } from '@ant-design/icons';
import { getAEInterestOfApplication, getAERechargeOfApplication } from '../../services/taxes';
import { CargoSedemat } from '../../routes';

const server = process.env.REACT_APP_SERVER_URL;

const PaySpecificTax: React.FC<PaySpecificTaxProps> = ({
  thm,
  requests,
  banks,
  institutions,
  auth,
  pesoBS,
  rebateRequest,
  changeRequestStatus,
  changeTaxData,
  payRequest,
  updateRequest,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [discount, setDiscount] = useState<boolean>(false);
  // const [days, setDays] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [checkboxInteresesAE, setCheckboxInteresesAE] = useState(false);
  const [checkboxRecargoAE, setCheckboxRecargoAE] = useState(false);
  const [comprobantes, setComprobantes] = useState<any>([]);
  const [modalShowIU, setModalShowIU] = useState<any>({ shoe: false, desglose: null });
  const [AEInterests, setAEInterests] = useState<ResponseLiqInteres | null>(null);
  const [AERecharges, setAERecharge] = useState<ResponseLiqRecharge | null>(null);
  const [fineSettlements, setFineSettlements] = useState<{ data: any; total: number; totalPetro: number }>({
    data: null,
    total: 0,
    totalPetro: 0,
  });

  const ae = useSelector((state: State) => state.ae);
  const petro = useSelector((state: State) => state.coin.petro) || 18000000;
  const dolar = useSelector((state: State) => state.coin.dolar) || 18000000;
  const euro = useSelector((state: State) => state.coin.euro) || 18000000;

  const { width } = useWindowDimensions();
  const { id } = useParams<{ id: any }>();
  const history = useHistory();
  const [form] = Form.useForm();

  const canPay: number[] = [22, 25, 40, 41, 42, 53, CargoSedemat.CajeroIntegralEspecial];
  const excluirDePagoInteresesRecargo = [CargoSedemat.CajeroIntegralEspecial];
  const canDiscount: number[] = [90, 35];
  // const canReduce: number[] = [22, 25, 40, 41];
  const selectedReq = requests.find((r) => r.id === parseInt(id || '0'));
  const selectedInstitution = institutions?.find((i) => i.id === 9);

  const aforos = useSelector((state: State) => state.ae.activities);

  const firstLetterMayus = (s: string) => (s ? s.charAt(0).toUpperCase() + s.slice(1) : s);

  useEffect(() => {
    if (!selectedReq) history.push('/dashboard');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedReq) {
      if (
        selectedReq.liquidaciones.some((l) => ['PATENTE DE VEHICULO'].includes(l.ramo ?? '')) &&
        selectedReq.estado === 'ingresardatos'
      ) {
        let fineLiqVH: any = [];
        selectedReq.liquidaciones
          .filter((l) => +l?.fecha?.year === 2024)
          .forEach((l) => {
            l?.desglose?.forEach((d) => {
              if (d.fechaAdquisicion && moment(d?.fechaAdquisicion).isSameOrBefore(moment('2024-04-01'))) {
                const monthDesde = moment([l.fecha.year, 0, 1]);
                const monthHasta = moment([l.fecha.year, 2, 31]);
                if (!moment().isBetween(monthDesde, monthHasta)) {
                  fineLiqVH?.push({
                    fecha: l.fecha,
                    descripcion: `MULTA POR PATENTE DE VEHICULO PAGO FUERA DE PLAZO (PLACA:${d?.placa ?? 'N/A'})`,
                    ramo: 'MULTA POR PATENTE DE VEHICULO',
                    monto: 12 * petro,
                    montoPetro: 12,
                    desglose: { ...d },
                    referenciaMunicipal: selectedReq.referenciaMunicipal,
                    contribuyente: selectedReq.contribuyente.id,
                  });
                }
              }
            });
          });
        const total = +fineLiqVH?.map((l) => l?.monto ?? 0)?.reduce((c, v) => c + v, 0) ?? 0;
        const totalPetro = +fineLiqVH?.map((l) => l?.montoPetro ?? 0)?.reduce((c, v) => c + v, 0) ?? 0;
        setFineSettlements({ data: fineLiqVH, total, totalPetro });
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: ResponseLiqInteres = await getAEInterestOfApplication(id, auth.token);
        const resRecargo: ResponseLiqRecharge = await getAERechargeOfApplication(id, auth.token);

        setAEInterests({ liquidaciones: response.liquidaciones, totalInteres: response.totalInteres });
        setAERecharge({ liquidaciones: resRecargo.liquidaciones, totalRecargo: resRecargo.totalRecargo });
      } catch (error) {
        message.error('Error al obtener los datos de intereses o recargo de actividades económicas');
        console.error('Error al obtener los datos de intereses o recargo de actividades económicas', error);
      }
    };

    if (selectedReq?.liquidaciones?.some((l) => l?.ramo === RAMO_AE) && selectedReq.estado === 'ingresardatos') {
      fetchData();
    }
  }, [selectedReq]);

  const pay = async () => {
    const values = await form.validateFields();
    console.log(values, comprobantes);
    Object.keys(values).forEach((k) => {
      if (moment.isMoment(values[k])) {
        values[k] = values[k].toISOString();
      }
    });
    const pagos = Array.isArray(form.getFieldValue('pagos')) ? cloneDeep(form.getFieldValue('pagos')) || [] : [];
    if (pagos.filter((p) => p).find((p) => p.metodoPago === 'TRANSFERENCIA' && !sedematBanks.includes(p.destino))) {
      return message.error('Los metodos de pago de transferencia y cheque deben ser realizados solo a los bancos de HACIENDA');
    }
    pagos
      .filter((p) => p)
      .forEach((p) => {
        Object.keys(p).forEach((k) => {
          if (moment.isMoment(p[k])) {
            p[k] = p[k].toISOString();
          }
        });
      });
    console.log(pagos);
    pagos.forEach((pago) => (pago.costo = pago.metodoPago === 'EFECTIVO PESO' ? pago.costo * pesoBS : pago.costo));
    pagos.forEach((pago) => (pago.costo = pago.metodoPago === 'EFECTIVO DOLAR' ? pago.costo * dolar : pago.costo));
    pagos.forEach((pago) => (pago.costo = pago.metodoPago === 'EFECTIVO EURO' ? pago.costo * euro : pago.costo));

    pagos.forEach((pago) => {
      if (auth.user?.tipoUsuario === 4) {
        pago.banco = pago.destino;
      }
      if (pago?.metodoPago?.includes('EFECTIVO')) {
        delete pago.banco;
        delete pago.destino;
        delete pago.referencia;
      }
    });

    const montoAPagar = +roundCalc(
      +parseFloat(selectedReq?.monto || ((+(selectedReq?.montoPetro || 0) * petro) as unknown as string) || '0').toFixed(2) +
        (checkboxInteresesAE ? 0 : Number(AEInterests?.totalInteres ?? 0)) +
        (checkboxRecargoAE ? 0 : Number(AERecharges?.totalRecargo ?? 0)) +
        +fineSettlements.total
    ).toFixed(2);
    const montoTotalPagos = +pagos
      .map((p) => p.costo)
      .reduce((c, v) => c + v, 0)
      .toFixed(2);

    console.log({ montoAPagar, montoTotalPagos, montoMultasVH: fineSettlements.total });
    if (auth.user?.tipoUsuario === 4) {
      if (montoTotalPagos !== +montoAPagar) {
        return message.error('La suma de los montos de las transacciones debe ser igual al total de la liquidación');
      }
    } else if (montoTotalPagos < +montoAPagar) {
      return message.error('La suma de los montos de las transacciones debe ser igual o mayor al total de la liquidación');
    }
    setLoading(true);
    const complete = message.loading('Creando pago para la declaración de impuestos');
    try {
      const procedimiento = {
        // pagos: pagos.map(p => p.metodoPago === 'EFECTIVO PESO' ? ({ ...p, metodoPago: 'EFECTIVO' }) : p),
        pagos,
        interesMoratorio: AEInterests
          ? AEInterests?.liquidaciones?.map((l) => ({
              solicitudAE: l.id_solicitud,
              montoInteres: checkboxInteresesAE ? 0 : Number((Number(l?.interes ?? 0) / petro).toFixed(8)),
              excluirDePago: checkboxInteresesAE,
              ...l.datos,
            }))
          : null,
        recargo: AERecharges?.liquidaciones
          ? AERecharges?.liquidaciones
              ?.filter((l) => l.tipoProcedmiento !== 'RECARGO DE ACTIVIDAD ECONOMICA')
              ?.map((l) => ({
                solicitudAE: l.id_solicitud,
                montoRecargo: checkboxRecargoAE ? 0 : Number((Number(l?.recargo ?? 0) / petro).toFixed(8)),
                excluirDePago: checkboxRecargoAE,
                ...l.datos,
              }))
          : null,
        multaVH: fineSettlements?.data?.filter((d) => ['MULTA POR PATENTE DE VEHICULO']?.includes(d.ramo)),
      };
      const response = await axios.put(
        `${server}/settlements/${id}/payment`,
        { procedimiento },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      if (response.status === 200) {
        const fd = new FormData();
        if (comprobantes) {
          comprobantes.forEach((e) => fd.append('comprobantes', e.file));

          const responseFile = await axios.post(`${server}/uploads/comprobantes/${id}`, fd, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });
          if (responseFile.status !== 200) message.error('Error al subir los comprobantes');
        }

        // if(responseFile.status === 200){
        changeRequestStatus(response.data.solicitud.estado, parseInt(id || '0'));
        if (AEInterests) {
          console.log('AEInterests payRequest', response.data.solicitud);
          payRequest(response.data.solicitud);
        }

        if (response?.data?.solicitud?.liquidaciones.some((d) => ['MULTA POR PATENTE DE VEHICULO']?.includes(d.ramo))) {
          await updateRequest(parseInt(id || '0'), response.data.solicitud);
        }

        message.success('Pago registrado de manera exitosa');
        auth.user?.tipoUsuario === 4
          ? history.push('/dashboard/impuestos/pagar')
          : history.push('/dashboard/impuestos/pendientes');
        response.data.solicitud.liquidaciones.forEach((item) => {
          const data: Liquidacion = {
            id: item.id,
            fecha: item.fecha,
            ramo: item.ramo,
            monto: item.monto,
            montoPetro: item.montoPetro,
            aprobado: response.data.solicitud.aprobado,
            pagado: response.data.solicitud.pagado,
            recibo: item.recibo,
            certificado: item.certificado,
            estado: response.data.solicitud.estado,
          };
          changeTaxData(data.id, data);
        });
        if (response.data.solicitud.recibo) {
          if (Array.isArray(response.data.solicitud.recibo)) {
            response.data.solicitud.recibo.forEach((cert) => {
              window.open(cert, '_blank');
              // win?.focus();
            });
          } else {
            const win = window.open(response.data.solicitud.recibo, '_blank');
            win?.focus();
          }
        }

        // if(selectedReq?.liquidaciones.some((l) => l.ramo === 'VEHICULOS')){
        //   getCertificado()
        // }
      }
    } catch (e) {
      console.log('error pagar impuesto', e);
      message.error(
        e.response?.data?.message
          ? (e.response.data.message as string).includes('referencia')
            ? 'La referencia bancaria ya ha sido utilizada.'
            : e.response.data.message
          : 'Error al crear el pago'
      );
    } finally {
      setLoading(false);
      complete();
    }
  };

  const getCertificado = async () => {
    try {
      let liquidaciones: any = selectedReq?.liquidaciones.filter((l) => l.ramo === 'VEHICULOS');
      let maxFecha = Math.max(...liquidaciones.map((l) => l.fecha.year));
      let liqFiltradas = liquidaciones.filter((l) => maxFecha === l.fecha.year);

      if (liqFiltradas || liqFiltradas.length === 0) return;

      liqFiltradas.forEach((f) => {
        axios
          .post(`${server}/settlements/${f.id}/solvencia`, {}, { headers: { Authorization: `Bearer ${auth.token}` } })
          .then((res) => {
            if (res.status === 200) {
              if (Array.isArray(res.data.media)) {
                res.data.media.forEach((cert) => {
                  window.open(cert, '_blank');
                });
              } else {
                window.open(res.data.media, '_blank');
              }
            }
          })
          .catch((error) => {
            throw error;
          });
      });
    } catch (error) {
      throw error;
    }
  };

  const getFineTotals = () => {
    return (
      parseFloat(
        selectedReq?.multas
          ?.map((r) => roundCalc(+r.monto || +r.montoPetro * petro))
          .reduce((c, v) => c + v, 0)
          .toFixed(2) || '0'
      ) + fineSettlements.total
    );
  };
  const getLiqsTotal = () =>
    parseFloat(
      selectedReq?.liquidaciones
        .map((l) => roundCalc(+l.monto || +l.montoPetro * petro))
        .reduce((c, v) => c + v, 0)
        .toFixed(2) || '0'
    );

  const getLiqsAETotal = () =>
    parseFloat(
      selectedReq?.liquidaciones
        .map((l) => (l.ramo?.includes('ACTIVIDADES ECONOMICAS') ? roundCalc(+l.montoPetro * petro) : 0))
        .reduce((c, v) => c + v, 0)
        .toFixed(2) || '0'
    );

  const calcTotalIngresado = () =>
    data.pagos?.reduce((acc, p) => {
      if (!p) return 0;
      if (p.metodoPago === 'EFECTIVO PESO') {
        const amount = +p.costo * +pesoBS;
        return acc + amount;
      }
      if (p.metodoPago === 'EFECTIVO DOLAR') {
        const amount = +p.costo * +dolar;
        return acc + amount;
      }
      if (p.metodoPago === 'EFECTIVO EURO') {
        const amount = +p.costo * +euro;
        return acc + amount;
      }
      return acc + +p.costo;
    }, 0) || 0;

  const calcDiffSign = () =>
    roundCalc(calcTotalIngresado()) >=
    roundCalc(
      +parseFloat(selectedReq?.monto || ((+(selectedReq?.montoPetro || 0) * petro) as unknown as string) || '0').toFixed(2) +
        (checkboxInteresesAE ? 0 : Number(AEInterests?.totalInteres ?? 0)) +
        (checkboxRecargoAE ? 0 : Number(AERecharges?.totalRecargo ?? 0)) +
        fineSettlements.total
    );

  const calcDiff = () =>
    Math.abs(
      calcTotalIngresado() -
        roundCalc(
          +parseFloat(selectedReq?.monto || ((+(selectedReq?.montoPetro || 0) * petro) as unknown as string) || '0').toFixed(2) +
            +parseFloat(((selectedReq?.interesMoratorio || 0) * petro) as unknown as string).toFixed(2) +
            (checkboxInteresesAE ? 0 : Number(AEInterests?.totalInteres ?? 0)) +
            (checkboxRecargoAE ? 0 : Number(AERecharges?.totalRecargo ?? 0)) +
            fineSettlements?.total
        )
    );

  const showTaxPayerIU = (record) => {
    const { ramo, desglose } = record;
    return (
      <div style={{ display: 'flex', gap: '0.6rem', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        <span>{ramo ? ramo : 'LIQUIDACIÓN SIN RAMO'}</span>
        <Button
          onClick={() => {
            setModalShowIU({ show: !modalShowIU.show, desglose });
          }}
          icon={<EyeOutlined />}
        >
          Ver Inmuebles
        </Button>
      </div>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Tipo de Liquidación',
      dataIndex: 'ramo',
      render: (ramo, record) =>
        ramo?.includes('INMUEBLES URBANOS') ? showTaxPayerIU(record) : <span>{ramo ? ramo : 'LIQUIDACIÓN SIN RAMO'}</span>,
    },
    {
      title: 'I.V.A. Retenido',
      dataIndex: 'ramo',
      render: (ramo, record) =>
        ramo === 'SERVICIOS MUNICIPALES' ? <b>{record.esAgenteSENIAT ? '75%' : '0%'}</b> : <span>No Aplica</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) =>
        fecha ? (
          <span>
            {firstLetterMayus(fecha.month)} {fecha.year}
          </span>
        ) : (
          <span>Sin Fecha</span>
        ),
    },
    {
      title: 'Monto (MMV)',
      dataIndex: 'montoPetro',
      align: 'center',
      render: (_petro, record) =>
        _petro ? (
          <span>
            {Number(_petro).toFixed(8)} <Petro />
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Monto en Bs.',
      dataIndex: 'monto',
      render: (monto, record) => <span>Bs. {formatCurrency(roundCalc(+(+monto || record.montoPetro * petro).toFixed(2)))}</span>,
    },
    {
      title: 'Descuento',
      dataIndex: 'descuentoAE',
      render: (descuentoAE, record) => (
        <span>
          Bs.{' '}
          {descuentoAE
            ? (
                (record.descuentoValorAE.monto || record.descuentoValorAE.montoPetro * petro) -
                (record.monto || record.montoPetro * petro)
              ).toFixed(2)
            : 0}{' '}
        </span>
      ),
    },
  ];

  const fineColumns: ColumnsType<any> = [
    {
      title: 'Motivo',
      dataIndex: 'descripcion',
      render: (desc) => <span>{desc || 'MULTA VIGENTE'}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => (
        <span>
          {firstLetterMayus(fecha.month)} {fecha.year}
        </span>
      ),
    },
    {
      title: 'Monto (MMV)',
      dataIndex: 'montoPetro',
      align: 'center',
      render: (_petro, record) =>
        _petro ? (
          <span>
            {Number(_petro).toFixed(8)} <Petro />
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Monto en Bs.',
      dataIndex: 'monto',
      render: (monto, record) => <span>Bs. {formatCurrency(roundCalc(+(+monto || record.montoPetro * petro).toFixed(2)))}</span>,
    },
  ];

  const AEcolumns: ColumnsType<any> = [
    {
      title: 'Aforo',
      dataIndex: 'aforo',
      width: '40%',
      render: (id) => <span>{aforos.find((ae) => +ae.id === +id)?.descripcion}</span>,
    },
    {
      title: 'Alicuota',
      dataIndex: 'aforo',
      key: 'alicuota',
      render: (id) => <span>{aforos.find((ae) => +ae.id === +id)?.alicuota} %</span>,
    },
    {
      title: 'Monto Declarado',
      dataIndex: 'montoDeclarado',
      render: (md) => <span>Bs. {formatCurrency(+md)}</span>,
    },
    {
      title: 'Impuestos',
      dataIndex: 'montoCobrado',
      render: (mc) => <span>Bs. {formatCurrency(+mc)}</span>,
    },
  ];

  const AEInterestsColumns: ColumnsType<any> = [
    {
      title: 'Tipo de Liquidacion',
      render: () => <span>INTERESES {RAMO_AE}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (record) => (
        <span>
          {firstLetterMayus(record.month)} {record.year}
        </span>
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'interes',
      render: (record) => <span>Bs. {formatCurrency(Number(record ?? 0))}</span>,
    },
  ];

  const AERecargoColumns: ColumnsType<any> = [
    {
      title: 'Tipo de Liquidacion',
      render: () => <span>RECARGO {RAMO_AE}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (record) => (
        <span>{record?.month && record?.year ? `${firstLetterMayus(record?.month)} ${record?.year}` : ''}</span>
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      render: (record) => <span>Bs. {formatCurrency(Number(record ?? 0))}</span>,
    },
  ];

  const IUcolumns: ColumnsType<any> = [
    {
      title: 'Código Catastral',
      dataIndex: 'codCat',
      width: '40%',
      render: (codCat) => (codCat ? <span>{codCat}</span> : <span>Inmueble sin Información Catastral</span>),
    },
    {
      title: 'Monto a Pagar',
      dataIndex: 'monto',
      render: (md) => <span>Bs. {md ? formatCurrency(+md.toFixed(2)) : 0}</span>,
    },
  ];

  // const handleDiscount = (daysNumber) => {
  //   setDays(daysNumber);
  // };

  const downloadTicket = async (id: number) => {
    if (!downloading) {
      setDownloading(true);
      const complete = message.loading('Generando recibo...');
      try {
        const response = await axios.post(`${server}/settlements/receipt/${id}/application`, null, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        message.success('Recibo generado de manera exitosa.');
        window.open(response.data.url, '_blank');
      } catch (e) {
        message.error('Error al generar recibo para la solicitud.');
      } finally {
        setDownloading(false);
        complete();
      }
    }
  };

  const downloadCertificate = async () => {
    const complete = message.loading('Generando Certificado...');
    setDownloading(true);
    try {
      let data: any = {
        ...selectedReq,
        liquidaciones: selectedReq?.liquidaciones
          .filter((l) => l.ramo?.startsWith(RAMO_AE))
          .map((l) => {
            return {
              ...l,
              monto: formatCurrency(+l.montoPetro * petro),
              desglose: l.desglose?.map((desglose) => {
                return { ...desglose, ...ae.activities.find((a) => a.id === +desglose.aforo) };
              }),
            };
          }),
      };

      let montoTotal = data?.liquidaciones?.map((l) => +l.montoPetro * petro).reduce((p, c) => p + +c.toFixed(2), 0);
      let montoPetroTotal = data?.liquidaciones?.map((l) => +l.montoPetro).reduce((p, c) => p + c, 0);

      data.monto = formatCurrency(montoTotal || 0);
      data.montoPetro = montoPetroTotal || 0;

      const response = await axios.post(`${server}/activities/statementReconciliationAE`, data, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });

      setDownloading(false);
      complete();

      let pdfURL = `data:application/pdf;base64,${encodeURI(response.data)}`;
      var fileLink = document.createElement('a');

      fileLink.href = pdfURL;
      fileLink.setAttribute('download', 'constanciaDeclaracionAE.pdf');
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    } catch (e) {
      message.error('Error al generar el certificado');
      setDownloading(false);
      complete();
    }
  };

  const downloadPlanilla = () => {
    setDownloading(true);
    try {
      const planillasDeclaracion = selectedReq?.planillasDeclaracion;

      if ((planillasDeclaracion?.length ?? 0) === 0) return;

      if (Array.isArray(planillasDeclaracion)) {
        planillasDeclaracion.forEach((cert) => {
          window.open(cert, '_blank');
        });
      } else {
        const win = window.open(planillasDeclaracion, '_blank');
        win?.focus();
      }
    } catch (error) {
      message.error('Error al cargar la planilla');
    } finally {
      setDownloading(false);
    }
  };

  const applyDiscount = async () => {
    const rebaja = await form.getFieldValue('rebaja');
    setLoading(true);
    const complete = message.loading('Creando pago para la declaración de impuestos');
    console.log('rebaja', rebaja);
    try {
      if (rebaja) {
        const solicitud = { montoRebajado: +Number(rebaja / petro).toFixed(8) };
        const response = await axios.patch(
          `${server}/settlements/rebate/${selectedReq?.id}`,
          { solicitud },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        message.success(response?.data?.message || 'Rebaja aplicada de manera exitosa!');
        rebateRequest(response.data.solicitud);
        history.push('/dashboard');
      } else {
        return message.error('Verifique los datos de la rebaja');
      }
    } catch (e) {
      console.log(e);
      message.error(e.response?.data?.message ? e.response.data.message : 'Error al aplicar rebaja');
    } finally {
      setLoading(false);
      complete();
    }
  };

  // const reduceAmount = (record) => record ? (record.interesMoratorio - (days)) : 0;
  const RAMO_AE = 'PATENTE DE INDUSTRIA Y COMERCIO';
  const AEs = selectedReq?.liquidaciones.filter((e) => e.ramo?.includes(RAMO_AE) && e.desglose);
  const IUs = selectedReq?.liquidaciones.filter((e) => e.ramo?.includes('PROPIEDAD INMOBILIARIA') && e.desglose);

  const getPanelSubtitle = (month, year) => (
    <span>
      {firstLetterMayus(month)} {year}
    </span>
  );

  const addVoucher = (e) => {
    setComprobantes([...comprobantes, e]);
  };

  const removeVoucher = (e) => {
    let newComprobantes = comprobantes.filter((f) => f.file.name !== e);
    setComprobantes(newComprobantes);
  };

  const movimientosDeclaracion = (
    <Col span={24}>
      <Col>
        <Typography.Text strong>Declarado por: {selectedReq?.responsable || 'N/A'}</Typography.Text>
      </Col>

      {selectedReq?.responsableModificacion && (
        <Col>
          <Typography.Text strong>
            Actualización de datos de liquidación por: {selectedReq?.responsableModificacion || 'N/A'}
          </Typography.Text>
        </Col>
      )}

      {selectedReq?.responsableSeparacion && (
        <Col>
          <Typography.Text strong>Liquidación separada por: {selectedReq?.responsableSeparacion || 'N/A'}</Typography.Text>
        </Col>
      )}
    </Col>
  );

  return (
    <Card
      style={{ height: '100%' }}
      title='Pagar Impuestos'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', padding: '0px 40px' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Row style={{ margin: '15px 0px 0 4px' }}>
        <Col span={12}>
          <Typography.Text strong>Razón Social: {selectedReq?.contribuyente.razonSocial}</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text strong style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            Documento: {selectedReq?.tipoDocumento}-{selectedReq?.documento}
            {selectedReq?.referenciaMunicipal && (
              <React.Fragment>
                <br />
                R.I.M: {selectedReq?.referenciaMunicipal}
              </React.Fragment>
            )}
          </Typography.Text>
        </Col>
        {auth.user?.tipoUsuario !== 4 && movimientosDeclaracion}
      </Row>
      {selectedReq?.tipo === 'RETENCION' && (
        <Alert
          banner
          type='warning'
          message='Una vez declarada la retención debe cancelarla de inmediato, de no hacerlo puede estar expuesto a las multas correspondientes.'
        />
      )}
      {selectedReq?.estado === 'finalizado' && (
        <>
          <Row gutter={24}>
            <Divider orientation='left'>
              <Typography.Title level={4} style={{ marginLeft: 0 }}>
                Recibo
              </Typography.Title>
            </Divider>
            <Col>
              <Button
                type='primary'
                style={{ marginBottom: 10 }}
                loading={downloading}
                icon={<CloudDownloadOutlined />}
                onClick={() => downloadTicket(selectedReq?.id || 0)}
              >
                Descargar Recibo
              </Button>
            </Col>
          </Row>
        </>
      )}
      {(AEs?.length ?? 0) > 0 && (
        <>
          <Row gutter={24}>
            <Divider orientation='left'>
              <Typography.Title level={4} style={{ marginLeft: 0 }}>
                Constancia de declaración de Actividad Económica
              </Typography.Title>
            </Divider>
            <Col>
              <Button
                type='primary'
                style={{ marginBottom: 10 }}
                loading={downloading}
                icon={<CloudDownloadOutlined />}
                onClick={() => downloadCertificate()}
              >
                Descargar Certificado
              </Button>
            </Col>
          </Row>
        </>
      )}
      {(selectedReq?.planillasDeclaracion?.length ?? 0) > 0 && (
        <>
          <Row gutter={24}>
            <Divider orientation='left'>
              <Typography.Title level={4} style={{ marginLeft: 0 }}>
                Soportes De Ingresos Brutos
              </Typography.Title>
            </Divider>
            <Col>
              <Button
                type='primary'
                style={{ marginBottom: 10 }}
                loading={downloading}
                icon={<CloudDownloadOutlined />}
                onClick={() => downloadPlanilla()}
              >
                Descargar Planilla
              </Button>
            </Col>
          </Row>
        </>
      )}
      <Row gutter={24}>
        <Divider orientation='left'>
          <Typography.Title level={4} style={{ marginLeft: 0 }}>
            Liquidaciones de la Declaración
          </Typography.Title>
        </Divider>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={selectedReq?.liquidaciones.map((l, key) => {
              l.key = key;
              return l;
            })}
            bordered
            pagination={false}
          />
        </Col>
        <Col xl={12} xs={0} />
        <Col xl={12} xs={24}>
          <Descriptions bordered>
            <Descriptions.Item label='Total'>Bs. {formatCurrency(getLiqsTotal())}</Descriptions.Item>
          </Descriptions>
        </Col>
        {((selectedReq?.multas?.length || -1) > 0 || fineSettlements?.data?.length > 0) && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Multas Pendientes</Typography.Title>
            </Divider>
            <Col span={24}>
              <Table
                columns={fineColumns}
                dataSource={
                  [...(selectedReq?.multas ?? []), ...(fineSettlements?.data ?? [])]?.map((r, key) => {
                    r.key = key;
                    return r;
                  }) || []
                }
                bordered
                pagination={false}
              />
            </Col>
            <Col xl={12} xs={0} />
            <Col xl={12} xs={24}>
              <Descriptions bordered>
                <Descriptions.Item label='Total'>Bs. {formatCurrency(getFineTotals())}</Descriptions.Item>
              </Descriptions>
            </Col>
          </>
        )}
        {AEs && AEs.length > 0 && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Declaración de Actividades Económicas</Typography.Title>
            </Divider>
            <Collapse style={{ width: '100%' }} accordion>
              {AEs.map((ae, key) => (
                <Collapse.Panel key={key} header={getPanelSubtitle(ae.fecha.month, ae.fecha.year)}>
                  <Row>
                    <Col span={24}>
                      <Table columns={AEcolumns} pagination={false} rowKey='aforo' bordered dataSource={ae.desglose} />
                    </Col>
                  </Row>
                </Collapse.Panel>
              ))}
            </Collapse>
          </>
        )}
        {IUs && IUs.length > 0 && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Inmuebles Urbanos</Typography.Title>
            </Divider>
            <Collapse style={{ width: '100%' }} accordion>
              {IUs.map((iu, key) => (
                <Collapse.Panel
                  key={key}
                  header={getPanelSubtitle(iu?.fecha?.month ?? 'Sin Fecha', iu?.fecha?.year ?? 'Sin Fecha')}
                >
                  <Table columns={IUcolumns} pagination={false} rowKey='codCat' bordered dataSource={iu.desglose} />
                </Collapse.Panel>
              ))}
            </Collapse>
          </>
        )}
        {selectedReq?.interesMoratorio && selectedReq.interesMoratorio > 0 && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Intereses Moratorios</Typography.Title>
            </Divider>
            <Col span={24}>
              <Descriptions bordered>
                <Descriptions.Item label='Total de Intereses'>
                  Bs. {formatCurrency(roundCalc(+(selectedReq.interesMoratorio * petro).toFixed(2)))}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </>
        )}
        {/* {canReduce.includes(auth.user?.institucion?.cargo?.id || 0) && 
        <>
          <Divider orientation='left' style={{ marginLeft: 0 }}>
            <Typography.Title level={4}>Rebaja de Intereses Moratorios</Typography.Title>
          </Divider>
          <Col span={24} style={{marginTop:'20px'}}>
            <Slider onChange={(e)=> handleDiscount(e)} marks={{0:'0 días', 5:'5 días'}} style={{width:'50%'}} max={5} min={0} />
          </Col>
        </>} */}
        {AEInterests && (
          <Row gutter={24} style={{ marginTop: 20 }}>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Intereses de actividades económicas</Typography.Title>
            </Divider>

            {excluirDePagoInteresesRecargo.includes(auth.user?.institucion?.cargo?.id ?? 0) && (
              <Col span={24} style={{ marginBlock: 20, marginLeft: 32 }}>
                <Checkbox checked={checkboxInteresesAE} onChange={() => setCheckboxInteresesAE(!checkboxInteresesAE)}>
                  Excluir del pago los intereses de actividades económicas
                </Checkbox>
              </Col>
            )}

            {!checkboxInteresesAE && (
              <>
                <Col span={24} style={{ marginBlock: 20 }}>
                  <Alert
                    description=''
                    message='En la siguiente tabla se muestra intereses sobre el ramo de actividades económicas.'
                    type='warning'
                  />
                </Col>
                <Col span={24}>
                  <Table
                    columns={AEInterestsColumns}
                    dataSource={
                      AEInterests?.liquidaciones?.map((l) => ({
                        interes: l.interes,
                        fecha: l.datos.fecha,
                        key: l.id_liquidacion,
                      })) ?? []
                    }
                    bordered
                    pagination={false}
                  />
                </Col>
                <Col span={24}>
                  <Descriptions bordered>
                    <Descriptions.Item label='Total de Intereses'>
                      Bs. {formatCurrency(Number(AEInterests?.totalInteres ?? 0))}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </>
            )}
          </Row>
        )}

        {AERecharges && AERecharges?.liquidaciones?.length > 0 && (
          <Row gutter={24} style={{ marginTop: 20 }}>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Recargo de actividades económicas</Typography.Title>
            </Divider>

            {excluirDePagoInteresesRecargo.includes(auth.user?.institucion?.cargo?.id ?? 0) && (
              <Col span={24} style={{ marginBlock: 20, marginLeft: 32 }}>
                <Checkbox checked={checkboxRecargoAE} onChange={() => setCheckboxRecargoAE(!checkboxRecargoAE)}>
                  Excluir del pago los recargos de actividades económicas
                </Checkbox>
              </Col>
            )}
            {!checkboxRecargoAE && (
              <>
                <Col span={24} style={{ marginBlock: 20 }}>
                  <Alert
                    description=''
                    message='En la siguiente tabla se muestra el recargo del 30% sobre el ramo de actividades económicas.'
                    type='warning'
                  />
                </Col>
                <Col span={24}>
                  <Table
                    columns={AERecargoColumns}
                    dataSource={
                      AERecharges?.liquidaciones?.map((l) => ({
                        fecha: l?.datos?.fecha,
                        key: l?.id_liquidacion,
                        monto: l?.recargo ?? 0,
                      })) ?? []
                    }
                    bordered
                    pagination={false}
                  />
                </Col>
                <Col span={24}>
                  <Descriptions bordered>
                    <Descriptions.Item label='Total de Recargo'>
                      Bs. {formatCurrency(Number(AERecharges?.totalRecargo ?? 0))}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </>
            )}
          </Row>
        )}
        <Divider orientation='left' style={{ marginLeft: 0 }}>
          <Typography.Title level={4}>Total a Pagar</Typography.Title>
        </Divider>
        <Col span={24}>
          <Descriptions bordered>
            <Descriptions.Item label='Total'>
              Bs.{' '}
              {formatCurrency(
                roundCalc(
                  +parseFloat(
                    selectedReq?.monto || ((+(selectedReq?.montoPetro || 0) * petro) as unknown as string) || '0'
                  ).toFixed(2)
                ) +
                  (checkboxInteresesAE ? 0 : Number(AEInterests?.totalInteres ?? 0)) +
                  (checkboxRecargoAE ? 0 : Number(AERecharges?.totalRecargo ?? 0)) +
                  fineSettlements.total
              )}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        {/* <Col style={{ marginTop: '20px' }}>
          <Button onClick={() => history.goBack()}>Atrás</Button>
        </Col> */}
        {(parseFloat(selectedReq?.creditoFiscal || '0') > 0 || parseFloat(selectedReq?.creditoFiscalRetencion || '0') > 0) && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Credito Fiscal</Typography.Title>
            </Divider>
            <Col span={24}>
              <Descriptions bordered column={1}>
                {parseFloat(selectedReq?.creditoFiscal || '0') > 0 && (
                  <Descriptions.Item label='Disponible por Compensación'>
                    Bs. {formatCurrency(parseFloat(selectedReq?.creditoFiscal || '0'))}
                  </Descriptions.Item>
                )}
                {parseFloat(selectedReq?.creditoFiscalRetencion || '0') > 0 && (
                  <Descriptions.Item label='Disponible por Retención'>
                    Bs. {formatCurrency(parseFloat(selectedReq?.creditoFiscalRetencion || '0'))}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Col>
          </>
        )}
      </Row>
      {(auth.user?.tipoUsuario !== 4 || (auth.user?.tipoUsuario === 4 && selectedReq?.isRebaja)) &&
      selectedReq?.liquidaciones.some(
        (l) => l.ramo === 'ACTIVIDADES ECONOMICAS COMERCIALES, INDUSTRIALES, DE SERVICIO Y SIMILARES'
      ) &&
      !selectedReq?.rebajado &&
      !selectedReq?.aprobado ? (
        <>
          {discount ? (
            <>
              <Divider orientation='left' style={{ marginLeft: -10 }}>
                <Typography.Title level={4}>Rebaja para Liquidación de Actividades Económicas</Typography.Title>
              </Divider>
              <Form
                layout='vertical'
                style={{ marginTop: 20 }}
                form={form}
                initialValues={{ metodoPago: 'TRANSFERENCIA' }}
                onValuesChange={(c, v) => {
                  form.setFieldsValue(v);
                  setData(v);
                }}
              >
                <Row>
                  <Col>
                    <Form.Item
                      label='Monto de Rebaja'
                      name='rebaja'
                      rules={[
                        { required: true, message: 'Por favor ingrese el monto de la rebaja.' },
                        {
                          validator: (rule, value) =>
                            value && getLiqsAETotal() < value
                              ? Promise.reject(
                                  'El monto de la rebaja no puede ser mayor al monto total de las Actividades Economicas.'
                                )
                              : Promise.resolve(),
                        },
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                        min={0}
                        step={1}
                        style={{ width: '100%' }}
                        placeholder='Monto'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Col span={24}>
                <Descriptions column={1} bordered>
                  <Descriptions.Item span={1} label='Monto Total de Actividades Economicas'>
                    Bs. {formatCurrency(getLiqsAETotal())}
                  </Descriptions.Item>
                  <Descriptions.Item span={1} label='Monto Despues de Rebaja'>
                    Bs. {formatCurrency(getLiqsAETotal() - (form.getFieldValue('rebaja') || 0))}
                  </Descriptions.Item>
                  <Descriptions.Item span={1} label='Monto Total de liquidacion despues de Rebaja'>
                    Bs.{' '}
                    {formatCurrency(
                      getLiqsTotal() +
                        getFineTotals() +
                        +(selectedReq?.interesMoratorio || 0) -
                        (form.getFieldValue('rebaja') || 0)
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                <Col>
                  <Button onClick={() => history.goBack()}>Atrás</Button>
                </Col>
                <Col>
                  <Button
                    type='primary'
                    loading={loading}
                    style={{ marginTop: 15 }}
                    onClick={() => applyDiscount()}
                    icon={<MinusCircleOutlined />}
                  >
                    Aplicar ajuste
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <Button onClick={() => history.goBack()}>Atrás</Button>
              </Col>
              {selectedReq.liquidaciones?.some((l) => l.ramo?.includes('ACTIVIDADES ECONOMICAS')) && (
                <Col>
                  <Button type='primary' onClick={() => setDiscount(true)} icon={<MinusCircleOutlined />}>
                    Ajustar monto en PETRO
                  </Button>
                </Col>
              )}
            </Row>
          )}
        </>
      ) : (
        !(auth.user?.tipoUsuario === 4 || canPay.includes(auth.user?.institucion?.cargo?.id || 0)) && (
          <Row gutter={12} justify='start' style={{ marginTop: 10 }}>
            <Col>
              <Button onClick={() => history.goBack()}>Atrás</Button>
            </Col>
          </Row>
        )
      )}
      {(auth.user?.tipoUsuario === 4 || canPay.includes(auth.user?.institucion?.cargo?.id || 0)) &&
        selectedReq?.estado === 'ingresardatos' && (
          <>
            <Divider orientation='left' style={{ marginLeft: -10 }}>
              <Typography.Title level={4}>Datos de Pago</Typography.Title>
            </Divider>
            <TooltipCoin
              priceBs={
                roundCalc(
                  +parseFloat(selectedReq?.monto || ((+selectedReq?.montoPetro * petro) as unknown as string) || '0').toFixed(
                    2
                  ) ?? 0
                ) + fineSettlements.total
              }
              dolarBs={dolar}
              pesoBs={pesoBS}
              euroBs={euro}
            >
              <Typography.Text strong>
                La liquidación de impuestos tiene un total de de Bs.{' '}
                {formatCurrency(
                  roundCalc(
                    +parseFloat(selectedReq?.monto || ((+selectedReq?.montoPetro * petro) as unknown as string) || '0').toFixed(2)
                  ) + fineSettlements.total
                )}
                . El monto de la(s) transaccion(es) debe ser igual o mayor al total de pagar y deben ser realizadas desde el mismo
                banco.
              </Typography.Text>
            </TooltipCoin>
            <Form
              layout='vertical'
              style={{ marginTop: 20 }}
              form={form}
              initialValues={{ metodoPago: 'TRANSFERENCIA' }}
              onValuesChange={(c, v) => {
                form.setFieldsValue(v);
                setData(v);
              }}
            >
              {width >= 992 && <Col span={16} />}
              {auth.user?.tipoUsuario !== 4 ? (
                <MultipleTransactions
                  selectedReqType={selectedReq?.tipo}
                  selectedInstitution={selectedInstitution}
                  form={form}
                  data={data}
                  maxCred={parseFloat(selectedReq?.creditoFiscal || '0')}
                  maxCredR={parseFloat(selectedReq?.creditoFiscalRetencion || '0')}
                />
              ) : (
                <MultipleWireTransfers
                  showVoucher={true}
                  addVoucher={(e) => addVoucher(e)}
                  removeVoucher={(e) => removeVoucher(e)}
                  selectedInstitution={selectedInstitution}
                  form={form}
                />
              )}
            </Form>
            <Row>
              <Divider orientation='left' style={{ marginLeft: 0 }}>
                <Typography.Title level={4}>Total Ingresado</Typography.Title>
              </Divider>
              <Col xl={24} xs={24}>
                <Descriptions bordered>
                  <Descriptions.Item
                    label='Total Ingresado'
                    style={{
                      fontSize: '1.2rem',
                    }}
                  >
                    <TooltipCoin priceBs={calcTotalIngresado()} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                      Bs.{' '}
                      {console.log('data: ', data) + '' === 'undefined' &&
                        formatCurrency(
                          // data.pagos
                          //   ?.filter((p) => p)
                          //   ?.map((p) => p.costo)
                          //   ?.reduce((c, v) => c + v, 0) || 0

                          calcTotalIngresado()
                        )}
                    </TooltipCoin>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Divider orientation='left' style={{ marginLeft: 0 }}>
                <Typography.Title level={4}>Monto Restante</Typography.Title>
              </Divider>
              <Col xl={24} xs={24}>
                <Descriptions bordered>
                  <Descriptions.Item
                    label={
                      // (data.pagos
                      //   ?.filter((p) => p)
                      //   ?.map((p) => p.costo)
                      //   ?.reduce((c, v) => c + v, 0) || 0)
                      calcDiffSign() ? 'Total a Favor' : 'Total Restante'
                    }
                    style={{
                      color:
                        // (data.pagos
                        // ?.filter((p) => p)
                        // ?.map((p) => p.costo)
                        // ?.reduce((c, v) => c + v, 0) || 0)
                        calcDiffSign() ? '#86E92B' : 'red',
                      fontSize: '1.2rem',
                    }}
                  >
                    <TooltipCoin priceBs={calcDiff()} dolarBs={dolar} pesoBs={pesoBS} euroBs={euro}>
                      Bs. {formatCurrency(calcDiff())}
                    </TooltipCoin>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <Button onClick={() => history.goBack()}>Atrás</Button>
              </Col>
              <Col>
                {selectedReq?.estado === 'ingresardatos' && (
                  <Button
                    type='primary'
                    loading={loading}
                    style={{ marginTop: 15 }}
                    onClick={() => pay()}
                    icon={<CreditCardFilled />}
                  >
                    Pagar
                  </Button>
                )}
              </Col>
            </Row>
          </>
        )}
      <Modal
        title='Inmuebles'
        visible={modalShowIU.show}
        onOk={() => {
          setModalShowIU({ show: !modalShowIU.show, desglose: null });
        }}
        onCancel={() => {
          setModalShowIU({ show: !modalShowIU.show, desglose: null });
        }}
      >
        {modalShowIU.desglose && modalShowIU?.desglose?.map((item) => <p key={item.id}>{item.direccion}</p>)}
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  requests: state.req.requests,
  banks: state.bk.banks,
  institutions: state.inst.institutions,
  auth: state.auth,
  pesoBS: state.coin.peso,
});

export default connect(mapStateToProps, { changeRequestStatus, changeTaxData, rebateRequest, payRequest, updateRequest })(
  PaySpecificTax
);

interface PaySpecificTaxProps {
  thm: States.ThemeColors;
  requests: States.Requests['requests'];
  banks: States.Banks['banks'];
  institutions: States.Institutions['institutions'];
  auth: States.Auth;
  pesoBS: number;
  changeRequestStatus: (status: string, id: number) => void;
  changeTaxData: (id: number, data: Liquidacion) => void;
  rebateRequest: (req: Request) => void;
  payRequest: (req: Request) => void;
  updateRequest: (id: number, req: Request) => void;
}

export const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(+number.toFixed(2));
export const roundCalc = (number: number): number => +number.toFixed(2);
export const sedematBanks = [3, 36, 2, 20, 23, 25, 11, 12, 37, 5, 14];
