import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const getAEInterestOfApplication = async (idSolicitud, token) => {
  try {
    const response = await axios.get(`${server}/settlements/interes/${idSolicitud}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAERechargeOfApplication = async (idSolicitud, token) => {
  try {
    const response = await axios.get(`${server}/settlements/recargo/${idSolicitud}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAEInterestOfApplicationAllData = async (data, token) => {
  try {
    const response = await axios.post(`${server}/settlements/interes/allData/${data.idSolicitud}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllInterestRate = async (token) => {
  try {
    const response = await axios.get(`${server}/settlements/interes/rate/all`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteInterestAE = async (idSolicitud: number, token: string) => {
  try {
    const response = await axios.post(
      `${server}/settlements/interest/zero/${idSolicitud}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
