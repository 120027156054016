import { States } from "sigt";
import { BranchesType, SET_BRANCHES } from "../actions/actionsTypes";

const initialState: States.Branches = {
  branches: []
};

export default (state: States.Branches = initialState, action: BranchesType
  ) => {
  switch(action.type) {
    case SET_BRANCHES: 
      return {  ...state, branches: action.payload };
    default: return state;
  }
};