import moment from 'moment';

export interface AEInterest {
  fecha: { year: number; month: string };
  montoCobrado: number | string;
  interes?: number;
}

export const getAEInterest = (tasas, liquidaciones): any => {
  if (tasas && liquidaciones) {
    enum Months {
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    }
    const months = {
      january: 'enero',
      february: 'febrero',
      march: 'marzo',
      april: 'abril',
      may: 'mayo',
      june: 'junio',
      july: 'julio',
      august: 'agosto',
      september: 'septiembre',
      october: 'octubre',
      november: 'noviembre',
      december: 'diciembre',
    };

    const today = moment();

    let totalInteres = 0;

    return liquidaciones.map((l) => {
      let startDate = moment().year(l?.fecha.year).month(Months[l?.fecha.month]).startOf('M');
      let monthDiff = today.diff(startDate, 'M');
      const isOldTax = startDate.isBefore(moment('2023-11-01'));
      let interes = 0;
      if ((isOldTax && monthDiff > 1) || (monthDiff > 0)) {
        let montoLiq = Number(l?.montoCobrado || 0);
        let ajusteDifMes = isOldTax ? 2 : 1;
        for (let i = 0; i <= monthDiff - ajusteDifMes; i++) {
          const today2 = moment();
          let monthDate = today2.subtract(monthDiff - ajusteDifMes - i, 'months').endOf('M');
          let tasa =
            tasas.find((t) => t.anio === monthDate.get('year') && t.mes === monthDate.format('MMMM').toLowerCase())?.tasa || 0;
          let days = 0;
          if (i === monthDiff - ajusteDifMes) {
            days = today.get('date');
          } else {
            days = monthDate.get('date');
          }
          if(!isOldTax && i === 0){
            if(days > 15){
              days = days - 15;
            }
            else {
              days = 0;
            }
          }
          interes = interes + (montoLiq * tasa * days) / 360;
        }
      }
      l.interes = interes;
      totalInteres = totalInteres + interes;
      return l;
    });
  }
};
