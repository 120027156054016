import React, { useEffect, useState } from 'react';
import ResultError from './ResultError';
import { useParams } from 'react-router';
import { Typography, Descriptions, Collapse, Spin } from 'antd';
import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import DisplayDoc from './DisplayDoc';
import { validateDoc, validateTramite } from '../services/validateDoc';
import { useWindowDimensions } from '../utils/hooks';

const ValidateDoc: React.FC<ValidateDocProps> = (props) => {
  const params = useParams<any>();
  const [data, setData] = useState<any>(null);
  const [open, setOpen] = useState('');
  const [loading, setLoading] = useState(true);
  const { width } = useWindowDimensions();

  const getData = async () => {
    try {
      const docs = props.type === 'TRAMITE' ? await validateTramite(params.id) : await validateDoc(params.id);
      setData(docs);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      getData();
    }
    //eslint-disable-next-line
  }, []);

  const loader = <Spin indicator={<LoadingOutlined style={{ marginLeft: '50%', fontSize: '10rem', color: '#fff' }} spin />} />;
  const response = data ? (
    <DisplayDoc
      url={data.urlCertificado}
      expand={open === '1'}
      content={() => (
        <Collapse
          onChange={(key) => {
            setOpen(key[0]);
          }}
          activeKey={open}
        >
          <Collapse.Panel
            key={1}
            header={
              <Typography.Title style={{ margin: 0 }} level={4}>
                Certificado válido <CheckCircleFilled style={{ color: '#52c41a' }} />
              </Typography.Title>
            }
          >
            <Descriptions style={{ height: width < 600 ? '15vh' : 'auto', overflow: 'auto' }} size='small'>
              <Descriptions.Item key={0} span={width < 600 ? 1 : 3}>
                <Typography.Text style={{ margin: 0 }}>Solicitante</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item key={1} label='Usuario'>
                {data.datosSolicitante.nombreUsuario}
              </Descriptions.Item>
              <Descriptions.Item key={2} label='Nombre'>
                {data.datosSolicitante.nombreCompleto}
              </Descriptions.Item>
              <Descriptions.Item key={3} label='C.I'>
                {data.datosSolicitante.cedula}
              </Descriptions.Item>
              <Descriptions.Item key={4} span={width < 600 ? 1 : 3}>
                <Typography.Text style={{ margin: 0 }}>Tramite</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item key={5} label='Institucion'>
                {data.institucion.nombreCompleto}
              </Descriptions.Item>
              <Descriptions.Item key={6} label='Tramite'>
                {data.tipoTramite.nombre}
              </Descriptions.Item>
              <Descriptions.Item key={7} label='Codigo'>
                {data.tramite.codigotramite}
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel>
        </Collapse>
      )}
    />
  ) : loading ? (
    loader
  ) : (
    <ResultError />
  );
  return <div style={{ minWidth: 320, width: data ? '90%' : 'auto' }}>{response}</div>;
};

export default ValidateDoc;

interface ValidateDocProps {
  type?: string;
}
