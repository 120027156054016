import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Modal,
  Form,
  Divider,
  Typography,
  Row,
  Col,
  Input,
  Select,
  Button,
  Collapse,
  Descriptions,
  Empty,
  Spin,
  InputNumber,
  Tooltip,
  Popconfirm,
  DatePicker,
  message,
} from 'antd';
import { Categoria, State, States, Vehicle, VehicleType } from 'sigt';
import { useWindowDimensions } from '../../../utils/hooks';
import {
  CarTwoTone,
  CheckOutlined,
  CloudSyncOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  IdcardTwoTone,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Utils } from '../../../utils/validators';
import {
  getVehicleTypes,
  deleteVehicle,
  registerVehicle,
  registerVehicleInternal,
  updateVehicle,
  linkVehicle,
} from '../../../redux/actions/vehicles';
import handlingMessage from '../../../utils/handlingMessage';
import { useHistory } from 'react-router';
import moment from 'moment';
import { setInitDateVehicle } from '../../../services/vehicle';
import Text from 'antd/lib/typography/Text';
import axios from 'axios';

const RegisterVehicles: React.FC<RegisterVehiclesProps> = ({
  auth,
  thm,
  vhc,
  registerVehicle,
  deleteVehicle,
  registerVehicleInternal,
  updateVehicle,
  linkVehicle,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [modalEditVehicle, setModalEditVehicle] = useState<boolean>(false);
  const [tipo, setTipo] = useState<number>(-1);
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [subCategorias, setSubCategorias] = useState<{ id: number; descripcion: string; costo: string }[]>([]);
  const { width } = useWindowDimensions();
  const { brands, types, vehicles, id, isRim, contribuyente } = vhc;
  const [placaVehiculo, setPlacaVehiculo] = useState('');
  const [anioVehiculo, setAnioVehiculo] = useState(0);
  const history = useHistory();
  const [vehicleEdit, setVehicleEdit] = useState<number>(0);
  const [initDate, setInitDate] = useState<{ [id: number]: string | undefined }>({});
  const [updatingDate, setUpdatingDate] = useState<{ [id: number]: boolean }>({});
  const [vehicle, setVehicle] = useState<Vehicle[]>([]);
  const [modalContribuyente, setModalContribuyente] = useState<boolean>(false);
  const [documentoContribuyente, setDocumentoContribuyente] = useState<string>('');
  const [nombreContribuyente, setNombreContribuyente] = useState<string>('');

  const server = process.env.REACT_APP_SERVER_URL;
  const carBodiesTypes = [
    'AMBULANCIA',
    'ARRASTRE',
    'ARTICULADO',
    'ATV',
    'AUTOBUS',
    'AUTOBUSETE',
    'BARANDA/HIERRO',
    'BATEA',
    'BLINDADO',
    'BOMBEADOR DE CONCRETO',
    'CABINA',
    'CAMION TRACTOR',
    'CARGADOR',
    'CASA RODANTE',
    'CASILLERO',
    'CAVA',
    'CAVA/GRANEL',
    'CESTA',
    'CESTA CANERA',
    'CHASIS',
    'CHASIS AUTOBUS',
    'CHASIS CABINA',
    'CHASIS LARGO',
    'CHASIS MINIBUS',
    'CHOPPER',
    'CHUTO',
    'CISTERNA',
    'COLECTIVO',
    'COMPACTADORA',
    'COMPACTO MPV',
    'CONVERTIBLE',
    'COSECHADORA',
    'COUPE',
    'COUPE CONVERTIBLE',
    'CROSS',
    'CROSSOVER',
    'CUSTON',
    'ENDURO',
    'ESPECIAL',
    'ESTACA/BARANDA',
    'ESTACA/ESTRUC/HIERRO',
    'ESTACAS',
    'EXCAVADORA',
    'FERRETERO',
    'FUNERARIO',
    'FURGON',
    'FURGON/CACHUCHA',
    'GABINETE',
    'GATO HIDRAULICO',
    'GEOFISICO',
    'GRANEL',
    'GRUA',
    'GRUA DE PLATAFORMA',
    'HATCH BACK',
    'JAULA',
    'JAULA GANADERA',
    'JAULA POLLERA',
    'LIMOUSINE',
    'LOW-BOY',
    'MEZCLADORA',
    'MINIBUS',
    'MINIVAN',
    'MONTA CARGA',
    'MOTOCICLETA',
    'MOTONETA',
    'NODRIZA',
    'PALA MECANICA',
    'PANEL',
    'PASEO',
    'PICK-UP',
    'PICK-UP C/CABINA',
    'PICK-UP D/CABINA',
    'PICK-UP/BARANDA',
    'PICK-UP/BARANDA/HIERRO',
    'PICK-UP/C CABINA',
    'PICK-UP/C FURGON',
    'PLATABANDA',
    'PLATAFORMA',
    'PLATAFORMA DESPLAZABLE',
    'PLATAFORMA FIJA',
    'PLATF/B HIDRAULICO',
    'PLATF/BARANDA',
    'PLATF/CACHUCHA',
    'PLATF/ESTACA',
    'PLATF/ESTRUC/HIERRO',
    'PLATF/G HIDRAULICO',
    'PORTA CONTENEDORES',
    'QUADS',
    'RACING',
    'RANCHERA',
    'RANCING',
    'RETRO EXCAVADORA',
    'RUSTICO',
    'SCOOTER',
    'SEDAN',
    'SEDAN CONVERTIBLE',
    'SILO',
    'SPORT WAGON',
    'SPORT WAGON CONVERTIBLE',
    'STATION WAGON',
    'SUPERVAN',
    'TANQUE',
    'TECHO DE LONA',
    'TECHO DURO',
    'TRACCION DE SANGRE',
    'TRACTOR',
    'TRANSPORTADOR',
    'TRAYLER',
    'TRIMOVIL',
    'UTILITARIO',
    'VACUUM',
    'VAN',
    'VOLTEO',
  ];

  useEffect(() => {
    let categoria = form.getFieldValue('categoria');
    if (categoria && subCategorias.length > 0) {
      categoryFilter(categoria);
    }
  }, [anioVehiculo]);

  useEffect(() => {
    setVehicle(vehicles);
  }, [vehicles]);

  const onCarRegister = async () => {
    const values = await form.validateFields();
    setLoading(true);
    handlingMessage({
      action: () => (auth.user?.tipoUsuario === 4 ? registerVehicle(values) : registerVehicleInternal(values, id, isRim)),
      loadingMessage: 'Registrando vehículo...',
      key: 'carRegister',
      cb: (data) => {
        if (data.status === 201) {
          setModal(false);
          form.resetFields();
        }
        setLoading(false);
      },
    });
  };

  const onCarDelete = async (id: number) => {
    setDeleting(true);
    handlingMessage({
      action: () => deleteVehicle(id),
      loadingMessage: 'Desenlazando vehículo...',
      key: 'carDeletion',
      cb: () => {
        setDeleting(false);
      },
    });
  };

  const onCarUpdate = async () => {
    const values = await form.validateFields();
    setLoading(true);
    handlingMessage({
      action: () => updateVehicle(vehicleEdit, { ...values, marca: brands?.find((b) => b.nombre === values.marca)?.id ?? 1 }),
      loadingMessage: 'Actualizando vehículo...',
      key: 'carUpdate',
      cb: (data) => {
        if (data.status === 200) {
          setModalEditVehicle(false);
        }
        setLoading(false);
      },
    });
  };

  const payButton = (lastUpdateDate, id: number, subcategoria: number) => {
    const today = moment();
    const type = vhc.subCategories.find((sc) => sc.id === subcategoria)?.tipo;
    const PayButton = (
      <Button
        style={{ marginTop: 20 }}
        type='primary'
        icon={<CreditCardOutlined />}
        onClick={() => history.push(`./vehiculos/pagar/${id}`)}
      >
        Pagar Impuesto
      </Button>
    );
    if (!lastUpdateDate) return PayButton;
    else {
      if (
        (type === 1 && moment(lastUpdateDate).diff(today, 'months') < 0) ||
        (type === 2 && moment(lastUpdateDate).diff(today, 'years') < 0)
      )
        return PayButton;
      else return null;
    }
  };

  const enlazarVehiculo = async () => {
    handlingMessage({
      action: () => linkVehicle({ placa: placaVehiculo, id, isRim }),
      loadingMessage: 'Enlazando vehículo...',
      key: 'linkVehicle',
      cb: (data) => {
        if (data.enlazadoContribuyente) {
          setNombreContribuyente(data.enlazadoContribuyente.razon_social);
          setDocumentoContribuyente(`${data.enlazadoContribuyente.tipo_documento}-${data.enlazadoContribuyente.documento}`);
          setModalContribuyente(true);
        }
      },
    });
  };

  const replaceLink = async () => {
    try {
      const response = await axios.post(
        `${server}/vehicle/linkCon`,
        { placa: placaVehiculo, id, isRim },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      message.success(response.data.message || 'Enlazado correctamente');
    } catch (error) {
      message.error(error.response.data.message || 'Error al generar el enlace');
    }
  };

  const categoryFilter = (idCat) => {
    const arrCat = [1, 2, 5];
    const subCat = types.find((t) => t.id === idCat)?.subcategoria ?? [];
    if (arrCat.includes(idCat)) {
      const actualDate = new Date().getFullYear();
      const inputDate = form.getFieldValue('anio');
      const carDate = actualDate - inputDate;
      idCat === 5 && carDate > 5 ? setSubCategorias([subCat[1]]) : setSubCategorias([subCat[0]]);
      idCat !== 5 && carDate > 10
        ? setSubCategorias([subCat[2]])
        : carDate > 5
        ? setSubCategorias([subCat[1]])
        : setSubCategorias([subCat[0]]);
      return form.setFieldsValue({ subcategoria: [] });
    }

    setSubCategorias(types.find((t) => t.id === idCat)?.subcategoria ?? []);
    form.setFieldsValue({ subcategoria: [] });
  };

  const onSetDate = async (idVehicle: number, date?: string) => {
    if (typeof date === 'undefined') return message.error('No se ha definido una fecha de inicio');
    else {
      setUpdatingDate((ld) => ({ ...ld, [idVehicle]: true }));
      handlingMessage({
        action: () =>
          setInitDateVehicle({
            id: idVehicle,
            date: moment(date).format('YYYY'),
            rim: contribuyente?.referenciaMunicipal ?? null,
            taxpayer: isRim ? contribuyente.id : id,
          }),
        key: `setInitDate${id}`,
        loadingMessage: 'Estableciendo fecha de inicio...',
        cb: (data) => {
          if (data.status === 200) {
            const _vehicle = [...vehicle];
            const eIndex = vehicle.findIndex((e) => e.id === idVehicle);
            _vehicle[eIndex] = { ..._vehicle[eIndex], fechaInicio: date };
            setVehicle(_vehicle);
          }
          setUpdatingDate((ld) => ({ ...ld, [idVehicle]: false }));
        },
      });
    }
  };

  const disabledDate = (current) => current > moment().endOf('day');

  return (
    <Card
      style={{ height: '100%' }}
      title='Impuesto de Vehículos'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Modal
        visible={modalContribuyente}
        onCancel={() => setModalContribuyente(false)}
        onOk={() => replaceLink()}
        bodyStyle={{ height: '150px' }}
      >
        <h2>Confirmar cambio de contribuyente</h2>
        <Typography.Text>Datos Actuales</Typography.Text>

        <Typography.Text>{`Documento: ${documentoContribuyente}`}</Typography.Text>

        <Typography.Text>{`Nombre: ${nombreContribuyente}`}</Typography.Text>
      </Modal>
      <Row gutter={16}>
        <Col>
          <Button type='primary' icon={<PlusOutlined />} onClick={() => setModal(true)}>
            Registrar Vehículo
          </Button>
        </Col>
      </Row>
      {auth.user?.tipoUsuario !== 4 && (
        <>
          <Row gutter={16} style={{ marginTop: '20px' }}>
            <Col>
              <Input
                placeholder='Placa del vehiculo a enlazar'
                onChange={(e) => {
                  setPlacaVehiculo(e.target.value);
                }}
              ></Input>
            </Col>
            <Col>
              <Button type='primary' onClick={enlazarVehiculo}>
                Enlazar Vehículo
              </Button>
            </Col>
          </Row>
        </>
      )}
      <Divider orientation='left'>
        <Typography.Text>
          Si el o los vehículos estan adjudicados a una compañía deberan tramitarlos a las oficinas del municipio San Cristóbal.
        </Typography.Text>
      </Divider>
      {vhc.types.length <= 0 ? (
        <Spin
          tip='Cargando Vehiculos...'
          className='custom-spinner'
          style={{ height: 400 }}
          indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '12px 0px' }} spin />}
          size='large'
        />
      ) : (
        <>
          {vehicle.length <= 0 ? (
            <Empty
              style={{
                padding: '100px 0 0 0',
                margin: '20px 0 0 0',
                height: 400,
                border: '1px solid lightgrey',
                borderRadius: 5,
              }}
              description='No posee vehículos registrados'
            />
          ) : (
            <Collapse style={{ marginTop: 20 }}>
              {vehicle.map((v) => (
                <Collapse.Panel
                  header={`PLACA: ${v.placa?.toUpperCase()} - MODELO: ${v.modelo?.toUpperCase()}`}
                  key={v.id}
                  extra={
                    auth.user?.tipoUsuario !== 4 && (
                      <>
                        <Popconfirm
                          title='Esta seguro desea desenlazar este vehiculo?'
                          onCancel={(e) => e?.stopPropagation()}
                          onConfirm={(e) => {
                            onCarDelete(v.id);
                            e?.stopPropagation();
                          }}
                          okText='Si'
                          cancelText='No'
                        >
                          <Button
                            type='danger'
                            size='small'
                            loading={deleting}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            desenlazar
                          </Button>
                        </Popconfirm>
                        <Button
                          style={{ marginLeft: '10px' }}
                          type='primary'
                          size='small'
                          onClick={() => {
                            categoryFilter(v?.idcategoria ?? v?.idCategoria);
                            setVehicleEdit(v.id);
                            setModalEditVehicle(true);
                            form.setFieldsValue({
                              ...v,
                              categoria: v?.idcategoria ?? v?.idCategoria,
                              subcategoria: v?.idsubcategoria ?? v?.idSubcategoria,
                              fechaAdquisicion: v?.fechaAdquisicion ? moment(v?.fechaAdquisicion) : undefined,
                            });
                          }}
                        >
                          Editar Vehículo
                        </Button>
                      </>
                    )
                  }
                >
                  <Descriptions column={width > 992 ? 2 : 1} layout={width > 768 ? 'horizontal' : 'vertical'} bordered>
                    <Descriptions.Item label='Categoría'>{v.categoria}</Descriptions.Item>
                    <Descriptions.Item label='Subcategoría'>{v.subcategoria}</Descriptions.Item>
                    <Descriptions.Item label='Marca'>{v.marca}</Descriptions.Item>
                    <Descriptions.Item label='Modelo'>{v.modelo}</Descriptions.Item>
                    <Descriptions.Item label='Nombre Propietario'>{v?.nombrePropietario ?? 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label='Cedula Propietario'>{v?.cedulaPropietario ?? 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label='Color'>{v.color}</Descriptions.Item>
                    <Descriptions.Item label='Peso'>{v.peso || v.peso_vehiculo}</Descriptions.Item>
                    <Descriptions.Item label='Cilindraje'>{v.cilindraje || v.cilindraje_vehiculo}</Descriptions.Item>
                    <Descriptions.Item label='Año'>{v.anio}</Descriptions.Item>
                    <Descriptions.Item label='Fecha de adquisición'>
                      {v?.fechaAdquisicion ? moment(v?.fechaAdquisicion).format('YYYY-MM-DD') : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label='Fecha de Inicio' span={width > 992 ? 2 : 1}>
                      {v.fechaInicio ? (
                        moment(v.fechaInicio).utcOffset(4).format('YYYY')
                      ) : (
                        <Row align='middle'>
                          <DatePicker
                            picker='year'
                            disabledDate={(current) => current > moment().endOf('year')}
                            value={initDate[v.id] ? moment(initDate[v.id]) : undefined}
                            format='YYYY'
                            onChange={(d) => setInitDate((dates) => ({ ...dates, [v.id]: d?.startOf('day').toISOString() }))}
                          />
                          {initDate[v.id] && (
                            <Button
                              shape='round'
                              loading={updatingDate[v.id]}
                              style={{ marginLeft: 20, border: 'none' }}
                              onClick={() => onSetDate(v.id, initDate[v.id])}
                              type='primary'
                              icon={<CheckOutlined />}
                            />
                          )}
                        </Row>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label='Serial de Carroceria'>{v.serialCarroceria}</Descriptions.Item>
                    <Descriptions.Item label='Serial de motor'>{v.serialMotor || v.serial_motor_vehiculo}</Descriptions.Item>
                  </Descriptions>
                  {/* {payButton(v.fechaUltimaActualizacion ? moment(v.fechaUltimaActualizacion).startOf('month') : v.fechaUltimaActualizacion, v.id, v.subcategoria)} */}
                </Collapse.Panel>
              ))}
            </Collapse>
          )}
        </>
      )}
      <Modal
        title='Registro de Vehículo'
        bodyStyle={{ padding: '30px 50px', height: '70vh', overflowY: 'scroll' }}
        centered
        confirmLoading={loading}
        width={900}
        maskClosable
        visible={modal}
        okText='Finalizar'
        onCancel={() => {
          setModal(false);
          form.resetFields();
          setTipo(-1);
        }}
        onOk={onCarRegister}
      >
        <Form layout='vertical' form={form}>
          <Divider orientation='left' style={{ marginLeft: -30 }}>
            <Typography.Title level={4}>Datos del propietario</Typography.Title>
          </Divider>
          <Row gutter={16}>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Nombre del Propietario'
                name='nombrePropietario'
                rules={[{ required: true, message: 'Debe ingresar el Nombre del Propietario' }]}
              >
                <Input placeholder='Nombre del Propietario' />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Cedula del Propietario'
                name='cedulaPropietario'
                rules={[
                  { pattern: new RegExp(/^[0-9]+$/), message: 'La cedula del propietario debe ser solo dígitos' },
                  { required: true, message: 'Debe ingresar la Cedula del Propietario' },
                ]}
              >
                <Input placeholder='Cedula del Propietario' />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation='left' style={{ marginLeft: -30 }}>
            <Typography.Title level={4}>Datos del vehículo</Typography.Title>
          </Divider>
          <Row gutter={16}>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Fecha de adquisición'
                name='fechaAdquisicion'
                rules={[{ required: true, message: 'Debe ingresar la fecha de adquisición' }]}
              >
                <DatePicker disabledDate={disabledDate} style={{ width: '100%' }} format={'DD-MM-YYYY'} />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Placa'
                name='placa'
                normalize={Utils.normalize.toUpper}
                rules={[{ required: true, message: 'Debe ingresar el Placa' }]}
              >
                <Input placeholder='Placa' minLength={6} maxLength={7} />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item label='Marca' name='marca' rules={[{ required: true, message: 'Debe seleccionar marca del vehículo.' }]}>
                <Select
                  showSearch
                  filterOption={(value: string, option) => option?.title?.includes(value.toUpperCase())}
                  placeholder='Marca'
                >
                  {brands?.map((b) => (
                    <Select.Option value={b.id} key={b.id} title={b.nombre?.toUpperCase()}>
                      {b.nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Modelo'
                name='modelo'
                rules={[{ required: true, message: 'Debe seleccionar modelo del vehículo.' }]}
              >
                <Input placeholder='Modelo' />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item label='Año' name='anio' rules={[{ required: true, message: 'Debe ingresar año del vehículo.' }]}>
                <InputNumber
                  style={{ width: '100%' }}
                  max={9999}
                  min={1800}
                  placeholder='Año'
                  onChange={(e) => {
                    console.log(e);
                    setAnioVehiculo(e || 0);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item label='Color' name='color' rules={[{ required: true, message: 'Debe ingresar color del vehículo.' }]}>
                <Input placeholder='Color' />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item label='Peso' name='peso' rules={[{ required: true, message: 'Debe ingresar el peso del vehículo.' }]}>
                <Input placeholder='Peso' />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Cilindraje'
                name='cilindraje'
                rules={[{ required: true, message: 'Debe ingresar el cilindraje del vehículo.' }]}
              >
                <Input placeholder='Cilindraje' />
              </Form.Item>
            </Col>
            {/* <Col xl={12} xs={24}>
              <Form.Item
                label='Tipo de Vehículo'
                name='tipo'
                rules={[{ required: true, message: 'Debe seleccionar el tipo de vehículo' }]}
              >
                <Select
                  placeholder='Tipo de Vehículo'
                  onChange={(tipo: number) => {
                    setTipo(tipo);
                    setCategorias(types[tipo]?.categorias);
                    form.setFieldsValue({ categoria: [], subcategoria: [] });
                  }}
                >
                  {types.map((t: VehicleType, i: number) => (
                    <Select.Option value={i} key={t.id}>
                      {t.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col xl={12} xs={24}>
              <Form.Item
                label='Categoria'
                name='categoria'
                rules={[{ required: true, message: 'Debe seleccionar el categoria de vehículo' }]}
              >
                <Select
                  placeholder='Categoria'
                  onChange={(categoria: number) => {
                    categoryFilter(categoria);
                  }}
                >
                  {types.map((c, i) => (
                    <Select.Option value={c.id} key={c.id}>
                      {c.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} xs={24}>
              <Form.Item
                label='Sub-Categoria'
                name='subcategoria'
                rules={[{ required: true, message: 'Debe seleccionar el sub-categoria de vehículo' }]}
              >
                <Select disabled={subCategorias.length <= 0} placeholder='Sub-Categoria'>
                  {subCategorias.length > 0 &&
                    subCategorias.map((sc) => (
                      <Select.Option value={sc.id} key={sc.id}>
                        <Tooltip title={sc.descripcion}>{sc.descripcion}</Tooltip>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} xs={24}>
              <Form.Item
                label='Serial Motor'
                name='serialMotor'
                rules={[{ required: true, message: 'Debe ingresar el serial del motor del vehículo.' }]}
              >
                <Input placeholder='Serial Motor' />
              </Form.Item>
            </Col>
            <Col xl={12} xs={24}>
              <Form.Item
                label='Serial Carrocería'
                name='serialCarroceria'
                rules={[{ required: true, message: 'Debe ingresar el serial de la carroceria' }]}
              >
                <Input placeholder='Serial Carrocería' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title='Editar Vehículo'
        bodyStyle={{ padding: '30px 50px', height: '70vh', overflowY: 'scroll' }}
        centered
        confirmLoading={loading}
        width={900}
        maskClosable
        visible={modalEditVehicle}
        okText='Finalizar'
        onCancel={() => {
          setModalEditVehicle(false);
          form.resetFields();
          setTipo(-1);
        }}
        onOk={onCarUpdate}
      >
        <Form layout='vertical' form={form}>
          <Divider orientation='left' style={{ marginLeft: -30 }}>
            <Typography.Title level={4}>Datos del propietario</Typography.Title>
          </Divider>
          <Row gutter={16}>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Nombre del Propietario'
                name='nombrePropietario'
                rules={[{ required: true, message: 'Debe ingresar el Nombre del Propietario' }]}
              >
                <Input placeholder='Nombre del Propietario' />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Cedula del Propietario'
                name='cedulaPropietario'
                rules={[
                  { pattern: new RegExp(/^[0-9]+$/), message: 'La cedula del propietario debe ser solo dígitos' },
                  { required: true, message: 'Debe ingresar la Cedula del Propietario' },
                ]}
              >
                <Input placeholder='Cedula del Propietario' />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation='left' style={{ marginLeft: -30 }}>
            <Typography.Title level={4}>Datos del vehículo</Typography.Title>
          </Divider>
          <Row gutter={16}>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Fecha de adquisición'
                name='fechaAdquisicion'
                rules={[{ required: true, message: 'Debe ingresar la fecha de adquisición' }]}
              >
                <DatePicker style={{ width: '100%' }} disabledDate={(current) => current > moment()} />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Placa'
                name='placa'
                normalize={Utils.normalize.toUpper}
                rules={[{ required: true, message: 'Debe ingresar el Placa' }]}
              >
                <Input placeholder='Placa' minLength={6} maxLength={7} />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item label='Marca' name='marca' rules={[{ required: true, message: 'Debe seleccionar marca del vehículo.' }]}>
                <Select
                  showSearch
                  filterOption={(value: string, option) => option?.title?.includes(value.toUpperCase())}
                  placeholder='Marca'
                >
                  {brands?.map((b) => (
                    <Select.Option value={b.id} key={b.id} title={b.nombre?.toUpperCase()}>
                      {b.nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Modelo'
                name='modelo'
                rules={[{ required: true, message: 'Debe seleccionar modelo del vehículo.' }]}
              >
                <Input placeholder='Modelo' />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item label='Año' name='anio' rules={[{ required: true, message: 'Debe ingresar año del vehículo.' }]}>
                <InputNumber
                  style={{ width: '100%' }}
                  max={9999}
                  min={1800}
                  placeholder='Año'
                  onChange={(e) => {
                    console.log(e);
                    setAnioVehiculo(e || 0);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item label='Color' name='color' rules={[{ required: true, message: 'Debe ingresar color del vehículo.' }]}>
                <Input placeholder='Color' />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item label='Peso' name='peso' rules={[{ required: true, message: 'Debe ingresar el peso del vehículo.' }]}>
                <Input placeholder='Peso' />
              </Form.Item>
            </Col>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Cilindraje'
                name='cilindraje'
                rules={[{ required: true, message: 'Debe ingresar el cilindraje del vehículo.' }]}
              >
                <Input placeholder='Cilindraje' />
              </Form.Item>
            </Col>
            <Col xl={12} xs={24}>
              <Form.Item
                label='Categoria'
                name='categoria'
                rules={[{ required: true, message: 'Debe seleccionar el categoria de vehículo' }]}
              >
                <Select
                  placeholder='Categoria'
                  onChange={(categoria: number) => {
                    categoryFilter(categoria);
                  }}
                >
                  {types.map((c, i) => (
                    <Select.Option value={c.id} key={c.id}>
                      {c.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} xs={24}>
              <Form.Item
                label='Sub-Categoria'
                name='subcategoria'
                rules={[{ required: true, message: 'Debe seleccionar el sub-categoria de vehículo' }]}
              >
                <Select placeholder='Sub-Categoria'>
                  {subCategorias.length > 0 &&
                    subCategorias.map((sc) => (
                      <Select.Option value={sc.id} key={sc.id}>
                        <Tooltip title={sc.descripcion}>{sc.descripcion}</Tooltip>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} xs={24}>
              <Form.Item
                label='Serial Motor'
                name='serialMotor'
                rules={[{ required: true, message: 'Debe ingresar el serial del motor del vehículo.' }]}
              >
                <Input placeholder='Serial Motor' />
              </Form.Item>
            </Col>
            <Col xl={12} xs={24}>
              <Form.Item
                label='Serial Carrocería'
                name='serialCarroceria'
                rules={[{ required: true, message: 'Debe ingresar el serial de la carroceria' }]}
              >
                <Input placeholder='Serial Carrocería' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Card>
  );
};

const mapStateToProps = ({ auth, thm, vhc }: State) => ({ auth, thm, vhc });

export default connect(mapStateToProps, {
  getVehicleTypes,
  registerVehicle,
  deleteVehicle,
  updateVehicle,
  linkVehicle,
  registerVehicleInternal,
})(RegisterVehicles);

interface RegisterVehiclesProps {
  auth: States.Auth;
  thm: States.ThemeColors;
  vhc: States.Vehicles;
  registerVehicle: (vehicle: any) => Promise<any>;
  deleteVehicle: (id: number) => Promise<any>;
  updateVehicle: (id: number, vehicle: any) => Promise<any>;
  linkVehicle: (data: any) => Promise<any>;
  registerVehicleInternal: (vehicle: any, id: number | undefined, isRim: boolean | undefined) => Promise<any>;
}
