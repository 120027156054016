import axios, { AxiosResponse } from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { BranchesType, SET_BRANCHES } from './actionsTypes';
import { Branch } from 'sigt';
const server = process.env.REACT_APP_SERVER_URL;

export const setBranches = (branches: Branch[]): BranchesType => ({ type: SET_BRANCHES, payload: branches });

export const fetchBranches =
  (token: string, userId: number): ThunkAction<Promise<void>, {}, {}, BranchesType> =>
  async (dispatch: ThunkDispatch<{}, {}, BranchesType>): Promise<void> => {
    try {
      const response: AxiosResponse<{ data: Branch[] }> = await axios.get(
        `${server}/branches?all=${[39, 38, 24, 25, 90, 110].includes(userId) ? 1 : 0}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(setBranches(response.data.data.sort((a, b) => (a.descripcion > b.descripcion ? 1 : -1))));
    } catch (e) {
      throw e;
    }
  };
