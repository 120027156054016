import React, { useEffect, useState } from 'react';
import {
  Card,
  Form,
  Descriptions,
  Collapse,
  Col,
  Row,
  Typography,
  Input,
  Empty,
  Button,
  Select,
  DatePicker,
  message,
  Spin,
  Tooltip,
  Pagination,
} from 'antd';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import usePagination, { useWindowDimensions } from '../../utils/hooks';
import {
  fetchTaxpayerUrbanEstates,
  linkUrbanEstate,
  setUrbanEstateInitDate,
  unlinkUrbanEstate,
} from '../../services/urbanEstates';
import { SubnodeOutlined, CheckOutlined, CloseCircleOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import handlingMessage from '../../utils/handlingMessage';
import moment from 'moment';
import Petro from '../Icons/Petro';
import { clasificaionIU } from './ConsultProperty';

const formatCurrency = (number: number | string) => {
  number = typeof number !== 'number' ? parseFloat(number) : number;
  return new Intl.NumberFormat('de-DE').format(Math.round((number + Number.EPSILON) * 100) / 100);
};

const TaxPayerUrbanEstates: React.FC<TaxPayerUrbanEstatesProps> = ({ thm, coin, auth }) => {
  const { width } = useWindowDimensions();
  const [userType, setUserType] = useState('JURIDICO');
  const [taxpayer, setTaxpayer] = useState<TaxPayer | null>();
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [updatingDate, setUpdatingDate] = useState<{ [id: number]: boolean }>({});
  const [estates, setEstates] = useState<Estate[]>([]);
  const [initDate, setInitDate] = useState<{ [id: number]: string | undefined }>({});
  const [addForm] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [paginatedData, currentPage, setCurrentPage, pageSize] = usePagination(estates);
  const [pageTotal, setPageTotal] = useState(0);

  const chargesCannotApplyChanges = [111, 112, 23];
  const cannotApplyChanges = !chargesCannotApplyChanges.includes(auth?.user?.institucion?.cargo?.id ?? 0);

  useEffect(() => {
    searchForm.setFieldsValue({ docType: userType === 'JURIDICO' ? 'J' : 'V' });
    // eslint-disable-next-line
  }, [userType]);

  const onLinkEstate = async () => {
    const { codCat, relation } = await addForm.validateFields();
    const rim = taxpayer?.referenciaMunicipal;
    setLoading(true);
    handlingMessage({
      action: () =>
        linkUrbanEstate({
          codCat: codCat.trim(),
          rim,
          relacion: relation,
          doc: taxpayer?.documento,
          typeDoc: taxpayer?.tipoDocumento,
        }),
      key: 'linkEstate',
      loadingMessage: 'Enlazando...',
      cb: ({ inmueble }) => {
        if (inmueble) setEstates([...estates, inmueble]);
        addForm.resetFields();
        setLoading(false);
      },
    });
  };

  const onUnlinkEstate = async (codCat: string) => {
    const rim = taxpayer?.referenciaMunicipal;
    setLoading(true);
    handlingMessage({
      action: () => unlinkUrbanEstate({ codCat, rim, doc: taxpayer?.documento, typeDoc: taxpayer?.tipoDocumento }),
      key: 'linkEstate',
      loadingMessage: 'Enlazando...',
      cb: ({ status }) => {
        if (status === 200) setEstates(estates.filter((e) => e.codigoCatastral !== codCat));
        setLoading(false);
      },
    });
  };

  const onSetDate = async (id: number, date?: string) => {
    if (typeof date === 'undefined') return message.error('No se ha definido una fecha de inicio');
    else {
      setUpdatingDate((ld) => ({ ...ld, [id]: true }));
      handlingMessage({
        action: () =>
          setUrbanEstateInitDate({ id, date, rim: taxpayer?.referenciaMunicipal || undefined, taxpayer: taxpayer?.id }),
        key: `setInitDate${id}`,
        loadingMessage: 'Estableciendo fecha de inicio...',
        cb: (data) => {
          if (data.status === 200) {
            const _estates = [...estates];
            const eIndex = estates.findIndex((e) => e.id === id);
            _estates[eIndex] = { ..._estates[eIndex], fechaInicio: date, actualizable: false };
            setEstates(_estates);
          }
          setUpdatingDate((ld) => ({ ...ld, [id]: false }));
        },
      });
    }
  };

  const onSearch = async () => {
    const { docType, document, rim } = await searchForm.validateFields();
    setSearchLoading(true);
    setEstates([]);
    handlingMessage({
      action: () => fetchTaxpayerUrbanEstates({ docType, document, rim, page: 1 }),
      key: 'find',
      loadingMessage: 'Buscando...',
      cb: ({ contribuyente, inmuebles, totalEstates }) => {
        setTaxpayer(contribuyente);
        setEstates(inmuebles);
        setPageTotal(totalEstates);
        setSearchLoading(false);
        setCurrentPage(1);
      },
    });
  };

  const getEstateForPagination = async (page = 1) => {
    const { docType, document, rim } = await searchForm.validateFields();
    setLoading(true);
    handlingMessage({
      action: () => fetchTaxpayerUrbanEstates({ docType, document, rim, page }),
      key: 'find',
      loadingMessage: 'Buscando...',
      cb: ({ contribuyente, inmuebles, totalEstates }) => {
        setTaxpayer(contribuyente);
        setEstates(inmuebles);
        setPageTotal(totalEstates);
        setCurrentPage(page);
        setLoading(false);
      },
    });
  };

  const docTypeSelect = (
    <Form.Item noStyle name='docType'>
      <Select>
        {userType !== 'JURIDICO' ? null : (
          <>
            <Select.Option value='J'>J</Select.Option>
            <Select.Option value='G'>G</Select.Option>
          </>
        )}
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );

  return (
    <Card
      style={{ height: '100%' }}
      title='Inmuebles por Contribuyente'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form
        form={searchForm}
        layout='vertical'
        style={{ padding: width < 992 ? '0 10px' : '0px 20px', marginBottom: 8 }}
        onFinish={onSearch}
      >
        <Row gutter={24}>
          {searchLoading ? (
            <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spin
                tip='Consultando contribuyente...'
                indicator={<LoadingOutlined style={{ fontSize: 50, color: thm.primaryColor, margin: '15px 0px' }} />}
              />
            </Col>
          ) : (
            <React.Fragment>
              <Col xl={7} xs={24}>
                <Form.Item
                  label='Tipo de Contribuyente'
                  name='userType'
                  rules={[{ required: true, message: 'Debe ingresar el tipo de contribuyente' }]}
                >
                  <Select placeholder='Tipo de Contribuyente' onChange={(value: string) => setUserType(value)}>
                    <Select.Option value='NATURAL'>Natural</Select.Option>
                    <Select.Option value='JURIDICO'>Jurídico</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} xl={7}>
                <Form.Item
                  label='Documento de Identidad'
                  name='document'
                  rules={[{ required: true, message: 'Debe ingresar el documento de identidad del contribuyente' }]}
                >
                  <Input placeholder='Documento de Identidad' addonBefore={docTypeSelect} />
                </Form.Item>
              </Col>
              <Col xs={24} xl={7}>
                <Form.Item name='rim' label='R.I.M'>
                  <Input placeholder='Referencia Municipal' />
                </Form.Item>
              </Col>
              <Col xl={3} xs={12}>
                <Button
                  style={{ marginTop: width < 1200 ? 0 : 40, width: '100%' }}
                  loading={searchLoading || loading}
                  type='primary'
                  htmlType='submit'
                  icon={<SearchOutlined />}
                >
                  Buscar
                </Button>
              </Col>
            </React.Fragment>
          )}
        </Row>
      </Form>
      {taxpayer ? (
        <Descriptions title='Datos del Contribuyente' layout='vertical' bordered>
          <Descriptions.Item label='Documento de Identidad'>
            {taxpayer?.tipoDocumento ? `${taxpayer?.tipoDocumento}-${taxpayer?.documento}` : 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label='Razón Social'>{taxpayer?.razonSocial || 'N/A'}</Descriptions.Item>
          <Descriptions.Item label='Denominación Comercial'>{taxpayer?.denominacionComercial || 'N/A'}</Descriptions.Item>
        </Descriptions>
      ) : (
        <Empty style={{ marginTop: 100 }} />
      )}
      {taxpayer && (
        <React.Fragment>
          {cannotApplyChanges && (
            <>
              <Typography.Title level={4} style={{ fontSize: 16, marginTop: 15 }}>
                Inmuebles Asociados
              </Typography.Title>
              <Form form={addForm} layout='vertical' onFinish={onLinkEstate}>
                <Row gutter={[16, 8]} align='bottom'>
                  <Col xs={24} md={8} lg={8}>
                    <Form.Item
                      name='codCat'
                      label='Código de identificación del Inmueble'
                      rules={[{ required: true, message: 'Ingrese Código de identificación del Inmueble' }]}
                    >
                      <Input placeholder='Código de identificación del Inmueble' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} lg={4}>
                    <Form.Item name='relation' label='Condición'>
                      <Select style={{ width: '100%' }} allowClear>
                        <Select.Option value='PROPIETARIO'>PROPIETARIO</Select.Option>
                        <Select.Option value='ALQUILADO'>ALQUILADO</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} lg={4}>
                    <Form.Item>
                      <Button type='primary' loading={loading} htmlType='submit' icon={<SubnodeOutlined />}>
                        {' '}
                        Asignar nuevo inmueble
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </>
          )}
          {estates?.length > 0 && (
            <Row gutter={[16, 16]}>
              <Col span={24} style={{ marginTop: 20, marginBlock: 20 }}>
                <Collapse>
                  {estates?.map((r) => (
                    <Collapse.Panel
                      header={`Código de identificación del Inmueble: ${r.codigoCatastral || 'N/A'}`}
                      extra={
                        cannotApplyChanges && (
                          <Button
                            onClick={() => onUnlinkEstate(r.codigoCatastral)}
                            type='danger'
                            loading={loading}
                            size='small'
                            icon={<CloseCircleOutlined />}
                          >
                            Desenlazar
                          </Button>
                        )
                      }
                      key={r.id}
                      forceRender
                      disabled={loading}
                    >
                      <Descriptions column={width > 992 ? 2 : 1} layout={width > 768 ? 'horizontal' : 'vertical'} bordered>
                        <Descriptions.Item label='Tipo de Inmueble'>{r.tipoInmueble || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label='Clasificación del Inmueble'>{r.clasificacion || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label='Condición'>{r.relacion || 'NO DEFINIDO'}</Descriptions.Item>
                        <Descriptions.Item label='Metros de Construccion'>{r.metrosConstruccion || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label='Metros de Terreno'>{r.metrosTerreno || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label='Dirección' span={width > 992 ? 2 : 1}>
                          {r.direccion || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label='Fecha inicio' span={width > 992 ? 2 : 1}>
                          {r.actualizable && cannotApplyChanges ? (
                            <Row align='middle'>
                              <DatePicker
                                disabledDate={(current) => current > moment().endOf('day')}
                                value={initDate[r.id] ? moment(initDate[r.id]) : undefined}
                                format='DD/MM/YYYY'
                                onChange={(d) => setInitDate((dates) => ({ ...dates, [r.id]: d?.startOf('day').toISOString() }))}
                              />
                              {initDate[r.id] && (
                                <Button
                                  shape='round'
                                  ghost
                                  loading={updatingDate[r.id]}
                                  style={{ marginLeft: 20, border: 'none' }}
                                  onClick={() => onSetDate(r.id, initDate[r.id])}
                                  type='primary'
                                  icon={<CheckOutlined />}
                                />
                              )}
                            </Row>
                          ) : r.fechaInicio ? (
                            moment(r.fechaInicio).utcOffset(4).format('DD/MM/YYYY')
                          ) : (
                            'N/A'
                          )}
                        </Descriptions.Item>
                      </Descriptions>
                      {/* <Descriptions layout='vertical' bordered>
                      <Descriptions.Item label='Avaluos' span={3}>
                        {<Descriptions bordered layout={width > 480 ? 'horizontal' : 'vertical'} >
                          {r.avaluos?.map(e => <Descriptions.Item key={e.anio} label={e.anio} span={2}><Tooltip title={`${formatCurrency(+(Number(+e.avaluo * coin.petro).toFixed(2)))} Bs.` } ><span style={{cursor: 'pointer'}} >{e.avaluo}<Petro style={{ marginLeft: 8 }}/></span></Tooltip></Descriptions.Item>)}
                        </Descriptions>}
                      </Descriptions.Item>
                    </Descriptions> */}
                      <Descriptions
                        style={{ marginTop: 15 }}
                        title='Avalúos'
                        column={width > 992 ? 2 : 1}
                        layout={width > 768 ? 'horizontal' : 'vertical'}
                        bordered
                      >
                        {r.avaluos &&
                          r.avaluos?.map((avaluo) => {
                            return (
                              <>
                                <Descriptions.Item label='Avalúo de Terreno en Petros'>
                                  {Number(avaluo?.avaluoTerreno ?? 0).toFixed(8)} <Petro />
                                </Descriptions.Item>
                                <Descriptions.Item label='Avalúo de Construcción en Petros'>
                                  {Number(avaluo?.avaluoConstruccion ?? 0).toFixed(8)} <Petro />
                                </Descriptions.Item>
                              </>
                            );
                          })}
                      </Descriptions>
                      {clasificaionIU(r?.clasificacion, r, width)}
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </Col>
              <Col span={24}>
                <Pagination
                  disabled={loading}
                  current={currentPage}
                  total={pageTotal}
                  key={'pagination'}
                  onChange={(page) => getEstateForPagination(page)}
                  pageSize={pageSize}
                  size='small'
                />
              </Col>
            </Row>
          )}
        </React.Fragment>
      )}
    </Card>
  );
};

interface TaxPayerUrbanEstatesProps {
  thm: States.ThemeColors;
  coin: States.Coins;
  auth: States.Auth;
}

interface TaxPayer {
  id: number;
  razonSocial: string;
  denominacionComercial: string;
  referenciaMunicipal: string;
  tipoDocumento: string;
  documento: string;
}

interface Estate {
  id: number;
  codigoCatastral: string;
  direccion: string;
  metrosConstruccion: number | string;
  metrosTerreno: number | string;
  tipoInmueble: string;
  clasificacion: string | null;
  fechaInicio?: string;
  avaluos: { anio: string; avaluo: number | string; avaluoTerreno?: number | string; avaluoConstruccion?: number | string }[];
  relacion: 'PROPIETARIO' | 'ALQUILADO' | null;
  actualizable: boolean;
}

const mapStateToProps = ({ thm, coin, auth }: State) => ({ thm, coin, auth });

export default connect(mapStateToProps)(TaxPayerUrbanEstates);
