import React, { useState, useEffect, useRef } from 'react';
import { States, State } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { Card, Table, message, Divider, Typography, Tag, Badge, Button, Form, Row, Col, DatePicker, Select, Input } from 'antd';
import axios from 'axios';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, CalendarOutlined } from '@ant-design/icons';
import { useWindowDimensions } from '../../utils/hooks';
import VoucherPreview from '../Forms/components/VoucherPreview';
const server = process.env.REACT_APP_SERVER_URL;

// BOD, BANESCO Y BNC

const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

const PaymentTable = ({ dataSource, requests, setRequests }) => {
  const banks = useSelector((state: State) => state.bk.banks);

  const columns: ColumnsType<any> = [
    {
      title: 'Referencia',
      dataIndex: 'referencia',
      render: (value) => <span>{value ?? 'NO APLICA'}</span>,
    },
    {
      title: 'Banco',
      dataIndex: 'bancoDestino',
      render: (id) => <span>{banks.find((b) => b.id === id)?.nombre ?? 'NO APLICA'}</span>,
    },
    {
      title: 'Método de pago',
      dataIndex: 'metodoPago',
      render: (value) => <span>{value ?? 'NO APLICA'}</span>,
    },
    {
      title: 'Fecha de Pago',
      dataIndex: 'fechaDePago',
      render: (fecha) => <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Fecha de aprobación',
      dataIndex: 'fechaDeAprobacion',
      render: (fecha) => <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      render: (monto) => <span>Bs. {formatCurrency(monto)}</span>,
    },
    {
      title: 'Estado',
      dataIndex: 'aprobado',
      render: (aprobado) => (
        <span>
          <Badge status={aprobado ? 'success' : 'error'} />
          {aprobado ? 'Aprobado' : 'Por aprobar'}
        </span>
      ),
    },
  ];

  return <Table columns={columns} dataSource={dataSource} rowKey='id' pagination={false} />;
};

const AmendPayments: React.FC<AmendPaymentsProps> = ({ thm, auth }) => {
  const [requests, setRequests] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const [keys, setKeys] = useState<Moment[]>([]);
  const { width } = useWindowDimensions();
  const [searchForm] = Form.useForm();

  const tipoAddon = (
    <Select defaultValue='J' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando pago', color: 'orange' };
    else if (estado === 'finalizado') return { name: 'Aprobado', color: 'green' };
    else return { name: 'Error', color: 'error' };
  };

  const searchByDoc = async () => {
    const values = await searchForm.validateFields();
    if (!tipoDocumento) return message.error('Debe seleccionar un tipo de documento');
    setLoading(true);
    try {
      const response = await axios.get(`${server}/banks/payment/history?type_doc=${tipoDocumento}&doc=${values.documento}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      console.log(response.data.data);
      setRequests(response.data.data);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al conseguir las solicitudes por pagar');
    } finally {
      setLoading(false);
    }
  };

  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
      return (
        <Row style={{ width: 250, padding: 8 }} gutter={[8, 8]}>
          <Col span={24}>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              format='DD-MM-YYYY'
              placeholder={['Fecha inicio ', 'Fecha fin']}
              size='small'
              onChange={(values) => {
                setSelectedKeys(values);
              }}
              value={selectedKeys}
            />
          </Col>
          <Col span={12}>
            <Button
              style={{ width: '100%' }}
              type='primary'
              icon={<SearchOutlined />}
              size='small'
              onClick={() => {
                confirm();
              }}
            >
              Buscar
            </Button>
          </Col>
          <Col span={12}>
            <Button style={{ width: '100%' }} onClick={() => clearFilters()} size='small'>
              Restablecer
            </Button>
          </Col>
        </Row>
      );
    },
    filterIcon: (filtered) => <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => {
      return (
        moment(record[dataIndex]).utc().format('DD-MM-YYYY') === keys[0]?.utc().format('DD-MM-YYYY') ||
        moment(record[dataIndex]).utc().isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
      );
    },
  });

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => record[dataIndex]?.toString()?.toLowerCase()?.includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const filters = [
    { value: 'impuesto', text: <span style={{ paddingRight: 35 }}>Impuesto</span> },
    { value: 'convenio', text: <span style={{ paddingRight: 35 }}>Convenio</span> },
    { value: 'retencion', text: <span style={{ paddingRight: 35 }}>Retención</span> },
    { value: 'tramite', text: <span style={{ paddingRight: 35 }}>Trámite</span> },
    { value: 'multa', text: <span style={{ paddingRight: 35 }}>Multa</span> },
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Id solicitud',
      dataIndex: 'id',
      ...getColumnSearchProps('id'),
      render: (value) => <span>{value}</span>,
    },
    {
      title: 'Contribuyente',
      dataIndex: 'documento',
      ...getColumnSearchProps('documento'),
      render: (doc, record) => <span>{record.documento}</span>,
    },
    {
      title: 'Fecha de la Solicitud',
      dataIndex: 'fechaSolicitud',
      ...getColumnFilterDate('fechaSolicitud'),
      render: (fecha) => <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Tipo de Solicitud',
      dataIndex: 'tipoSolicitud',
      filters,
      onFilter: (tipo, record) => record.tipoSolicitud.toLowerCase().indexOf((tipo as string).toLowerCase()) === 0,
      render: (tipo) => <b>{tipo}</b>,
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      render: (value) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(value).color}>
          {getState(value).name}
        </Tag>
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      ...getColumnSearchProps('monto'),
      sorter: (a, b) => b.monto - a.monto,
      render: (monto) => <span>Bs. {formatCurrency(parseFloat(monto))}</span>,
    },
  ];

  // useEffect(() => {
  //   fetchRequests();
  //   //eslint-disable-next-line
  // }, []);

  // const fetchRequests = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(`${server}/banks/payment/history`, { headers: { Authorization: `Bearer ${auth.token}` } });
  //     setRequests(response.data.data);
  //   } catch (e) {
  //     message.error(e.response?.data?.message || 'Error al conseguir las solicitudes por pagar');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <Card
      style={{ height: '100%' }}
      title='Historial de pagos'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 10px', color: 'white' }}
    >
      <Divider orientation='left' style={{ marginLeft: 0, marginBottom: 10, marginTop: -5 }}>
        <Typography.Title level={4}>Solicitudes Validadas</Typography.Title>
      </Divider>
      <Form layout='vertical' style={{ marginBottom: 20 }} form={searchForm} onFinish={() => searchByDoc()}>
        <Row gutter={24}>
          <Col xs={24} xl={8}>
            <Form.Item
              name='documento'
              label='Documento de Identidad'
              rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
            >
              <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={3}>
            <Button
              disabled={loading}
              style={{ marginTop: width < 1200 ? 0 : 40, width: '100%' }}
              type='primary'
              htmlType='submit'
              icon={<SearchOutlined />}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        bordered
        loading={loading}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <PaymentTable
                dataSource={record.pagos.map((p) => {
                  p.request = record.id;
                  p.type = record.tipoSolicitud;
                  return p;
                })}
                requests={requests}
                setRequests={setRequests}
              />
              <Row style={{ marginLeft: '25px', marginTop: '25px' }}>
                <VoucherPreview comprobantes={record.comprobantes} />
              </Row>
            </>
          ),
        }}
        dataSource={requests.map((r) => {
          r.key = r.id;
          return r;
        })}
      />
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(AmendPayments);

interface AmendPaymentsProps {
  thm: States.ThemeColors;
  auth: States.Auth;
}
