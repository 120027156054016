import React, { useState, useEffect, useRef } from 'react';
import { States, State } from 'sigt';
import { Card, Form, Row, Divider, Typography, Col, Input, Button, List, message, DatePicker } from 'antd';
import { useWindowDimensions } from '../../../utils/hooks';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import GetTaxpayerInfo from '../../Taxpayer/components/GetTaxpayerInfo';
import { useForm } from 'antd/lib/form/util';
import { Utils } from '../../../utils/validators';
import TaxpayerSocietyType from '../../Forms/components/TaxpayerSocietyType';
import EconomicActivities from '../../Forms/components/EconomicActivities';
import moment from 'moment';
import axios from 'axios';
import LicenseTypeSelect from '../../Forms/components/LicenseTypeSelect';
import { setProcedure } from '../../../redux/actions/procedures';
import MultipleTransactions from '../../Forms/components/MultipleTransactions';
import { cloneDeep } from 'lodash';
import ParishSelect from '../../Forms/components/ParishSelect';
import { DeleteOutlined } from '@ant-design/icons';
const server = process.env.REACT_APP_SERVER_URL;

const InternalLicense: React.FC<InternalLicenseProps> = ({ thm, inst, auth, setProcedure }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [file, setFile] = useState<File | null>(null);
  const [licenseState, setLicenseState] = useState<string>('');

  const { id } = useParams<{ id: any }>();

  const { width } = useWindowDimensions();
  const [form] = useForm();
  const history = useHistory();
  const ref = useRef<HTMLInputElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);
  const selectedInstitution = inst?.find((i) => i.id === 9);
  const permProcedure = selectedInstitution?.tramitesDisponibles?.find((t) => t.id === 28);
  const notPermProcedure = selectedInstitution?.tramitesDisponibles?.find((t) => t.id === 36);

  useEffect(() => {
    if (!(history.location.state as any)?.contribuyente || (history.location.state as any)?.contribuyente?.id !== id) {
      history.push('/dashboard/contribuyente/licencia');
    }
    // eslint-disable-next-line
  }, []);

  const handleFile = (e) => {
    const _file = e.target.files[0];
    if (_file) {
      const fr = new FileReader();
      const blob = (_file as File).slice(0, (_file as File).size, _file.type);
      setFile(new File([blob], 'DocumentoSolicitud.pdf', { type: _file.type }));
      fr.readAsDataURL(_file);
      fr.onload = (e) => {
        imgRef.current?.setAttribute('src', require('../../../assets/images/pdf.png'));
        imgRef.current?.style.setProperty('width', '80px');
        imgRef.current?.style.setProperty('height', '80px');
      };
    }
  };

  const submit = async () => {
    const values = await form.validateFields();
    console.log(values);
    const fd = new FormData();
    if (file) fd.append('recaudos', file);
    values.contribuyente = (history.location.state as any).contribuyente;
    // const pagos = cloneDeep(values.pagos);
    // pagos.filter(p => p).forEach(p => {
    //   Object.keys(p).forEach(k => {
    //     if(moment.isMoment(p[k])) {
    //       p[k] = p[k].toISOString();
    //     }
    //   });
    // });
    // const price = values.estadoLicencia === 'PERMANENTE' ? +(permProcedure?.costo || 0) : +(notPermProcedure?.costo || 0)
    // if((pagos.map(p => p.costo).reduce((c, v) => c + v, 0)) !== price) return message.error('La suma de los montos de las transacciones debe ser igual al total de la licencia');
    values.actividadesEconomicas.forEach((ae) => {
      if (moment.isMoment(ae.desde)) ae.desde = ae.desde.toISOString();
    });
    values.correo = values.username;
    const body = {
      tipoTramite: values.estadoLicencia === 'PERMANENTE' ? 28 : 36,
      username: values.username,
      datos: values,
      idSolicitud: +values.idSolicitud,
    };
    setLoading(true);
    try {
      const response = await axios.post(`${server}/settlements/license`, body, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      if (response.status === 200 && file) {
        const complete = message.loading('Subiendo recaudos...');
        const upload = await axios.post(`${server}/uploads/takings/${response.data.tramite.codigoTramite}`, fd, {
          headers: { Authorization: `Bearer ${auth?.token}` },
        });
        if (upload.status === 200) {
          complete();
          setProcedure(response.data.tramite);
          message.success('Tramite para la solicitud de licencia de actividades económicas creado exitosamente.');
          history.push('/dashboard/contribuyente/licencia');
          form.resetFields();
          setFile(null);
        }
      } else if (response.status === 200) {
        setProcedure(response.data.tramite);
        message.success('Tramite para la solicitud de licencia de actividades económicas creado exitosamente.');
        history.push('/dashboard/contribuyente/licencia');
        form.resetFields();
        setFile(null);
      }
    } catch (e) {
      message.error(e.response?.data?.error || e.response?.data?.message || 'Error al crear licencia.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Licencia de Actividades Económicas'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white' }}
    >
      {!id && <GetTaxpayerInfo />}
      {id && (
        <Form
          form={form}
          layout='vertical'
          scrollToFirstError
          onFinish={submit}
          onValuesChange={(c, v) => {
            form.setFieldsValue(v);
            setData(v);
          }}
        >
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4}>Usuario del S.U.T.</Typography.Title>
          </Divider>
          <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Col span={24} style={{ marginBottom: 10 }}>
              <Typography.Text strong>
                Para la asignación de una Licencia de Actividades Económicas, el contribuyente debe poseer un usuario en el
                sistema.
              </Typography.Text>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item
                label='Correo Electrónico'
                name='username'
                rules={[
                  { required: true, message: 'Debe ingresar el correo electrónico' },
                  { validator: Utils.validators.email },
                ]}
              >
                <Input placeholder='Correo Electrónico' />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4}>Datos de la Sucursal</Typography.Title>
          </Divider>
          <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Col xs={24} xl={8}>
              <Form.Item
                label='Denominación Comercial'
                name='denominacionComercial'
                rules={[{ required: true, message: 'Debe ingresar la denominación comercial' }]}
              >
                <Input placeholder='Denominación Comercial' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item
                label='Nombre del Representante Legal'
                name='nombreRepresentante'
                rules={[{ required: true, message: 'Debe ingresar el nombre del representante legal' }]}
              >
                <Input placeholder='Nombre del Representante Legal' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item
                label='Capital Suscrito'
                name='capitalSuscrito'
                rules={[{ required: true, message: 'Debe ingresar el capital suscrito' }]}
                normalize={Utils.normalize.isNumber}
              >
                <Input placeholder='Capital Suscrito' type='numeric' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item
                label='Tipo de Sociedad'
                name='tipoSociedadContrib'
                rules={[{ required: true, message: 'Debe ingresar el tipo de sociedad' }]}
              >
                <TaxpayerSocietyType placeholder='Tipo de Sociedad' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item
                label='Teléfono'
                name='telefono'
                rules={[{ required: true, message: 'Debe ingresar el teléfono' }, { validator: Utils.validators.phoneNumber }]}
              >
                <Input placeholder='Teléfono' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Form.Item label='Parroquia' name='parroquia' rules={[{ required: true, message: 'Debe ingresar la parroquia' }]}>
                <ParishSelect />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item label='Dirección' name='direccion' rules={[{ required: true, message: 'Debe ingresar la dirección' }]}>
                <Input placeholder='Dirección' />
              </Form.Item>
            </Col>
          </Row>
          {/* <Divider orientation='left' style={{ marginLeft: -10 }}>
          <Typography.Title level={4}>Datos del Timbre Fiscal</Typography.Title>
        </Divider>
        <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Col xs={24} xl={8}>
            <Form.Item label='Banco del pago' name='bancoTimbre' rules={[{ required: true, message: 'Debe ingresar el banco' }]}>
              <Input placeholder='Banco del Timbre' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Objeto' name='objeto' rules={[{ required: true, message: 'Debe ingresar el objeto' }]}>
              <Input placeholder='Objeto de la actividad económica' />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label='Fecha del pago' name='fechaTimbre' rules={[{ required: true, message: 'Debe ingresar la fecha' }]}>
              <DatePicker format='DD/MM/YYYY' disabledDate={(current) => current > moment().endOf('day')} style={{width: '100%'}} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Col xs={24} xl={4}>
            <Form.Item label='Monto del pago' name='montoTimbre' rules={[{ required: true, message: 'Debe ingresar el monto' }]}>
              <Input type='number' step='0.01' placeholder='monto del pago' />
            </Form.Item>
          </Col>
        </Row> */}
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4}>Actividades Económicas</Typography.Title>
          </Divider>
          <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Col span={24}>
              <Form.Item
                name='actividadesEconomicas'
                rules={[{ required: true, message: 'Debe ingresar las actividades económicas' }]}
              >
                <EconomicActivities form={form} data={data} />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4}>Datos de la Licencia</Typography.Title>
          </Divider>
          <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Col xl={6} xs={24}>
              <Form.Item name='estadoLicencia' rules={[{ required: true, message: 'Debe ingresar el estado de la licencia' }]}>
                <LicenseTypeSelect onChange={setLicenseState} placeholder='Tipo de Licencia' />
              </Form.Item>
            </Col>
          </Row>
          {/* <Divider orientation='left' style={{ marginLeft: -10 }}>
          <Typography.Title level={4}>Monotributo</Typography.Title>
        </Divider>
        <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Col xl={6} xs={24}>
            <Form.Item name='esMonotributo' rules={[{ required: true, message: 'Debe indicar si el R.I.M. declara montributo.' }]}>
              <MonotaxSwitch placeholder='Declara monotributo' />
            </Form.Item>
          </Col>
        </Row> */}
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4}>Recaudos</Typography.Title>
          </Divider>
          <Row>
            <List
              style={{ marginBottom: 20, width: '100%' }}
              bordered
              dataSource={
                licenseState === 'PERMANENTE'
                  ? permProcedure?.recaudos || []
                  : licenseState === 'PROVISIONAL'
                  ? notPermProcedure?.recaudos || []
                  : []
              }
              size='small'
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text strong>{item.nombreCompleto}</Typography.Text>
                </List.Item>
              )}
            />
          </Row>
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4}>Archivos a Subir</Typography.Title>
          </Divider>
          <Row gutter={24}>
            {!file ? (
              <Col
                xs={24}
                xl={6}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  marginBottom: 20,
                }}
              >
                <input hidden ref={ref} type='file' accept='application/pdf' onChange={(e) => handleFile(e)} />
                <Button type='primary' style={{ marginBottom: 10 }} onClick={() => ref.current?.click()}>
                  Subir Archivo
                </Button>
                <Typography.Text strong style={{ textAlign: 'center' }}>
                  Documento Solicitud
                </Typography.Text>
              </Col>
            ) : (
              <Col xs={24} xl={6}>
                <Card
                  cover={<img alt='documentoSolicitud' ref={imgRef} />}
                  bodyStyle={{ padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  <Typography.Text strong style={{ textAlign: 'center' }}>
                    Documento Solicitud
                  </Typography.Text>
                  <Button type='link' onClick={() => setFile(null)} style={{ textAlign: 'right', width: '100%', color: 'red' }}>
                    Eliminar
                    <DeleteOutlined />
                  </Button>
                </Card>
              </Col>
            )}
          </Row>
          <Divider orientation='left' style={{ marginLeft: -10 }}>
            <Typography.Title level={4}>Datos de Pago</Typography.Title>
          </Divider>
          {/* {data.estadoLicencia ?
        <Typography.Text style={{ display: 'inline-block', marginBottom: 20, marginTop: -15, marginLeft: 10 }} strong>El tramite de Licencia de Actividades Económicas tiene un costo de Bs. {formatCurrency(data.estadoLicencia === 'PERMANENTE' ? permProcedure?.costo || 0 : notPermProcedure?.costo || 0)}. 
          El monto de la(s) transaccion(es) debe ser igual al total a pagar.</Typography.Text> :  */}
          {
            <Typography.Text style={{ display: 'inline-block', marginBottom: 20, marginTop: -15, marginLeft: 10 }} strong>
              Ingrese el número de la solicitud del recibo de la tasa correspondiente.
            </Typography.Text>
          }
          <Row gutter={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <Col xs={6} xl={6}>
              <Form.Item name='idSolicitud' rules={[{ required: true, message: 'Debe ingresar un número de solicitud' }]}>
                <Input type='number' step='1' placeholder='núm. de solicitud' />
              </Form.Item>
            </Col>
          </Row>
          {/* <MultipleTransactions selectedReqType='TRAMITE' selectedInstitution={selectedInstitution} form={form} data={data} maxCred={0} /> */}
        </Form>
      )}
      {id && (
        <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
          <Col>
            <Button
              onClick={() =>
                history.push('/dashboard/contribuyente/licencia', {
                  contribuyentes: (history.location.state as any)?.contribuyentes,
                })
              }
            >
              Atrás
            </Button>
          </Col>
          <Row gutter={12}>
            <Col>
              <Button type='primary' loading={loading} onClick={form.submit}>
                Generar
              </Button>
            </Col>
          </Row>
        </Row>
      )}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  inst: state.inst.institutions,
  banks: state.bk.banks,
  auth: state.auth,
});

export default connect(mapStateToProps, { setProcedure })(InternalLicense);

interface InternalLicenseProps {
  thm: States.ThemeColors;
  inst: States.Institutions['institutions'];
  banks: States.Banks['banks'];
  auth: States.Auth;
  setProcedure: (tramite) => void;
}
