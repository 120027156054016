import axios, { AxiosResponse } from 'axios';
import {
  AUTH_USER_LOGIN,
  AuthUserAction,
  SET_USER,
  EDIT_USER,
  NotificationTypes,
  SET_TAXPAYER_INFO,
  AuthActionTypes,
} from './actionsTypes';
import { User, Response, Taxpayer } from 'sigt';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { hasNewNotifications } from './notifications';
import moment from 'moment';
const server = process.env.REACT_APP_SERVER_URL;

export const setUserAndToken = (user: User, token: string): AuthUserAction => ({
  type: AUTH_USER_LOGIN,
  payload: { user, token },
});

export const setUser = (user: User | null, token?: string): AuthActionTypes => ({ type: SET_USER, payload: { user, token } });

export const updateUser = (user: User): AuthActionTypes => ({ type: EDIT_USER, payload: user });

export const setTaxpayerInfo = (taxpayer: Taxpayer): AuthActionTypes => ({ type: SET_TAXPAYER_INFO, payload: taxpayer });

export const signIn =
  (formValues, token: string): ThunkAction<Promise<Response>, {}, {}, AuthActionTypes> =>
  async (dispatch: ThunkDispatch<{}, {}, AuthActionTypes | NotificationTypes>): Promise<Response> => {
    try {
      let response: AxiosResponse<SignInResponse>;
      if (token) {
        const data = { user: formValues };
        response = await axios.post(`${server}/auth/complete`, data, { headers: { Authorization: `Bearer ${token}` } });
      } else {
        response = await axios.post(`${server}/auth/login`, formValues);
      }
      const tokenUser = response.data.token;
      response.data.message.includes('bloqueado') && localStorage.setItem('block', response.data.message);
      dispatch(setUserAndToken(response.data.user, tokenUser));
      const hasNotf = response?.data?.user?.hasNewNotifications;
      dispatch(hasNewNotifications(hasNotf ? hasNotf : false));
      localStorage.setItem('token', tokenUser);
      localStorage.setItem('tokenDate', moment().toString());
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const signUp =
  (formValues): ThunkAction<Promise<Response>, {}, {}, AuthActionTypes> =>
  async (dispatch: ThunkDispatch<{}, {}, AuthActionTypes>): Promise<Response> => {
    try {
      const data = { user: formValues };
      const response: AxiosResponse<SignInResponse> = await axios.post(`${server}/auth/signup`, data);
      const tokenUser = response.data.token;
      dispatch(setUserAndToken(response.data.user, response.data.token));
      localStorage.setItem('token', tokenUser);
      localStorage.setItem('tokenDate', moment().toString());
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const getUserData =
  (token: string): ThunkAction<Promise<Response>, {}, {}, AuthActionTypes> =>
  async (dispatch: ThunkDispatch<{}, {}, AuthActionTypes>): Promise<Response> => {
    try {
      const response: AxiosResponse<SignInResponse> = await axios.get(`${server}/auth/user`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch(setUser(response.data.user, token));
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const editUser =
  (formValues): ThunkAction<Promise<Response>, {}, {}, AuthActionTypes> =>
  async (dispatch: ThunkDispatch<{}, {}, AuthActionTypes>): Promise<Response> => {
    try {
      const data = { user: formValues };
      const response: AxiosResponse<SignInResponse> = await axios.patch(`${server}/user`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      dispatch(updateUser(response.data.user));
      return response.data;
    } catch (error) {
      throw error;
    }
  };

interface SignInResponse extends Response {
  user: User;
  token: string;
}
