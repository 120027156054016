import Axios from 'axios';

const server = process.env.REACT_APP_SERVER_URL;

export const getMovements = async (tipoMovimiento, page: number = 1, token: string) => {
  const response = await Axios.get(`${server}/procedures/users/movements/${tipoMovimiento}/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};
