import React, { useEffect, useState } from 'react';
import { States, State } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, Col, Row, Divider, Typography, Table, Button, Tag, message, Pagination, Alert } from 'antd';
import usePagination, { useWindowDimensions } from '../../utils/hooks';
import { CreditCardFilled, EyeFilled } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import axios from 'axios';
import Petro from '../Icons/Petro';
import { formatCurrency, roundCalc } from './PaySpecificTax';
import { setRequestsPagination } from '../../redux/actions/requests';
const server = process.env.REACT_APP_SERVER_URL;

const PayTaxes: React.FC<PayTaxesProps> = ({ auth, thm, setReduxRequests, reduxReqs, reduxReqsPagination }) => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [searching, setSearching] = useState(false);
  const [pageTotal, setPageTotal] = useState(reduxReqsPagination?.total ?? 0);

  const [paginatedData, currentPage, setCurrentPage, pageSize] = usePagination(reduxReqs);

  const petro = useSelector((state: State) => state.coin.petro);

  const columns: ColumnsType<any> = [
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      render: (doc, r) => (
        <span>
          {r.tipoDocumento}-{doc}
        </span>
      ),
    },
    {
      title: 'R.I.M.',
      dataIndex: 'referenciaMunicipal',
      render: (rim) => <span>{rim ? rim : 'N/A'}</span>,
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      render: (tipo) => <span>{tipo ? tipo : 'N/A'}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span>,
    },
    {
      title: (
        <Row justify='center' align='middle'>
          <Col span={24}>
            <Typography.Text>Monto Base (MMV)</Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text style={{ color: 'red', fontSize: 10 }}>sin intereses, recargos o multas</Typography.Text>
          </Col>
        </Row>
      ),
      dataIndex: 'montoPetro',
      align: 'center',
      render: (monto, record) => (
        <Row>
          <span>
            {monto
              ? monto + (record?.interesesAE?.totalInteres ?? 0) / petro + Number(record?.recargoAE?.totalRecargo ?? 0) / petro
              : +(
                  +record.monto / petro +
                  (record?.interesesAE?.totalInteres ?? 0) / petro +
                  Number(record?.recargoAE?.totalRecargo ?? 0) / petro
                ).toFixed(8)}{' '}
            <Petro />
          </span>
        </Row>
      ),
    },
    {
      title: (
        <Row justify='center' align='middle'>
          <Col span={24}>
            <Typography.Text>Monto Base (Bs.)</Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text style={{ color: 'red', fontSize: 10 }}>sin intereses, recargos o multas</Typography.Text>
          </Col>
        </Row>
      ),
      align: 'center',
      dataIndex: 'monto',
      render: (monto, record) => (
        <span>{`Bs. ${formatCurrency(
          roundCalc(
            (+monto || +record.montoPetro * petro) +
              (record?.interesesAE?.totalInteres ?? 0) +
              (record?.recargoAE?.totalRecargo ?? 0)
          )
        )}`}</span>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'monto',
      render: (_, record) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(record.estado).color}>
          {getState(record.estado).name}
        </Tag>
      ),
    },
  ];

  const search = async (page: number) => {
    try {
      setSearching(true);
      const response = await axios.get(
        `${server}/settlements/instances/${auth.user?.contribuyente?.tipoContribuyente}?documento=${auth.user?.contribuyente?.documento}&tipoDocumento=${auth.user?.contribuyente?.tipoDocumento}
        &page=${page}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      setCurrentPage(page);
      setPageTotal(response.data.totalSolicitudes);
      setReduxRequests(response.data.solicitudes, {
        total: response.data.totalSolicitudes,
        tipoContribuyente: auth.user?.contribuyente?.tipoContribuyente,
        documento: auth.user?.contribuyente?.documento,
        tipoDocumento: auth.user?.contribuyente?.tipoDocumento,
        referencia: null,
      });
    } catch (e) {
      setCurrentPage(0);
      setPageTotal(0);
      setReduxRequests([], {
        total: 0,
        tipoContribuyente: auth.user?.contribuyente?.tipoContribuyente,
        documento: auth.user?.contribuyente?.documento,
        tipoDocumento: auth.user?.contribuyente?.tipoDocumento,
        referencia: null,
      });
      message.error(e?.response?.data?.message || 'Error al buscar contribuyente');
    } finally {
      setSearching(false);
    }
  };

  useEffect(() => {
    if (auth.user?.contribuyente) {
      search(1);
    }
  }, []);

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando pago', color: 'warning' };
    else return { name: 'Solvente', color: 'green' };
  };

  // if(requests.find((r) => r.estado === 'ingresardatos'))
  columns.push({
    title: 'Acciones',
    dataIndex: 'id',
    render: (id, tax) => (
      <Button
        icon={tax.estado === 'ingresardatos' ? <CreditCardFilled /> : <EyeFilled />}
        type='primary'
        onClick={() => history.push(`/dashboard/impuestos/pagar/${id}`)}
      >
        {tax.estado === 'ingresardatos' ? 'Pagar' : 'Ver'}
      </Button>
    ),
  });

  useEffect(() => {
    if (auth.user?.tipoUsuario !== 4) history.push('/dashboard');
    // eslint-disable-next-line
  }, []);

  return (
    <Card
      style={{ height: '100%' }}
      title='Pagar Impuestos'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Row gutter={[24, 24]}>
        <Divider orientation='left'>
          <Typography.Title level={4} style={{ marginLeft: 5 }}>
            Declaraciones
          </Typography.Title>
        </Divider>
        <Col span={24}>
          <Alert
            message={
              'Para obtener información precisa sobre su solicitud, es necesario que ingrese al detalle de la misma. Allí podrá verificar si existen multas, intereses o recargos asociados. No olvide revisar esta sección para evitar inconvenientes.'
            }
            type='error'
          />
        </Col>
        <Col span={24}>
          <Table
            style={{ marginTop: width < 1200 ? 38 : 0 }}
            loading={searching}
            columns={columns}
            dataSource={reduxReqs}
            rowKey='id'
            bordered
            pagination={false}
          />
        </Col>
        <Col span={24}>
          <Pagination
            key={'pagination'}
            current={currentPage}
            onChange={(page) => search(page)}
            pageSize={pageSize}
            total={pageTotal}
            size='small'
          />
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  auth: state.auth,
  thm: state.thm,
  reduxReqs: state.req.requests,
  reduxReqsPagination: state.req.pagination,
});

export default connect(mapStateToProps, { setReduxRequests: setRequestsPagination })(PayTaxes);

interface PayTaxesProps {
  auth: States.Auth;
  thm: States.ThemeColors;
  setReduxRequests: any;
  reduxReqs: States.Requests['requests'];
  reduxReqsPagination: States.Requests['pagination'];
}
