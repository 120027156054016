import axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const updateCost = async (cost: number, id, token) => {
  try {
    const data = { costo: cost };
    const response = await axios.patch(`${server}/procedures/instances/${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCertificate = async (id, token) => {
  try {
    const response = await axios.post(
      `${server}/procedures/certificate/${id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProceduresByPagination = async (page, token) => {
  try {
    const response = await axios.post(
      `${server}/procedures/pagination`,
      { page },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
