import React, { useState, useEffect } from 'react';
import { FormInstance } from 'antd/lib/form';
import { Row, Col, Form, Input, Typography, Divider } from 'antd';

const PropertyLinderos: React.FC<PropertyLinderosProps> = ({ form }) => {
  const [original, setOriginal] = useState({
    norte: '',
    sur: '',
    este: '',
    oeste: '',
  });
  const [actuales, setActuales] = useState({
    norte: '',
    sur: '',
    este: '',
    oeste: '',
  });

  const handleChangeOriginal = (fieldName, value) => {
    setOriginal((state) => {
      return { ...state, [fieldName]: value };
    });
    form.setFieldsValue({
      linderosOriginales: {
        [fieldName]: value,
      },
      linderosActuales: {
        [fieldName]: value,
      },
    });
  };

  const handleChangeActuales = (fieldName, value) => {
    setActuales((state) => {
      return { ...state, [fieldName]: value };
    });
    form.setFieldsValue({
      linderosActuales: {
        [fieldName]: value,
      },
    });
  };

  useEffect(() => {
    setActuales((state) => ({ ...state, ...original }));
  }, [original]);

  return (
    <>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col xl={24} xs={24}>
          <Typography.Title level={4}>Linderos Originales</Typography.Title>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            label='Norte'
            name={['linderosOriginales', 'norte']}
            rules={[{ required: true, message: 'Este campo es requerido' }]}
          >
            <Input
              onChange={(e) => {
                handleChangeOriginal('norte', e.target.value);
              }}
              placeholder='Norte'
            ></Input>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            label='Sur'
            name={['linderosOriginales', 'sur']}
            rules={[{ required: true, message: 'Este campo es requerido' }]}
          >
            <Input
              onChange={(e) => {
                handleChangeOriginal('sur', e.target.value);
              }}
              placeholder='Sur'
            ></Input>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            label='Este'
            name={['linderosOriginales', 'este']}
            rules={[{ required: true, message: 'Este campo es requerido' }]}
          >
            <Input
              onChange={(e) => {
                handleChangeOriginal('este', e.target.value);
              }}
              placeholder='Este'
            ></Input>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            label='Oeste'
            name={['linderosOriginales', 'oeste']}
            rules={[{ required: true, message: 'Este campo es requerido' }]}
          >
            <Input
              onChange={(e) => {
                handleChangeOriginal('oeste', e.target.value);
              }}
              placeholder='Oeste'
            ></Input>
          </Form.Item>
        </Col>
      </Row>
      <Divider type='horizontal' />
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col xl={24} xs={24}>
          <Typography.Title level={4}>Linderos Actuales</Typography.Title>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            label='Norte'
            name={['linderosActuales', 'norte']}
            rules={[{ required: true, message: 'Este campo es requerido' }]}
          >
            <Input
              onChange={(e) => {
                handleChangeActuales('norte', e.target.value);
              }}
              value={actuales.norte}
              placeholder='Norte'
            ></Input>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            label='Sur'
            name={['linderosActuales', 'sur']}
            rules={[{ required: true, message: 'Este campo es requerido' }]}
          >
            <Input
              onChange={(e) => {
                handleChangeActuales('sur', e.target.value);
              }}
              value={actuales.sur}
              placeholder='Sur'
            ></Input>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            label='Este'
            name={['linderosActuales', 'este']}
            rules={[{ required: true, message: 'Este campo es requerido' }]}
          >
            <Input
              onChange={(e) => {
                handleChangeActuales('este', e.target.value);
              }}
              value={actuales.este}
              placeholder='Este'
            ></Input>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            label='Oeste'
            name={['linderosActuales', 'oeste']}
            rules={[{ required: true, message: 'Este campo es requerido' }]}
          >
            <Input
              onChange={(e) => {
                handleChangeActuales('oeste', e.target.value);
              }}
              value={actuales.oeste}
              placeholder='Oeste'
            ></Input>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PropertyLinderos;

interface PropertyLinderosProps {
  form: FormInstance;
}
