import React from 'react';
import { Checkbox, Row, Col, Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';

const DatosFisicos: React.FC<DatosFisicosProps> = ({ form }) => {
  return (
    <>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col xl={6} xs={24}>
          <Form.Item
            label='Tenencia del Terreno'
            name={['datosFisicos', 'tenenciaTerreno']}
            rules={[{ required: true, message: 'Debe ingresar la tenencia del terreno' }]}
          >
            <Input placeholder='Tenencia del Terreno'></Input>
          </Form.Item>
        </Col>
        <Col xl={6} xs={24}>
          <Form.Item
            label='Tenencia de Construcción'
            name={['datosFisicos', 'tenenciaConstruccion']}
            rules={[{ required: true, message: 'Debe ingresar la tenencia de construcción' }]}
          >
            <Input placeholder='Tenencia de Construcción'></Input>
          </Form.Item>
        </Col>
        <Col xl={6} xs={24}>
          <Form.Item
            label='Regimen de Propiedad'
            name={['datosFisicos', 'regimenPropiedad']}
            rules={[{ required: true, message: 'Debe ingresar el regimen de propiedad' }]}
          >
            <Input placeholder='Regimen de Propiedad'></Input>
          </Form.Item>
        </Col>
        <Col xl={6} xs={24}>
          <Form.Item
            label='Regimen Familiar'
            name={['datosFisicos', 'regimenFamiliar']}
            rules={[{ required: true, message: 'Debe ingresar el regimen el familiar' }]}
          >
            <Input placeholder='Regimen Familiar'></Input>
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ paddingLeft: 16 }} gutter={16}>
        <Col xl={12} xs={24} style={{ maxHeight: '200px' }}>
          <Form.Item label='Descripción de Uso' name={['datosFisicos', 'descripcionUso']}>
            <Checkbox.Group style={{ width: '100%' }}>
              <Col xl={12}>
                <Checkbox value='Comercio Al Detal'>Comercio al Detal</Checkbox>
              </Col>
              <Col xl={12}>
                <Checkbox value='Comercio Al Mayor'>Comercio al Mayor</Checkbox>
              </Col>
              <Col xl={12}>
                <Checkbox value='Industrial'>Industrial</Checkbox>
              </Col>
              <Col xl={12}>
                <Checkbox value='Servicio'>Servicio</Checkbox>
              </Col>
              <Col xl={12}>
                <Checkbox value='Agropecuaria'>Agropecuaria</Checkbox>
              </Col>
              <Col xl={12}>
                <Checkbox value='Otro'>Otro</Checkbox>
              </Col>
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24} style={{ maxHeight: '200px' }}>
          <Form.Item label='Servicios Públicos' name={['datosFisicos', 'serviciosPublicos']}>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row gutter={16}>
                <Col span={12}>
                  <Col xl={24}>
                    <Checkbox value='Acueducto'>Acueducto</Checkbox>
                  </Col>
                  <Col xl={24}>
                    <Checkbox value='Electricidad'>Electricidad</Checkbox>
                  </Col>
                  <Col xl={24}>
                    <Checkbox value='Cloacas'>Cloacas</Checkbox>
                  </Col>
                  <Col xl={24}>
                    <Checkbox value='Pavimento'>Pavimento</Checkbox>
                  </Col>
                  <Col xl={24}>
                    <Checkbox value='Acera'>Acera</Checkbox>
                  </Col>
                  <Col xl={24}>
                    <Checkbox value='Transporte'>Transporte</Checkbox>
                  </Col>
                </Col>
                <Col span={12}>
                  <Col xl={24}>
                    <Checkbox value='Baño Séptico'>Baño Séptico</Checkbox>
                  </Col>
                  <Col xl={24}>
                    <Checkbox value='Electricidad Inc.'>Electricidad Inc.</Checkbox>
                  </Col>
                  <Col xl={24}>
                    <Checkbox value='Teléfono'>Teléfono</Checkbox>
                  </Col>
                  <Col xl={24}>
                    <Checkbox value='TvCable'>TvCable</Checkbox>
                  </Col>
                  <Col xl={24}>
                    <Checkbox value='Alumbrado Pub.'>Alumbrado Pub.</Checkbox>
                  </Col>
                  <Col xl={24}>
                    <Checkbox value='Otro'>Otro</Checkbox>
                  </Col>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default DatosFisicos;

interface DatosFisicosProps {
  form: FormInstance;
}
