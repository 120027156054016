import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";

const AuthVerify = (props) => {
  props.history.listen(() => {
    const tokenTime = localStorage.getItem("tokenDate");
    if(tokenTime){
      const exp = moment(tokenTime || '');
      const dif = moment().diff(exp, 'minutes');
      if(dif >= 20) {
        props.logOut(true);
      }
      else {
        localStorage.setItem('tokenDate', moment().toString());
      }
    }
    else {
      props.logOut();
    }
  });
  return <div></div>;
};
export default withRouter(AuthVerify)